import React, { useState, useEffect } from "react"
import AdminHeader from "../components/adminheader/AdminHeader"
import AdminFooter from "../components/adminfooter/AdminFooter"
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from 'material-table'
import { useHistory } from "react-router";
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2'
import BaseUrl from "../../../../config/BaseUrl"
import image_url from "../../../../config/BaseUrl"
// import default_image from "../../../../../"
import { getApiWithToken, PutApi, PostApi, DeleteApi } from "../../../../config/Api/FakeuserApi";
import IconButton from "@material-ui/core/IconButton";
import { mapToStyles } from "@popperjs/core/lib/modifiers/computeStyles";
import { Reorder } from "@material-ui/icons";
import {FormCheck} from 'react-bootstrap'
import Form from 'react-bootstrap/Form'

const background={
    color: "white",
    backgroundColor:"rgba(0,0,0,.65)"
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    input: {
        display: "none",
    },
}));

export default function EmailMarketing() {
    const [Loader, setLoader] = useState(false)
    const history = useHistory();
    const classes = useStyles();
    const [Disable, setDisable] = useState(true);


    // First Modal
    const [selectedRow, setSelectedRow] = useState(null);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //Add img states

    const [SelectedThumbnail, setSelectedThumbnail] = useState(null)
    const [UpdateThumbnail, setUpdateThumbnail] = useState(null)
    const [AddSubProfile, SetAddSubProfile] = useState(null)
    const [Selectedprofile, setSelectedprofile] = useState(null)

    //get data state

    const [getemaildata, setgetemaildata] = useState([])
    const [emaillistdata, setemaillistdata] = useState([])

    //add data

    const [link, setlink] = useState("");
    const [description, setdescription] = useState("");
    const [name, setname] = useState("");

     //FOR EDIT
     const [show2, setShow2] = useState(false);
     const handleClose2 = () => setShow2(false);
     const handleShow2 = () => setShow2(true);

     const [editlink, seteditlink] = useState("");
     const [editdescription, seteditdescription] = useState("");
     const [editid, seteditid] = useState("");
     const [editname, seteditname] = useState("");

     const [profileimage, setprofileimage] = useState("")


     //for send modal

     const [show3, setShow3] = useState(false);
     const handleClose3 = () => setShow3(false);
     const handleShow3 = () => setShow3(true);

     //send email list
     const [mailId, setmailId] = useState("")
     const [userid, setuserId] = useState([])
     const [Select, SetSelect] = useState(true)


         //add validation states
         const [AddLinkErr, SetAddLinkErr] = useState({})
         const [AddDescriptionErr, SetAddDescriptionErr] = useState({})
         const [AddNameErr, SetAddNameErr] = useState({})
         const [AddimageErr, SetAddimageErr] = useState({})


         //edit validation

         const [EditLinkErr, SetEditLinkErr] = useState({})
         const [EditDescriptionErr, SetEditDescriptionErr] = useState({})
         const [EditNameErr, SetEditNameErr] = useState({})
         const [EditimageErr, SetEditimageErr] = useState({})





     const Token = localStorage.getItem('Token');





    useEffect(() => {
        EmailData()
        // SendEmail()
        SendEmailList()


    }, [])



    // console.log("getemaildata",getemaildata[0]['id'])

    //image code

    const handleInputFileChange = (event, func) => {
        var file = event.target.files[0];
        var reader = new FileReader();
        // console.log(file);
        const filesize = file.size
        const filesizeround = Math.round((filesize / 1024))
        if (filesizeround > 1024) {
            // setupdateProfileImage(null)
            // setselectProfileImage(null)
            Swal.fire({
                title: "Opps",
                text: "plz select your image less then and equals to 1mb",
                icon: "warning"
            });
        } else {
            var url = reader.readAsDataURL(file);
            // console.log(url)
            reader.onloadend = function (e) {
                setSelectedprofile(reader.result);
                setUpdateThumbnail(file);


                // console.log("data-======>",reader.result)
            };
            // console.log(selectProfileImage)
            func(event.target.files[0])
            // setdisable(false)
        }
    }



  // SHOW DATA IN MATERIAL TABLE CALL GET API

  const EmailData = () => {

    setLoader(true);
    getApiWithToken(`${BaseUrl.Admin_Url}/EmailMarketingData`, "", Token)

        .then(({ data }) => {
            setLoader(false);
            if (data.status) {
                setgetemaildata(data.data)
                console.log("EMAILname==>", data)


            } else {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: data.message,
                    icon: "error"
                })
            }
        }).catch(function (e) {
            // $(".loader").hide();
            Swal.fire({
                title: "Opps",
                text: e.message,
                icon: "error"
            })
        })

}



// Add AddSmsData
const AddEmailData = () => {
    const isValid = formValidation();
    if (isValid== true){




    setLoader(true);
    var formdata = new FormData();
    formdata.append("Link", link);
    formdata.append("Name", name);

    formdata.append("Description", description);
    formdata.append("thumbnail", AddSubProfile);





    PostApi(`${BaseUrl.Admin_Url}/EmailMarketingData`, formdata, Token)
        .then(({ data }) => {
            setLoader(false);
            console.log("Addemail==>", data)
            if (data.status) {
                setlink("")
                setdescription("")
                SetAddSubProfile("")
                handleClose()
                EmailData()

                Swal.fire({
                    title: "success",
                    text: data.message,
                    icon: "success"
                })
            } else {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: data.message,
                    icon: "error"
                })
            }
        }).catch(function (e) {
            // $(".loader").hide();
            Swal.fire({
                title: "Opps",
                text: e.message,
                icon: "error"
            })
        })

    }
    else{
        formValidation()


    }


    }



     //add validation
     const formValidation = () => {
        const AddLinkErr = {};
        const AddDescriptionErr = {};
        const AddNameErr = {};
        const AddimageErr = {};



        let isValid = true;

        if (link.length == 0) {
            AddLinkErr.firstNamenull = "Required Link";
            isValid = false;
        }
        if (AddSubProfile == null) {
            AddimageErr.firstNamenull = "Required Image";
            isValid = false;
        }

        if (description.length == 0) {
            AddDescriptionErr.firstNamenull = "Required Description";
            isValid = false;
        }

        if (name.length == 0) {
            AddNameErr.firstNamenull = "Required Name";
            isValid = false;
        }

        SetAddLinkErr(AddLinkErr);
        SetAddDescriptionErr(AddDescriptionErr);
        SetAddNameErr(AddNameErr);
        SetAddimageErr(AddimageErr);


        return isValid;
    }


      // EDITAPI EDIT
      const UpdateEmailMarketing = () => {
        const isValid = UpdateformValidation();
        if (isValid== true){


        setLoader(true);
        var formdata = new FormData();
        formdata.append("id", editid);
        formdata.append("Link", editlink);
        formdata.append("Description", editdescription);
        formdata.append("Name", editname);

        formdata.append("thumbnail", profileimage);




        PutApi(`${BaseUrl.Admin_Url}/EmailMarketingData`, formdata, Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    console.log("dataaPut==>",data)

                    handleClose2()
                    EmailData()


                    Swal.fire({
                        title: "success",
                        text: data.message,
                        icon: "success"
                    })
                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })
        }
        else{
            UpdateformValidation()


        }


}




//  Update validation
 const UpdateformValidation = () => {
    const EditLinkErr = {};
    const EditDescriptionErr = {};
    const EditNameErr = {};
    const EditimageErr = {};



    let isValid = true;

    if (editlink.length == 0) {
        EditLinkErr.firstNamenull = "Required Link";
        isValid = false;
    }
    if (profileimage == null) {
        EditimageErr.firstNamenull = "Required Image";
        isValid = false;
    }

    if (editdescription.length == 0) {
        EditDescriptionErr.firstNamenull = "Required Description";
        isValid = false;
    }

    if (editname.length == 0) {
        EditNameErr.firstNamenull = "Required Name";
        isValid = false;
    }



        SetEditLinkErr(EditLinkErr);
        SetEditDescriptionErr(EditDescriptionErr);
        SetEditNameErr(EditNameErr);
        SetEditimageErr(EditimageErr);


    return isValid;
}



const Delete = (id) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "You want to delete it",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            DeleteEMAILMarketing(id)
        }
    })

}
// DELETE API

const DeleteEMAILMarketing = (id) => {
    setLoader(true);
    var formdata = new FormData();
    formdata.append("id", id);
    DeleteApi(`${BaseUrl.Admin_Url}/EmailMarketingData?id=${id}`, formdata, Token)
        .then(({ data }) => {
            setLoader(false);
            if (data.status) {
                console.log("dELETEsms==>", data)
                EmailData()


                Swal.fire({
                    title: "success",
                    text: data.message,
                    icon: "success"
                })
            } else {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: data.message,
                    icon: "error"
                })
            }
        }).catch(function (e) {
            // $(".loader").hide();
            Swal.fire({
                title: "Opps",
                text: e.message,
                icon: "error"
            })
        })
}



//SEND EMAIL LIST

const SendEmailList = () => {


    setLoader(true);
    getApiWithToken(`${BaseUrl.Admin_Url}/SendEmail`, "", Token)

        .then(({ data }) => {
            setLoader(false);
            if (data.status) {
                setemaillistdata(data.data)
                console.log("EMAILlsitdata==>", data)


            } else {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: data.message,
                    icon: "error"
                })
            }
        }).catch(function (e) {
            // $(".loader").hide();
            Swal.fire({
                title: "Opps",
                text: e.message,
                icon: "error"
            })
        })

}

//SEND EMAIL POST API


const OpenModal=(id)=>{
    setgetemaildata(id)
    handleShow3()


}

const mysetUserId = (id) =>{
    setuserId([...userid, id])
}



const AllCheckbox =()=>{
SetSelect(false)
    var checkboxes = document.getElementsByClassName('allcheckbox');
    for(var i=0;i<checkboxes.length;i++){

        checkboxes[i].checked = true
        console.log("  checkboxes[i]",  checkboxes[i])

  }

}


const UncheckedAll =()=>{
    SetSelect(true)

    var checkboxes = document.getElementsByClassName('allcheckbox');
    for(var i=0;i<checkboxes.length;i++){

        checkboxes[i].checked = false

  }

}


const SendEmail = () => {




    console.log("length is ",userid.length)


    if(userid.length > 0){
        setLoader(true);
    var formdata = new FormData();
    formdata.append("Userid", JSON.stringify(userid) );
    formdata.append("id",getemaildata);



        PostApi(`${BaseUrl.Admin_Url}/SendEmail`, formdata, Token)
        .then(({ data }) => {
            setLoader(false);

            if (data.status) {
               console.log("emailsend",data)
               setuserId([])
               setgetemaildata([])
               EmailData()
               handleClose3()

                Swal.fire({
                    title: "success",
                    text: data.message,
                    icon: "success"
                })
            } else {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: data.message,
                    icon: "error"
                })
            }
        }).catch(function (e) {
            // $(".loader").hide();
            Swal.fire({
                title: "Opps",
                text: e.message,
                icon: "error"
            })
        })


    }

    else{

        // console.log("elsee")
        Swal.fire({
            title: "Opps",
            text: "plz select checkbox",
            icon: "error"
        })


    }







    }










    return (
        <>
            {Loader ? <div className="loader" ></div> : null}
            <div id="app">
                <AdminHeader />
                <div class="main-wrapper main-wrapper-1">
                    <div className="main-content">
                        <section className="section">
                            <ul className="breadcrumb breadcrumb-style " style={{backgroundcolor:"blue"}}>
                                <li className="breadcrumb-item">
                                    <h4 className="page-title m-b-0">  Subadmin</h4>
                                </li>

                                <li className="breadcrumb-item active">Email Marketing</li>

                                <div class="row">
                                    <div class="col-md-12  text-right">
                                        <button onClick={() => handleShow()} className="btn btn-outline-primary"  >Add Email</button>

                                    </div>
                                </div>


                            </ul>


                            <div style={{ maxWidth: '100%' }}>
                                <MaterialTable
                                    title=""
                                    data={getemaildata}

                                    columns={[


                                        { title: "thumbnail", field: "thumbnail", render: item => <img src={(item.thumbnail)} alt="" border="3" height="80" width="80" /> },
                                        { title: "Title", field: "Name" },
                                        { title: "Description", field: "Description" },
                                        { title: "Link", field: "Link" },
                                        { title: "Date", field: "Date" },
                                        {
                                            title: 'Send',
                                            render: rowData =><Button onClick={()=>OpenModal(rowData.id)} >Send</Button>
                                          }


                                    ]}

                                    onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                    options={{
                                        rowStyle: rowData => ({
                                            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                        }),
                                        actionsColumnIndex: -1,
                                    }}
                                    // other props
                                    actions={[
                                        rowData => ({
                                            icon: 'edit',
                                            tooltip: 'Edit User',
                                            //   onClick: (event, rowData) => history.push("/EditSubadmin")
                                            // onClick: () => history.push("/EditSubadmin")
                                            onClick: () => {
                                                handleShow2()
                                                setprofileimage(rowData.thumbnail)
                                                seteditid(rowData.id)
                                                seteditlink(rowData.Link)
                                                seteditdescription(rowData.Description)
                                                seteditname(rowData.Name)





                                            }

                                        }),

                                        rowData => ({
                                            icon: 'delete',
                                            tooltip: 'Delete User',
                                            onClick: () => {
                                                console.log("rowData2==>", rowData)
                                                Delete(rowData.id)


                                                // setDelSubEmail()
                                            }


                                        })
                                    ]}
                                    options={{
                                        maxBodyHeight: 400,
                                        overflowX: 'x-scroll',
                                        actionsColumnIndex: 7,
                                        headerStyle: {
                                            backgroundColor: '#222',
                                            color: "white"
                                          }
                                      }}




                                />


                            </div>



                        </section>

                    </div>
                    <AdminFooter />
                </div>
            </div>




            {/*  modal for Add SUBADMIN */}



            <Modal className="btn-close"
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg"
                show={show}
                onHide={handleClose}>
                <Modal.Header >
                    <Modal.Title>EMAIL MARKETING</Modal.Title>
                    <i onClick={handleClose} style={{ fontSize: "24px" }} className=" fa-10X fas fa-window-close"></i>
                </Modal.Header>
                <Modal.Body>

                    <form>
                    <div className="form-row text-center " style={{ display: "block" }}>


<div className="row">
    <div className="col-md-12 ">
        <center>
            <div className="upload_photo_main">
                <input onChange={(e) => handleInputFileChange(e, SetAddSubProfile)}
                    accept="image/*"
                    className={classes.input}
                    id="icon-button-file"
                    type="file"

                />
                <label htmlFor="icon-button-file">

                    <IconButton
                        color="#414141"
                        aria-label="upload picture"
                        component="span"
                        className="upload_photo_main"
                    >
                        <img style={{ minWidth: "120px", maxWidth: "120px", borderRadius: "20px" }}
                            src={Selectedprofile ? Selectedprofile : 'assets/img/cam.jpg'}

                            className="upload_photo_main" />

                    </IconButton>

                </label>
                <div>
                                    {Object.keys(AddimageErr).map((key) => {
                                        return <div style={{ color: "red" }}>{AddimageErr[key]}</div>
                                    })}
                                </div>
            </div>
        </center>
    </div>
</div>


                              </div>

                               <div className="row">
                                <div className="col-md-12">

                                <label htmlFor="inputFN">Title</label>
                                <input value={name}  onChange={(e)=>setname(e.target.value)}  type="text" className="form-control" id="inputFN" placeholder="Enter name" />
                                <div>
                                    {Object.keys(AddNameErr).map((key) => {
                                        return <div style={{ color: "red" }}>{AddNameErr[key]}</div>
                                    })}
                                </div>


                                </div>



                            </div>



                            <div className="row mt-2">
                                <div className="col-md-12">

                                <label htmlFor="inputL">Link</label>
                                <input value={link}  onChange={(e)=>setlink(e.target.value)}  type="text" className="form-control" id="inputL" placeholder="Enter Link" />
                                <div>
                                    {Object.keys(AddLinkErr).map((key) => {
                                        return <div style={{ color: "red" }}>{AddLinkErr[key]}</div>
                                    })}
                                </div>
                                </div>



                            </div>
                            <div className="row mt-2">
                                <div className="col-md-12">

                                <label htmlFor="inputFN">Description</label>
                                <textarea value={description}  onChange={(e)=>setdescription(e.target.value)}  type="text" className="form-control" id="inputFN" placeholder="Enter Description" />

                                <div>
                                    {Object.keys(AddDescriptionErr).map((key) => {
                                        return <div style={{ color: "red" }}>{AddDescriptionErr[key]}</div>
                                    })}
                                </div>

                                </div>



                            </div>



                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={()=>AddEmailData()} >
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>

                {/* Second modal for edit  */}



                <Modal className="btn-close"
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg"
                show={show2}
                onHide={handleClose2}>
                <Modal.Header >
                    <Modal.Title> EDIT EMAIL MARKETING</Modal.Title>
                    <i onClick={handleClose2} style={{ fontSize: "24px" }} className=" fa-10X fas fa-window-close"></i>
                </Modal.Header>
                <Modal.Body>

                    <form>
                    <div className="form-row text-center " style={{ display: "block" }}>


<div className="row">
    <div className="col-md-12 ">
        <center>
            <div className="upload_photo_main">
                <input
                    onChange={e => handleInputFileChange(e, setprofileimage)}
                    accept="image/*"
                    className={classes.input}
                    id="icon-button-file"
                    type="file"

                />
                <label htmlFor="icon-button-file">
                    <IconButton
                        color="#414141"
                        aria-label="upload picture"
                        component="span"
                        className="upload_photo_main"
                    >
                        <img src={Selectedprofile ? Selectedprofile : `${(profileimage)}` !== "https://backend.fridaymart.mlnull" ? `${(profileimage)}` : 'assets/img/cam.jpg'}

                            style={{ minWidth: "120px", maxWidth: "120px", borderRadius: "20px" }} className="upload_photo_main" />

                    </IconButton>
                </label>
                <div>
                                    {Object.keys(EditimageErr).map((key) => {
                                        return <div style={{ color: "red" }}>{EditimageErr[key]}</div>
                                    })}
                                </div>
            </div>
        </center>
    </div>
</div>





</div>


                          <div className="row">
                                <div className="col-md-12">

                                <label htmlFor="inputFN">Title</label>
                                <input value={editname}  onChange={(e)=>seteditname(e.target.value)}  type="text" className="form-control" id="inputFN" placeholder="Enter name" />
                                <div>
                                    {Object.keys(EditNameErr).map((key) => {
                                        return <div style={{ color: "red" }}>{EditNameErr[key]}</div>
                                    })}
                                </div>


                                </div>



                            </div>



                            <div className="row mt-2">
                                <div className="col-md-12">

                                <label htmlFor="inputL">Link</label>
                                <input value={editlink}  onChange={(e)=>seteditlink(e.target.value)}  type="text" className="form-control" id="inputL" placeholder="Enter Link" />
                                <div>
                                    {Object.keys(EditLinkErr).map((key) => {
                                        return <div style={{ color: "red" }}>{EditLinkErr[key]}</div>
                                    })}
                                </div>
                                </div>



                            </div>


                            <div className="row mt-2">
                                <div className="col-md-12">

                                <label htmlFor="inputFN">Description</label>
                                <textarea value={editdescription}  onChange={(e)=>seteditdescription(e.target.value)} type="text" className="form-control" id="inputFN" placeholder="Enter Description" />
                                <div>
                                    {Object.keys(EditDescriptionErr).map((key) => {
                                        return <div style={{ color: "red" }}>{EditDescriptionErr[key]}</div>
                                    })}
                                </div>


                                </div>



                            </div>



                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose2}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={()=>UpdateEmailMarketing()} >
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* modal for send  */}



            <Modal
                scrollable={true}
                size="lg"
                // aria-labelledby="example-modal-sizes-title-lg"
                show={show3}
                onHide={handleClose3}>
                <Modal.Header >
                    <Modal.Title>Sms Details</Modal.Title>
                    <hr />
                    <i onClick={handleClose3} style={{ fontSize: "24px" }} className=" fa-10X fas fa-window-close"></i>
                </Modal.Header>




                <Modal.Body  >

                    <form>
                        <div className="row">
                        <div className="col-md-12" style={{textAlign:"end"}}>
                            {Select?
                        <Button onClick={AllCheckbox} > Select All</Button>
                        :
                        <Button onClick={UncheckedAll} > Unchecked All</Button>
                            }

                        </div>

                    </div>


                            {emaillistdata.map((e)=>
                        <div className="row">

                            <div className="col-md-4">
                                <small style={{ fontSize: "13px" }}>Name</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{e.Name}</p>

                            </div>
                            <div className="col-md-4">
                                <small style={{ fontSize: "13px" }}>Email</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{e.Email}</p>

                            </div>

                            <div className="col-md-4" >
                                {/* <small style={{ fontSize: "13px" }}><Form.Check aria-label="option 1" /> Select All</small> */}

                                <p style={{ fontSize: "15px", fontWeight: "bold" ,marginTop:"6px"}}><input className="allcheckbox" type="checkbox" onChange={(id)=>mysetUserId(e.id)} /></p>

                            </div>

                        </div>
                            )}








                    </form>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="primary" onClick={handleClose3}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={()=>SendEmail()} >
                        Send
                    </Button>
                </Modal.Footer>
            </Modal>




        </>
    )
}