import React, { useState, useEffect } from "react"
import { useHistory } from "react-router"
import BaseUrl from "../../../../config/BaseUrl"
import { postApi, getApi,  } from "../../../../config/Api/FakeuserApi";
import Swal from 'sweetalert2'
import background from "../../Admin/Adminlogin/background.png"

export default function ConfirmPassword(props) {
    const history = useHistory();
    const [Loader, setLoader] = useState(false)
    const [Email, SetEmail] = useState("")
    const [password, setPassword] = useState('');
    const [renterpassword, setRenterpassword] = useState('');
    const [passwordErr, setPasswordErr] = useState({});
    const [renterpasswordErr, setRenterpasswordErr] = useState({});


    console.log("propssssss",props.location.state)



    const ChangePassword = () => {
        const isValid = formValidation();
        if (isValid == true) {
        setLoader(true);
        var formdata = new FormData();
        formdata.append("Email", props.location.state);
        formdata.append("Password", password);

       ;



        postApi(`${BaseUrl.base_url}/ChangePassword`, formdata)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    console.log("changePASS",data)
                    SetEmail("");
                    history.push("./SellerLogin")

                    Swal.fire({
                        title: "success",
                        text: data.message,
                        icon: "success"
                    })


                } else {

                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {

                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })
        }
            else{
                formValidation()
            }
    }




    const formValidation = () => {

        const passwordErr = {};
        const renterpasswordErr = {};

        const validPassword = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$');

        let isValid = true;


        if (!validPassword.test(password)) {
            passwordErr.passwordNull = "Enter Strong Password";
            isValid = false;
        }
        if (password.length == 0) {
            passwordErr.passwordNull = "Password is required";
            isValid = false;
        }
        if (!validPassword.test(renterpassword)) {
            renterpasswordErr.nameNull = "Enter Strong Password";
            isValid = false;
        }
        if (renterpassword.length == 0) {
            renterpasswordErr.nameNull = "Re-enter password is required";
            // if (renterpassword.trim() == password.trim()) {
            //     renterpasswordErr.passNotmatched = "Password is not matched";
            // }
            isValid = false;
        }
        if (password != renterpassword) {
            renterpasswordErr.nameNull = "Password not match!";
            isValid = false;
        }


        setPasswordErr(passwordErr);
        setRenterpasswordErr(renterpasswordErr);
        // console.log(props)

        return isValid;
    }





    return (
        <>
            {Loader?<div className="loader" />:null}
            <div id="app" style={{backgroundImage: `url(${background})`, marginTop: '-50px', backgroundSize: 'cover', height: '100vh' }} >
                <section className="section">
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <img style={{ width: "25%" }} src="assets/logo/HUGO-07.png"></img>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h4>Forget Password</h4>
                                    </div>
                                    <div className="card-body">
                                        <form method="" action="#" className="needs-validation" noValidate>
                                            <div className="form-group">
                                                <label htmlFor="pass">Password</label>
                                                <input  value={password} onChange={(e) => { setPassword(e.target.value) }}  id="pass" type="password" className="form-control" name="pass" tabIndex={1} required autofocus />
                                                <div>
                                                    {Object.keys(passwordErr).map((key) => {
                                                        return <div style={{ color: "red" }}>{passwordErr[key]}</div>
                                                    })}
                                                </div>

                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="conp">Confirm Password</label>
                                                <input value={renterpassword} onChange={(e) => { setRenterpassword(e.target.value) }}   id="conp" type="password" className="form-control" name="conp" tabIndex={1} required autofocus />
                                                <div>
                                                    {Object.keys(renterpasswordErr).map((key) => {
                                                        return <div style={{ color: "red" }}>{renterpasswordErr[key]}</div>
                                                    })}
                                                </div>

                                            </div>




                                            <div className="form-group">
                                                <button  type="button" onClick={()=>ChangePassword()} className="btn btn-primary btn-lg btn-block" tabIndex={4}>
                                                    Change Password</button>
                                            </div>
                                        </form>



                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}