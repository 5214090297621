import MaterialTable from 'material-table'
import React, { useState, useEffect } from "react"
import AdminHeader from "../components/adminheader/AdminHeader"
import AdminFooter from "../components/adminfooter/AdminFooter"
import Swal from 'sweetalert2'
import BaseUrl from "../../../../config/BaseUrl"
import { getApiWithToken, PutApi, PostApi, DeleteApi } from "../../../../config/Api/FakeuserApi";



function EmailList() {
    const [selectedRow, setSelectedRow] = useState(null);
    const [Loader, setLoader] = useState(false)
    const [Emaillist, setEmaillist] = useState([])



    const Token = localStorage.getItem('Token');
    useEffect(() => {
        GetEmailList()

    }, [])


     // VIEW ALL ROLES API
     const GetEmailList = () => {
        setLoader(true);

        getApiWithToken(`${BaseUrl.Admin_Url}/GetEmailList`, "", Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    setEmaillist(data.data)
                    console.log("setEmaillist==>", data)
                    // setdisable(true);
                    // $(".loader").hide();

                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })

    }



    return (
        <>
         {Loader ?<div class="loader"></div>:null}
         <div id="app">
         <AdminHeader />
         <div class="main-wrapper main-wrapper-1">
                    <div className="main-content">
                        <section className="section">
                            <ul className="breadcrumb breadcrumb-style ">
                                <li className="breadcrumb-item">
                                    <h4 className="page-title m-b-0">  Subadmin</h4>
                                </li>

                                <li className="breadcrumb-item active">Email List</li>

                            </ul>


        <div style={{ maxWidth: '100%' }}>
      <MaterialTable
        title=""

        data={Emaillist}

        columns={[
          { title: 'Name', field: 'Name' ,  export: false},
          { title: 'Email', field: 'Email' },


        ]}
        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}

        options={{
            rowStyle: rowData => ({
                backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
            }),
          exportButton: true,
           actionsColumnIndex: 7,
           headerStyle: {
                 backgroundColor: '#222',
                color: "white"
              }

        }}




      />

      </div>
      </section>
      </div>
      <AdminFooter />
      </div>
            </div>



      </>
    )
  }
export default EmailList