import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Route, } from 'react-router-dom';
import LandingPage from './allPages/pages/landingPage/landingPage';
import Login from './allPages/auth/login';
import SignUp from './allPages/auth/signup';
import EmailVarify from './allPages/auth/emailVarify';
import BusinessDetails from './allPages/auth/businessDetails';
import SignupDetails from './allPages/auth/signupDetails';
import Dashboard from './allPages/pages/dashboard/dashboard'
import SignUpWizard from './allPages/auth/signupWizard';
import AddProduct from './allPages/pages/Seller/addProduct/addProduct'
import AdminLogin from '../src/allPages/pages/Admin/Adminlogin/adminlogin'
import adminDashboard from './allPages/pages/Admin/adminDashboard/adminDashboard';
import AdminProfile from './allPages/pages/Admin/Adminprofile/AdminProfile';
import AddSubadmin from './allPages/pages/Admin/subadminview/AddSubadmin';
import SubadminView from './allPages/pages/Admin/subadminview/SubadminView';
import EditSubadmin from './allPages/pages/Admin/subadminview/EditSubadmin';
import AddRole from './allPages/pages/Admin/Roles/AddRoles';
import ViewRoles from './allPages/pages/Admin/Roles/ViewRoles';
import ProductManagement from './allPages/pages/Seller/productManagement/productManagement';
import NewDashboard from './allPages/pages/Seller/newDashboard/newDashoard';
import NewAddProduct from './allPages/pages/Seller/newAddproduct/newAddproduct2';
import Orders from './allPages/pages/Seller/orders/orders';
import Category from './allPages/pages/Admin/Category/Category';
import BrandNames from './allPages/pages/Admin/Brand/BrandNames';
import SuperAdminLogin from './allPages/pages/SuperAdmin/SuperAdminLgin/Login';
import SuperAdminDashboard from './allPages/pages/SuperAdmin/SADashboard/SuperAdminDashboard';
import SuperAdminProfile from './allPages/pages/SuperAdmin/Superadminprofile/SuperAdminProfile';
import AdminAccounts from './allPages/pages/SuperAdmin/AdminAccounts/AdminAccounts';
import Packages from "./allPages/pages/SuperAdmin/SAPackages/Packages"
import Membership from "./allPages/pages/SuperAdmin/membership/Membership"
import MembershipName from "./allPages/auth/MembershipName"
import ActiveVendors from './allPages/pages/Admin/ActiveVenders/ActiveVendors';
import PendingVendors from './allPages/pages/Admin/PendingVendors/PendingVectors';
import SellerLogin from "./allPages/pages/Seller/SellerLogin/SellerLogin"
import ViewProductDetails from "./allPages/pages/Seller/viewProductdetails/ProductDetails"
import Profile from "./allPages/pages/Seller/Profile/profile"
import InventoryManagement from "./allPages/pages/Admin/InventoryManagement/InventoryManagement"
import NewProDetails from "./allPages/pages/Admin/newproductDetails/NewProDetails"
import ManageProductimg from './allPages/pages/Seller/ManageProduct/ManageProImages';
import AffilateMarket from './allPages/pages/Seller/Marketing/AffilateMarket';
import ActiveAffilate from './allPages/pages/Admin/ActiveAffilate/Active';
import PendingAffilate from './allPages/pages/Admin/PendingAffilate/Pending';
import OrderDetails from './allPages/pages/Seller/orderDetails/OrderDetails';
import SmsMarketing from './allPages/pages/Admin/smsmarketing/SmsMarketing';
import EmailMarketing from './allPages/pages/Admin/emailmarketing/EmailMarketing';
import ForgetPassword from './allPages/pages/Seller/ForgetPassword/ForgetPassword';
import SellerVerify from './allPages/pages/Seller/SellerVerify/SellerVerify';
import AdminForgetPassword from './allPages/pages/Admin/AdminForgetPassword/AdminForgetPassword';
import AdminVerify from './allPages/pages/Admin/VerifyPassword/AdminVerify'
import SuperAdminForgetPassword from './allPages/pages/SuperAdmin/SupperAdminForgetPassword/SuperAdminForgetPassword'
import SuperAdminVerify from './allPages/pages/SuperAdmin/SuperAdminVerify/SuperAdminVerify';
import ConfirmPassword from './allPages/pages/Seller/confirmpassword/ConfirmPassword';
import AdminCpassword from './allPages/pages/Admin/Adminconfpass/AdminCpassword';
import SadminCPassword from './allPages/pages/SuperAdmin/SadminCpassword/SadminCPassword';
import EmailList from './allPages/pages/Admin/EmailList/EmailList';
import SendSmsList from './allPages/pages/Admin/SensSmsList/SendSmsList';
import SendEmailList from './allPages/pages/Admin/Sendemaillist/SendEmailList';
import Country from "./allPages/pages/SuperAdmin/country/Country"
import Active from './allPages/pages/SuperAdmin/activevendors/Active';
import Pending from "./allPages/pages/SuperAdmin/Pendingvendors/Pending"
import InventoryManag from "./allPages/pages/SuperAdmin/inventorymanagement/InventoryManag"
import SuperOrders from "./allPages/pages/SuperAdmin/orders/SuperOrders"
import AdminOrder from "./allPages/pages/Admin/orders/AdminOrder"
import AdminOdetails from "./allPages/pages/Admin/orderDetails/AdminOdetails"
import SuperOdetails from "./allPages/pages/SuperAdmin/orderdetails/SuperOdetails"



















function App() {
  return (
    <Router>
      <Route path="/" component={LandingPage} exact></Route>
      <Route path="/login" component={Login} exact></Route>
      <Route path="/signup" component={SignUp} exact></Route>
      <Route path="/varify" component={EmailVarify} exact></Route>
      <Route path="/businessDetails" component={BusinessDetails} exact></Route>
      <Route path="/wizard" component={SignUpWizard} exact></Route>
      <Route path="/signupdetails" component={SignupDetails} exact></Route>
      <Route path="/dashboard" component={Dashboard} exact></Route>
      <Route path="/addProduct" component={AddProduct} exact></Route>
      <Route path="/AdminLogin" component={AdminLogin} exact></Route>
      <Route path="/adminDashboard" component={adminDashboard} exact></Route>
      <Route path="/AdminProfile" component={AdminProfile} exact></Route>
      <Route path="/AddSubadmin" component={AddSubadmin} exact></Route>
      <Route path="/SubadminView" component={SubadminView} exact></Route>
      <Route path="/EditSubadmin" component={EditSubadmin} exact></Route>
      <Route path="/AddRole" component={AddRole} exact></Route>
      <Route path="/ViewRoles" component={ViewRoles} exact></Route>
      <Route path="/productManagement" component={ProductManagement}></Route>
      <Route path="/newDashboard" component={NewDashboard}></Route>
      <Route path="/newAddproduct" component={NewAddProduct}></Route>
      <Route path="/orders" component={Orders}></Route>
      <Route path="/Category" component={Category}></Route>
      <Route path="/BrandNames" component={BrandNames}></Route>
      <Route path="/SuperAdminLogin" component={SuperAdminLogin}></Route>
      <Route path="/SuperAdminDashboard" component={SuperAdminDashboard}></Route>
      <Route path="/SuperAdminProfile" component={SuperAdminProfile}></Route>
      <Route path="/AdminAccounts" component={AdminAccounts}></Route>
      <Route path="/Packages" component={Packages}></Route>
      <Route path="/Membership" component={Membership}></Route>
      <Route path="/Memberships" component={MembershipName}></Route>
      <Route path="/ActiveVendors" component={ActiveVendors}></Route>
      <Route path="/PendingVendors" component={PendingVendors}></Route>
      <Route path="/SellerLogin" component={SellerLogin}></Route>
      <Route path="/ViewProductDetails" component={ViewProductDetails}></Route>
      <Route path="/InventoryManagement" component={InventoryManagement}></Route>
      <Route path="/NewProDetails" component={NewProDetails}></Route>
      <Route path="/ManageProductimg" component={ManageProductimg}></Route>
      <Route path="/AffilateMarket" component={AffilateMarket}></Route>
      <Route path="/ActiveAffilate" component={ActiveAffilate}></Route>
      <Route path="/PendingAffilate" component={PendingAffilate}></Route>
      <Route path="/OrderDetails" component={OrderDetails}></Route>
      <Route path="/SmsMarketing" component={SmsMarketing}></Route>
      <Route path="/EmailMarketing" component={EmailMarketing}></Route>
      <Route path="/ForgetPassword" component={ForgetPassword}></Route>
      <Route path="/SellerVerify" component={SellerVerify}></Route>
      <Route path="/AdminForgetPassword" component={AdminForgetPassword}></Route>
      <Route path="/AdminVerify" component={AdminVerify}></Route>
      <Route path="/SuperAdminForgetPassword" component={SuperAdminForgetPassword}></Route>
      <Route path="/SuperAdminVerify" component={SuperAdminVerify}></Route>
        <Route path="/ConfirmPassword" component={ConfirmPassword}></Route>
        <Route path="/AdminCpassword" component={AdminCpassword}></Route>
        <Route path="/SadminCPassword" component={SadminCPassword}></Route>
        <Route path="/EmailList" component={EmailList}></Route>
        <Route path="/SendSmsList" component={SendSmsList}></Route>
        <Route path="/SendEmailList" component={SendEmailList}></Route>
        <Route path="/Country" component={Country}></Route>
        <Route path="/Active" component={Active}></Route>
        <Route path="/Pending" component={Pending}></Route>
        <Route path="/InventoryManag" component={InventoryManag}></Route>
        <Route path="/SuperOrders" component={SuperOrders}></Route>
        <Route path="/AdminOrder" component={AdminOrder}></Route>
        <Route path="/AdminOdetails" component={AdminOdetails}></Route>
        <Route path="/SuperOdetails" component={SuperOdetails}></Route>
        <Route path="/profile" component={Profile}></Route>
    </Router>
  );
}

export default App;
