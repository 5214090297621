import React, { useState, useEffect } from "react"
import { useHistory } from "react-router"
import BaseUrl from "../../../../config/BaseUrl"
import { postApi, getApi, PostApi, getApiWithToken, PostApiWithOutToken } from "../../../../config/Api/FakeuserApi";
import Swal from 'sweetalert2'
import background from "../Adminlogin/background.png"

export default function AdminForgetPassword() {
    const history = useHistory();
    const [Loader, setLoader] = useState(false)
    const [email, Setemail] = useState("")
    const [password, Setpassword] = useState("")
    // const [PasswordError, SetPasswordError] = useState("")

   const [EditemailErr, SetEditemailErr] = useState({})






    const SendVerificationCode = () => {

        const isValid = UpdateformValidation();
        if (isValid== true){
        setLoader(true);
        var formdata = new FormData();
        formdata.append("Email", email);
       ;



        postApi(`${BaseUrl.Admin_Url}/SendVerificationCode`, formdata)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    console.log("Adminverification code==>",data)
                    Setemail("");
                    history.push({
                        pathname: "/AdminVerify",
                        state: data,email
                    })

                    // Swal.fire({
                    //     title: "success",
                    //     text: data.message,
                    //     icon: "success"
                    // })
                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })

        }
        else{
            UpdateformValidation()


        }
    }



    //  validation

    const UpdateformValidation = () => {

        const EditemailErr = {};


        let isValid = true;

        if (email == 0) {
            EditemailErr.firstNamenull = "Required Email";
            isValid = false;
        }






        SetEditemailErr(EditemailErr);




        return isValid;
    }











    return (
        <>
            {Loader?<div className="loader" />:null}
            <div id="app" style={{backgroundImage: `url(${background})`, marginTop: '-50px', backgroundSize: 'cover', height: '100vh' }}>
                <section className="section">
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <img style={{ width: "25%" }} src="assets/logo/HUGO-05.png"></img>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h4>Forget Password</h4>
                                    </div>
                                    <div className="card-body">
                                        <form method="" action="#" className="needs-validation" noValidate>
                                            <div className="form-group">
                                                <label htmlFor="email"> Email</label>
                                                <input value={email} onChange={(e)=>Setemail(e.target.value)}  id="email" type="email" className="form-control" name="email" tabIndex={1} required autofocus />
                                                <div>
                                    {Object.keys(EditemailErr).map((key) => {
                                        return <div style={{ color: "red" }}>{EditemailErr[key]}</div>
                                    })}
                                </div>
                                            </div>


                                            <div className="form-group">
                                                <button  onClick={()=>SendVerificationCode()} type="button" className="btn btn-primary btn-lg btn-block" tabIndex={4}>
                                                    Send Email</button>

                                            </div>
                                        </form>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}