import React, { useState, useEffect } from "react"
import SuperAdminHeader from "../components/SAheader/Header"
import SuperAdminFooter from "../components/SAfooter/Footer"
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from 'material-table'
import { useHistory } from "react-router";
import Swal from 'sweetalert2'
import BaseUrl from "../../../../config/BaseUrl"
import image_url from "../../../../config/BaseUrl"
import { getApiWithToken, PutApi, PostApi, DeleteApi } from "../../../../config/Api/FakeuserApi";
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import IconButton from "@material-ui/core/IconButton";
import Country from "../../../components/countries/countries";

// import{ MaterialTable} from "@material-ui/core/MaterialTable";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    input: {
        display: "none",
    },
}));

export default function Packages() {
    // ADD PAKAGES STATES
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [Loader, setLoader] = useState(false)
    const classes = useStyles();
    const [disabledate,Setdisabledate ] = useState("");


    // ADD MODAL STATES
    const [Status, SetStatus] = useState("")
    const [PackAdmin, SetPackAdmin] = useState("")
    const [PackPrice, SetPackPrice] = useState("")
    const [PackName, SetPackName] = useState("")
    const [PackDurationStart, SetPackDurationStart] = useState("")
    const [PackDurationEnd, SetPackDurationEnd] = useState("")
    const [PackDescription, SetPackDescription] = useState("")
    const [Packages, SetPackages] = useState([])
    const [MemCountry, SetMemCountry] = useState("")
    const [MemCountryErr, setMemCountryErr] = useState({});

    // add form error states
    const [pckgenErr, setpckgenErr] = useState({});
    const [packDErr, setpackDErr] = useState({});
    const [packDSErr, setpackDSErr] = useState({});
    const [packDeErr, setpackDeErr] = useState({});
    const [packPErr, setpackPErr] = useState({});
    const [pakageAErr, setpakageAErr] = useState({});
    const [pcErr, setpcErr] = useState({});
    const [psAErr, setpsAErr] = useState({});

    // add form  error states

    // add form validation
    const formValidation = () => {
        const pckgenErr = {};
        const packDErr = {};
        const packDSErr = {};
        const packDeErr = {};
        const packPErr = {};
        const pakageAErr = {};
        const pcErr = {};
        const psAErr = {};

        let isValid = true;

        if (PackName == "") {
            pckgenErr.packgeNull = "Package name is required";
            isValid = false;
        }
        if (PackDescription == "") {
             packDErr.fnameNull = "Package Description is required";
            isValid = false;
        }
        if (PackDurationStart == "") {
            packDSErr.lnameNull = "Package Start Duration  required";
            isValid = false;
        }
        if (PackDurationEnd == "") {
            packDeErr.contactNull = "Package End Duration required";
            isValid = false;
        }
        if (PackAdmin == "") {
            pakageAErr.emailNull = "Admin required";
            isValid = false;
        }
        if (MemCountry == "") {
            pcErr.passwordNull = "Country required";
            isValid = false;
        }
        if (Status=="") {
            psAErr.passwordNull = "Status Required";
            isValid = false;
        }
        if (PackPrice == "") {
            packPErr.pakageNull = "Package Price required";
            isValid = false;
        }



         setpckgenErr(pckgenErr);
        setpackDErr(packDErr);
        setpackDSErr(packDSErr);
        setpackDeErr(packDeErr);
        setpackPErr(packPErr);
        setpakageAErr(pakageAErr);
        setpcErr(pcErr);
        setpsAErr(psAErr);
        return isValid;
    }



    // Edit MODAL STATES
    const [PackId, setPackId] = useState('')
    const [EditStatus, SetEditStatus] = useState("")
    const [EditPackAdmin, SetEditPackAdmin] = useState("")
    const [EditPackPrice, SetEditPackPrice] = useState("")
    const [EditPackName, SetEditPackName] = useState("")
    const [EditPackDurationStart, SetEditPackDurationStart] = useState("")
    const [EditPackDurationEnd, SetEditPackDurationEnd] = useState("")
    const [EditPackDescription, SetEditPackDescription] = useState("")
    const [EditCountry, SetEditCountry] = useState("")


    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [pckUgenErr, setpckUgenErr] = useState({});
    const [packUDErr, setpackUDErr] = useState({});
    const [packUDSErr, setpackUDSErr] = useState({});
    const [packUDeErr, setpackUDeErr] = useState({});
    const [packUPErr, setpackUPErr] = useState({});
    const [pakUageAErr, setpakUageAErr] = useState({});
    const [pcUErr, setpcUErr] = useState({});
    const [psAUErr, setpsAUErr] = useState({});


    const UpdateformValidation = () => {
        const pckUgenErr = {};
        const packUDErr = {};
        const packUDSErr = {};
        const packUDeErr = {};
        const packUPErr = {};
        const pakUageAErr = {};
        const pcUErr = {};
        const psAUErr = {};

        let isValid = true;

        if (EditPackName == "") {
            pckUgenErr.upackgeNull = "Package name is required";
            isValid = false;
        }
        if (EditPackDescription == "") {
            packUDErr.ufnameNull = "Package Description is required";
            isValid = false;
        }
        if (EditPackDurationStart == "") {
            packUDSErr.uameNull = "Package Start Duration  required";
            isValid = false;
        }
        if (EditPackDurationEnd == "") {
            packUDeErr.ucontactNull = "Package End Duration required";
            isValid = false;
        }
        if (EditPackAdmin == "") {
            pakUageAErr.uemailNull = "Admin required";
            isValid = false;
        }
        if (EditCountry == "") {
            pcUErr.upasswordNull = "Country required";
            isValid = false;
        }
        if (EditStatus=="") {
            psAUErr.upasswordNull = "Status Required";
            isValid = false;
        }
        if (EditPackPrice == "") {
            packUPErr.upakageNull = "Package Price required";
            isValid = false;
        }



        setpckUgenErr(pckUgenErr);
        setpackUDErr(packUDErr);
        setpackUDSErr(packUDSErr);
        setpackUDeErr(packUDeErr);
        setpackUPErr(packUPErr);
        setpakUageAErr(pakUageAErr);
        setpcUErr(pcUErr);
        setpsAUErr(psAUErr);
        return isValid;
    }






    console.log("package==.", Packages)
    // gETtoken from local storage
    const Token = localStorage.getItem('SuperToken');
    useEffect(() => {
        var dateObj = new Date();
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
        if(month < 10){
        month = '0' + month.toString();
        }
        var day = dateObj.getUTCDate();
        var year = dateObj.getUTCFullYear();
      var  newdate = year + "-" + month + "-" + day;
      console.log("mewdate",newdate)
      Setdisabledate(newdate)
        ViewAllPackages()
        // PickDate()


    }, [])

    // VIEW ALL PACKAGES API

    const ViewAllPackages = () => {
        setLoader(true);
        getApiWithToken(`${BaseUrl.Super_Admin_Url}/AdminPackages`, "", Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    // SetGetAccounts(data.data)
                    console.log("packages==>", data)
                    SetPackages(data.data)
                    // setdisable(true);
                    // $(".loader").hide();

                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })

    }




    // ADD ACCOUNTS API

    const AddNewPackage = () => {
        const isValid = formValidation();

        if (isValid == true) {
        setLoader(true);
        var formdata = new FormData();
        formdata.append("PackName", PackName);
        formdata.append("PackDescription", PackDescription);
        formdata.append("PackAdmin", PackAdmin);
        // formdata.append("lname", AddLName);
        formdata.append("PackDurationStart", PackDurationStart);
        // formdata.append("PackDurationEnd", PackDurationStart);
        formdata.append("PackDurationEnd", PackDurationEnd);
        formdata.append("Country", MemCountry);

        formdata.append("PackPrice", PackPrice);
        formdata.append("Status", Status);


        PostApi(`${BaseUrl.Super_Admin_Url}/AdminPackages`, formdata, Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    console.log("Addpackage==>", data)
                    // SetRole("")
                    // setSelectedThumbnail("")
                    ViewAllPackages()
                    handleClose()

                    Swal.fire({
                        title: "success",
                        text: data.message,
                        icon: "success"
                    })
                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })


    }else {
        formValidation()
    }
}

    // DELETE API

    const Delete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete it",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                DeletePackage(id)
            }
        })

    }

    const DeletePackage = (id) => {
        // console.log("token==>",Token)

        setLoader(true);
        // var formdata = new FormData();
        // formdata.append("PackId", id);

        DeleteApi(`${BaseUrl.Super_Admin_Url}/AdminPackages?PackId=${id}`, "", Token)
            .then(({ data }) => {
                if (data.status) {
                    setLoader(false);
                    console.log("dELETE==>", data)
                    ViewAllPackages()
                    // handleClose2()

                    Swal.fire({
                        title: "success",
                        text: data.message,
                        icon: "success"
                    })
                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })
    }

    // Edit function
    const edit = (id, packname, packdes, packDstart, packDend, packprice, packadmin,upcountry, packstatus) => {
        handleShow2()
        SetEditPackName(packname)
        SetEditPackDescription(packdes)
        SetEditPackDurationStart(packDstart)
        SetEditPackDurationEnd(packDend)
        SetEditPackPrice(packprice)
        SetEditPackAdmin(packadmin)
        SetEditCountry(upcountry)
        SetEditStatus(packstatus)
        setPackId(id)

    }

    // EDITAPI EDIT SUBADMIN PANEL
    const UpdatePackage = () => {
        const updatevalid = UpdateformValidation();

        if (updatevalid == true) {

        setLoader(true);
        var formdata = new FormData();
        // formdata.append("PackId", Email);
        formdata.append("PackId", PackId);
        formdata.append("PackDescription", EditPackDescription);
        formdata.append("PackAdmin", EditPackAdmin);
        formdata.append("PackDurationStart", EditPackDurationStart);
        formdata.append("PackDurationEnd", EditPackDurationEnd);
        formdata.append("PackName", EditPackName);
        formdata.append("PackPrice", EditPackPrice);
        formdata.append("Country", EditCountry);
        formdata.append("Status", EditStatus);

        PutApi(`${BaseUrl.Super_Admin_Url}/AdminPackages`, formdata, Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    console.log("dataaupdate==>", data)
                    ViewAllPackages()
                    handleClose2()

                    Swal.fire({
                        title: "success",
                        text: data.message,
                        icon: "success"
                    })
                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })
    } else {
        UpdateformValidation();
    }
}





// const PickDate=()=>{

//     var dateObj = new Date();
//     var month = dateObj.getUTCMonth() + 1; //months from 1-12
//     var day = dateObj.getUTCDate();
//     var year = dateObj.getUTCFullYear();

//   var  newdate = year + "-" + month + "-" + day;
//   console.log("newdate",newdate)
//   Setdisabledate(newdate)
// }



    return (
        <>
           {Loader? <div class="loader"></div>:null}
            <div id="app">
                <SuperAdminHeader />
                <div class="main-wrapper main-wrapper-1">

                    <div class="main-content">
                        <section class="section">
                            <ul class="breadcrumb breadcrumb-style ">
                                <li class="breadcrumb-item">
                                    <h4 class="page-title m-b-0">PACKAGES</h4>
                                </li>
                                <div class="row">
                                    <div class="col-md-12  text-right">
                                        <button onClick={() => handleShow()} type="button" className="btn btn-outline-primary">Add Package</button>

                                    </div>
                                </div>

                            </ul>

                            <div class="section-body">
                                <div class="row">

                                    {Packages.map((e) =>

                                        <div class=" col-md-4 ">
                                            <div class="pricing pricing-highlight">
                                                <div class="pricing-title">
                                                    {e.PackName}</div>
                                                <div class="pricing-padding">
                                                    <div class="pricing-price">
                                                        <div>$ {e.PackPrice}</div>
                                                        <div>Package Price</div>
                                                    </div>
                                                    <div class="pricing-details">
                                                        <div class="pricing-item">
                                                            <div class="pricing-item-icon bg-primary" ><i class="fas fa-check"></i></div>
                                                            <div class="pricing-item-label">Package Admin : {e.PackAdmin}</div>
                                                        </div>
                                                        <div class="pricing-item">
                                                            <div class="pricing-item-icon bg-primary"><i class="fas fa-check"></i></div>
                                                            <div class="pricing-item-label">Package Duration Start : {e.PackDurationStart}</div>
                                                        </div>
                                                        <div class="pricing-item">
                                                            <div class="pricing-item-icon bg-primary"><i class="fas fa-check"></i></div>
                                                            <div class="pricing-item-label"> Package Duration End : {e.PackDurationEnd}</div>
                                                        </div>
                                                        <div class="pricing-item">
                                                            <div class="pricing-item-icon bg-primary"><i class="fas fa-check"></i></div>
                                                            <div class="pricing-item-label">Country : {e.Country}</div>
                                                        </div>
                                                        <div class="pricing-item">
                                                            <div class="pricing-item-icon bg-primary"><i class="fas fa-check"></i></div>
                                                            <div class="pricing-item-label">Status : {e.Status}</div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-6">

                                                                <button type="button" onClick={() => edit(e.PackId, e.PackName, e.PackDescription, e.PackDurationStart, e.PackDurationEnd, e.PackPrice, e.PackAdmin,e.Country, e.Status)} class="btn btn-primary btn-lg">Edit</button>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <button type="button" onClick={(a) => Delete(e.PackId)} class="btn btn-primary btn-lg">Delete</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </section>

                    </div>

                    <SuperAdminFooter />
                </div>
            </div>



            {/* Modal 1 Add   */}


            <Modal className="btn-close"
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg"
                show={show}
                onHide={handleClose}>
                <Modal.Header >
                    <Modal.Title>Add New Package</Modal.Title>
                    <i onClick={handleClose} style={{ fontSize: "24px" }} className=" fa-10X fas fa-window-close"></i>
                </Modal.Header>
                <Modal.Body>

                    <form>
                        <div className="form-row text-center " style={{ display: "block" }}>




                        </div>
                        <div className="form-row mt-4">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputFN">Package Name</label>
                                <input value={PackName} onChange={e => SetPackName(e.target.value)} type="text" className="form-control" id="inputFN" placeholder="Enter Package Name" />
                                <div>
                                        {Object.keys(pckgenErr).map((key) => {
                                            return <div style={{ color: "red" }}>{pckgenErr[key]}</div>
                                        })}
                                    </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputLN">Package Description</label>
                                <textarea value={PackDescription} onChange={e => SetPackDescription(e.target.value)} type="text" className="form-control" id="inputLN" placeholder="Enter Package Description" />
                                <div>
                                        {Object.keys(packDErr).map((key) => {
                                            return <div style={{ color: "red" }}>{packDErr[key]}</div>
                                        })}
                                    </div>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputEmail">Package Duration Start</label>
                                <input value={PackDurationStart} onChange={e => SetPackDurationStart(e.target.value)} min={disabledate} type="date" className="form-control" id="txtDate" placeholder=" " />
                                <div>
                                        {Object.keys(packDSErr).map((key) => {
                                            return <div style={{ color: "red" }}>{packDSErr[key]}</div>
                                        })}
                                    </div>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputPS">Package Duration End</label>
                                <input value={PackDurationEnd} onChange={e => SetPackDurationEnd(e.target.value)} min={disabledate} type="date" className="form-control" id="inputPS" placeholder="" />
                                <div>
                                        {Object.keys(packDeErr).map((key) => {
                                            return <div style={{ color: "red" }}>{packDeErr[key]}</div>
                                        })}
                                    </div>
                            </div>

                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputCN">Package Price</label>
                                <input value={PackPrice} onChange={e => SetPackPrice(e.target.value)} min={disabledate} min="0" max="111111111111111111111" type="number" className="form-control" id="inputCN" placeholder="Enter Package Price" />
                                <div>
                                        {Object.keys(packPErr).map((key) => {
                                            return <div style={{ color: "red" }}>{packPErr[key]}</div>
                                        })}
                                    </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputCN">Package Admin</label>
                                <input value={PackAdmin} onChange={e => SetPackAdmin(e.target.value)} type="number" className="form-control" id="inputCN" placeholder="Enter Package Admin " />
                                <div>
                                        {Object.keys(pakageAErr).map((key) => {
                                            return <div style={{ color: "red" }}>{pakageAErr[key]}</div>
                                        })}
                                    </div>
                            </div>


                            <div class="form-group col-md-6 ">
                                <label for="exampleFormControlSelect1">Status</label>
                                <select onClick={(e) => SetStatus(e.target.value)} class="form-control" id="exampleFormControlSelect1">
                                <option>Select Status...</option>
                                    <option >True</option>
                                    <option >False</option>



                                </select>
                                <div>
                                        {Object.keys(psAErr).map((key) => {
                                            return <div style={{ color: "red" }}>{psAErr[key]}</div>
                                        })}
                                    </div>
                            </div>
                            <div class="form-group col-md-6 ">
                            <label for="exampleFormControlSelect1">Select Membership Country</label>

                            <Country
                             value={MemCountry}
                             onSelect={(country) => {
                                SetMemCountry(country)
                             }}
                            />
                             <div>
                                    {Object.keys(pcErr).map((key) => {
                                        return <div style={{ color: "red" }}>{pcErr[key]}</div>
                                    })}
                                </div>

                            </div>

                        </div>



                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => AddNewPackage()} >
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* MODAL 2 FOR EDIT  */}


            <Modal className="btn-close"
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg"
                show={show2}
                onHide={handleClose2}>
                <Modal.Header >
                    <Modal.Title>Edit Package</Modal.Title>
                    <i onClick={handleClose2} style={{ fontSize: "24px" }} className=" fa-10X fas fa-window-close"></i>
                </Modal.Header>
                <Modal.Body>

                    <form>
                        <div className="form-row text-center " style={{ display: "block" }}>




                        </div>
                        <div className="form-row mt-4">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputFN">Package Name</label>
                                <input value={EditPackName} onChange={e => SetEditPackName(e.target.value)} type="text" className="form-control" id="inputFN" placeholder="Enter Package Name" />
                                <div>
                                        {Object.keys(pckUgenErr).map((key) => {
                                            return <div style={{ color: "red" }}>{pckUgenErr[key]}</div>
                                        })}
                                    </div>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputLN">Package Description</label>
                                <textarea value={EditPackDescription} onChange={e => SetEditPackDescription(e.target.value)} type="text" className="form-control" id="inputLN" placeholder="Enter Package Description" />
                                <div>
                                        {Object.keys(packUDErr).map((key) => {
                                            return <div style={{ color: "red" }}>{packUDErr[key]}</div>
                                        })}
                                    </div>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputEmail">Package Duration Start</label>
                                <input value={EditPackDurationStart} onChange={e => SetEditPackDurationStart(e.target.value)} min={disabledate} type="date" className="form-control" id="inputEmail" placeholder=" " />
                                <div>
                                        {Object.keys(packUDSErr).map((key) => {
                                            return <div style={{ color: "red" }}>{packUDSErr[key]}</div>
                                        })}
                                    </div>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputPS">Package Duration End</label>
                                <input value={EditPackDurationEnd} onChange={e => SetEditPackDurationEnd(e.target.value)} min={disabledate} type="date" className="form-control" id="inputPS" placeholder="" />
                                <div>
                                        {Object.keys(packUDeErr).map((key) => {
                                            return <div style={{ color: "red" }}>{packUDeErr[key]}</div>
                                        })}
                                    </div>
                            </div>

                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputCN">Package Price</label>
                                <input value={EditPackPrice} onChange={e => SetEditPackPrice(e.target.value)} min="0" max="111111111111" type="number" className="form-control" id="inputCN" placeholder="Enter Package Price" />
                                <div>
                                        {Object.keys(packUPErr).map((key) => {
                                            return <div style={{ color: "red" }}>{packUPErr[key]}</div>
                                        })}
                                    </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputCN">Package Admin</label>
                                <input value={EditPackAdmin} onChange={e => SetEditPackAdmin(e.target.value)} type="number" className="form-control" id="inputCN" placeholder="Enter Package Admin " />
                                <div>
                                        {Object.keys(pakUageAErr).map((key) => {
                                            return <div style={{ color: "red" }}>{pakUageAErr[key]}</div>
                                        })}
                                    </div>
                            </div>

                            </div>
                            <div className="form-row">

                            <div class="form-group col-md-6 ">
                            <label for="exampleFormControlSelect1">Select Membership Country</label>

                            <Country
                             value={EditCountry}
                             onSelect={(country) => {
                                SetEditCountry(country)
                             }}
                            />
                             <div>
                                        {Object.keys(pcUErr).map((key) => {
                                            return <div style={{ color: "red" }}>{pcUErr[key]}</div>
                                        })}
                                    </div>


                            </div>


                            <div class="form-group col-md-6 ">
                                <label for="exampleFormControlSelect1">Status</label>
                                <select value={EditStatus} onChange={(e) => SetEditStatus(e.target.value)} class="form-control" id="exampleFormControlSelect1">
                                <option>Select Status...</option>
                                    <option value='True'>True</option>
                                    <option value='False'>False</option>
                                </select>
                                <div>
                                        {Object.keys(psAUErr).map((key) => {
                                            return <div style={{ color: "red" }}>{psAUErr[key]}</div>
                                        })}
                                    </div>


                        </div>
                        </div>



                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose2}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => UpdatePackage()} >
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>





        </>
    )
}