import React, { useState, useEffect } from "react"
import SuperAdminHeader from "../components/SAheader/Header"
import SuperAdminFooter from "../components/SAfooter/Footer"
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from 'material-table'
import { useHistory } from "react-router";
import Swal from 'sweetalert2'
import BaseUrl from "../../../../config/BaseUrl"
import { getApiWithToken, PutApi, PostApi, DeleteApi } from "../../../../config/Api/FakeuserApi";
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import IconButton from "@material-ui/core/IconButton";




const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    input: {
        display: "none",
    },
}));

export default function Country() {
    const history = useHistory();
    const [selectedRow, setSelectedRow] = useState(null);
    const [Loader, setLoader] = useState(false)

    // AddROLES Modal States1
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const classes = useStyles();

    // Edit api states Modal 2
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);


//image states
    const [SelectedThumbnail, setSelectedThumbnail] = useState(null)
    const [UpdateThumbnail, setUpdateThumbnail] = useState(null)
    const [Selectedprofile, setSelectedprofile] = useState(null)

    //get curency states
    const [getData, SetgetData] = useState([])


    //ADD DATA STATES
    const [country, Setcountry] = useState("")
    const [symbolText, SetsymbolText] = useState("")
    const [AddSubProfile, SetAddSubProfile] = useState(null)

    //add validation states

    const [countryErr, SetcountryErr] = useState({})
    const [symbolTextErr, SetsymbolTextErr] = useState({})
    const [AddSubProfileErr, SetAddSubProfileErr] = useState({})

    //update data api
    const [ucountry, Setucountry] = useState("")
    const [suymbolText, SetusymbolText] = useState("")
    const [upId, SetupId] = useState("")
    const [profileimage, setprofileimage] = useState("")


    //update validation states
    const [ucountryErr, SetucountryErr] = useState({})
    const [usymbolTextErr, SetusymbolTextErr] = useState({})
    const [profileimageErr, setprofileimageErr] = useState({})



    const Token = localStorage.getItem('SuperToken');

useEffect(() => {
    GetCurrencyData()

}, []);






    // PROFILE IMAG WORK

    const handleInputFileChange = (event, func) => {
        var file = event.target.files[0];
        var reader = new FileReader();
        // console.log(file);
        const filesize = file.size
        const filesizeround = Math.round((filesize / 1024))
        if (filesizeround > 1024) {
            // setupdateProfileImage(null)
            // setselectProfileImage(null)
            Swal.fire({
                title: "Opps",
                text: "plz select your image less then and equals to 1mb",
                icon: "warning"
            });
        } else {
            var url = reader.readAsDataURL(file);

            reader.onloadend = function (e) {
                setSelectedprofile(reader.result);
                setUpdateThumbnail(file);



            };

            func(event.target.files[0])
            // setdisable(false)
        }
    }

    //get country currency data

      const GetCurrencyData = () => {

        setLoader(true);
        getApiWithToken(`${BaseUrl.Super_Admin_Url}/AddCountry`, "", Token)

            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    SetgetData(data.data)
                    console.log("Currency data==>", data)
                    // setdisable(true);
                    // $(".loader").hide();

                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })

    }


    //ADD DATA API


    const AddData = () => {
        const isValid = formValidation();
        if (isValid == true) {


            setLoader(true);
            var formdata = new FormData();
            formdata.append("Country", country);
            formdata.append("SymbolText", symbolText);
            formdata.append("CountryFlag", AddSubProfile);





            PostApi(`${BaseUrl.Super_Admin_Url}/AddCountry`, formdata, Token)
                .then(({ data }) => {
                    setLoader(false);
                    console.log("Addcurrencydata==>", data)
                    if (data.status) {
                        GetCurrencyData()
                        handleClose()


                        Swal.fire({
                            title: "success",
                            text: data.message,
                            icon: "success"
                        })
                    } else {
                        // $(".loader").hide();
                        Swal.fire({
                            title: "Opps",
                            text: data.message,
                            icon: "error"
                        })
                    }
                }).catch(function (e) {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: e.message,
                        icon: "error"
                    })
                })

            } else {
                formValidation()
            }


    }


    const formValidation = () => {
        const countryErr = {};
        const symbolTextErr = {};
        const AddSubProfileErr = {};



        let isValid = true;

        if (country == "") {
            countryErr.firstNamenull = "Required Country";
            isValid = false;
        }
        if (symbolText == "") {
            symbolTextErr.lastNamenull = "Required Curreny";
            isValid = false;
        }
        if (AddSubProfile == null) {
            AddSubProfileErr.emailnull = "Required Image";
            isValid = false;
        }


        SetcountryErr(countryErr);
        SetsymbolTextErr(symbolTextErr);
        SetAddSubProfileErr(AddSubProfileErr);




        return isValid;
    }



    //DELETE DATA
    const Delete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete it",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteData(id)
            }
        })

    }
    // DELETE API

    const DeleteData = (id) => {
        setLoader(true);
        var formdata = new FormData();
        formdata.append("id", id);
        DeleteApi(`${BaseUrl.Super_Admin_Url}/AddCountry`, formdata, Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    console.log("dELETE==>", data)

                    GetCurrencyData()

                    Swal.fire({
                        title: "success",
                        text: data.message,
                        icon: "success"
                    })
                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })
    }


    //UPDATE DATA


    const UpdateData = () => {
        const isValid = UpdateformValidation();
        if (isValid == true) {




            setLoader(true);
            var formdata = new FormData();
            formdata.append("Country", ucountry);
            formdata.append("SymbolText", suymbolText);
            formdata.append("CountryFlag", UpdateThumbnail);
            formdata.append("id", upId);



            PutApi(`${BaseUrl.Super_Admin_Url}/AddCountry`, formdata, Token)
                .then(({ data }) => {
                    setLoader(false);
                    if (data.status) {
                        console.log("edit data==>",data)
                        GetCurrencyData()
                        handleClose2()

                        Swal.fire({
                            title: "success",
                            text: data.message,
                            icon: "success"
                        })
                    } else {
                        // $(".loader").hide();
                        Swal.fire({
                            title: "Opps",
                            text: data.message,
                            icon: "error"
                        })
                    }
                }).catch(function (e) {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: e.message,
                        icon: "error"
                    })
                })

            } else {
                UpdateformValidation()
            }


    }



    const UpdateformValidation = () => {
        const ucountryErr = {};
        const usymbolTextErr = {};
        const profileimageErr = {};



        let isValid = true;

        if (ucountry == "") {
            ucountryErr.firstNamenull = "Required Country";
            isValid = false;
        }
        if (suymbolText == "") {
            usymbolTextErr.lastNamenull = "Required Curreny";
            isValid = false;
        }
        if (profileimage == null) {
            profileimageErr.emailnull = "Required Image";
            isValid = false;
        }


        SetucountryErr(ucountryErr);
        SetusymbolTextErr(usymbolTextErr);
        setprofileimageErr(profileimageErr);




        return isValid;
    }









    return (
        <>
            {Loader ? <div class="loader"></div> : null}
            <div id="app">
                <SuperAdminHeader />
                <div class="main-wrapper main-wrapper-1">
                    <div className="main-content">
                        <section className="section">
                            <ul className="breadcrumb breadcrumb-style ">
                                <li className="breadcrumb-item">
                                    <h4 className="page-title m-b-0">  Subadmin</h4>
                                </li>

                                <li className="breadcrumb-item active">Country</li>
                                <div class="row">
                                    <div class="col-md-12  text-right">
                                        <button onClick={() => handleShow()} type="button" className="btn btn-outline-primary">Add </button>

                                    </div>
                                </div>
                            </ul>

                            <div style={{ maxWidth: '100%' }}>
                                <MaterialTable

                                    title=""
                                    data={getData}
                                    columns={[




                                        { title: "Country", field: "Country" },
                                        { title: "Currency", field: "text", },
                                        { title: "Currency Symbols", field: "Symbol", },

                                        { title: "Flag", field: "CountryFlag",render: item =><img src={(item.CountryFlag)} border="3" height="60" width="60"  /> },


                                    ]}


                                    onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                    options={{
                                        rowStyle: rowData => ({
                                            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                        }),
                                        actionsColumnIndex: -1,
                                        exportButton: true
                                    }}
                                    // other props
                                    actions={[
                                        rowData => ({
                                            icon: 'edit',
                                            tooltip: 'Edit User',

                                            onClick: () => {
                                                handleShow2()

                                                Setucountry(rowData.Country)
                                                SetusymbolText(rowData.SymbolText)
                                                setprofileimage(rowData.CountryFlag)
                                                SetupId(rowData.id)





                                            }

                                        }),

                                        rowData => ({
                                            icon: 'delete',
                                            tooltip: 'Delete User',
                                            onClick: () => {
                                                console.log("rowData2==>", rowData)
                                                Delete(rowData.id)


                                            }
                                        })

                                    ]}
                                    options={{
                                        maxBodyHeight: 400,
                                        overflowX: 'x-scroll',
                                        actionsColumnIndex: 7,
                                        headerStyle: {
                                            backgroundColor: '#222',
                                            color: "white"
                                        }
                                    }}
                                />


                            </div>


                        </section>

                    </div>
                    <SuperAdminFooter />
                </div>
            </div>

            {/* //ADD MODAL */}

            <Modal
                size="md"
                aria-labelledby="example-modal-sizes-title-lg"
                show={show}
                onHide={handleClose}>
                <Modal.Header   >
                    <Modal.Title>Add Data</Modal.Title>
                    <i onClick={handleClose} style={{ fontSize: "24px" }} className=" fa-10X fas fa-window-close"></i>
                </Modal.Header>
                <Modal.Body>

                    <form>
                        <div className="form-row text-center " style={{ display: "block" }}>
                            <div className="row">
                                <div className="col-md-12 ">
                                    <center>
                                        <div className="upload_photo_main">
                                            <input onChange={(e) => handleInputFileChange(e,SetAddSubProfile)}
                                                accept="image/*"
                                                className={classes.input}
                                                id="icon-button-file"
                                                type="file"

                                            />
                                            <label htmlFor="icon-button-file">

                                                <IconButton
                                                    color="#414141"
                                                    aria-label="upload picture"
                                                    component="span"
                                                    className="upload_photo_main"
                                                >
                                                    <img style={{ minWidth: "120px", maxWidth: "120px", borderRadius: "20px" }}
                                                        src={Selectedprofile ? Selectedprofile : 'assets/img/cam.jpg'}

                                                        className="upload_photo_main" />
                                                </IconButton>
                                                <div>
                                                    {Object.keys(AddSubProfileErr).map((key) => {
                                                        return <div style={{ color: "red" }}>{AddSubProfileErr[key]}</div>
                                                    })}
                                                </div>
                                            </label>
                                        </div>
                                    </center>
                                </div>
                            </div>

                        </div>

                        <div className="form-row mt-4">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputAR">Select Country</label>

                                <select value={country} onChange={(e)=>Setcountry(e.target.value)} className="form-control">
                                    <option value="">Select Country...</option>
                                    <option value="Afghanistan">Afghanistan</option>
                                    <option value="Åland Islands">Åland Islands</option>
                                    <option value="Albania">Albania</option>
                                    <option value="Algeria">Algeria</option>
                                    <option value="American Samoa">American Samoa</option>
                                    <option value="Andorra">Andorra</option>
                                    <option value="Angola">Angola</option>
                                    <option value="Anguilla">Anguilla</option>
                                    <option value="Antarctica">Antarctica</option>
                                    <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                    <option value="Argentina">Argentina</option>
                                    <option value="Armenia">Armenia</option>
                                    <option value="Aruba">Aruba</option>
                                    <option value="Australia">Australia</option>
                                    <option value="Austria">Austria</option>
                                    <option value="Azerbaijan">Azerbaijan</option>
                                    <option value="Bahamas">Bahamas</option>
                                    <option value="Bahrain">Bahrain</option>
                                    <option value="Bangladesh">Bangladesh</option>
                                    <option value="Barbados">Barbados</option>
                                    <option value="Belarus">Belarus</option>
                                    <option value="Belgium">Belgium</option>
                                    <option value="Belize">Belize</option>
                                    <option value="Benin">Benin</option>
                                    <option value="Bermuda">Bermuda</option>
                                    <option value="Bhutan">Bhutan</option>
                                    <option value="Bolivia">Bolivia</option>
                                    <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                    <option value="Botswana">Botswana</option>
                                    <option value="Bouvet Island">Bouvet Island</option>
                                    <option value="Brazil">Brazil</option>
                                    <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                    <option value="Brunei Darussalam">Brunei Darussalam</option>
                                    <option value="Bulgaria">Bulgaria</option>
                                    <option value="Burkina Faso">Burkina Faso</option>
                                    <option value="Burundi">Burundi</option>
                                    <option value="Cambodia">Cambodia</option>
                                    <option value="Cameroon">Cameroon</option>
                                    <option value="Canada">Canada</option>
                                    <option value="Cape Verde">Cape Verde</option>
                                    <option value="Cayman Islands">Cayman Islands</option>
                                    <option value="Central African Republic">Central African Republic</option>
                                    <option value="Chad">Chad</option>
                                    <option value="Chile">Chile</option>
                                    <option value="China">China</option>
                                    <option value="Christmas Island">Christmas Island</option>
                                    <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                    <option value="Colombia">Colombia</option>
                                    <option value="Comoros">Comoros</option>
                                    <option value="Congo">Congo</option>
                                    <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                                    <option value="Cook Islands">Cook Islands</option>
                                    <option value="Costa Rica">Costa Rica</option>
                                    <option value="Cote D'ivoire">Cote D'ivoire</option>
                                    <option value="Croatia">Croatia</option>
                                    <option value="Cuba">Cuba</option>
                                    <option value="Cyprus">Cyprus</option>
                                    <option value="Czech Republic">Czech Republic</option>
                                    <option value="Denmark">Denmark</option>
                                    <option value="Djibouti">Djibouti</option>
                                    <option value="Dominica">Dominica</option>
                                    <option value="Dominican Republic">Dominican Republic</option>
                                    <option value="Ecuador">Ecuador</option>
                                    <option value="Egypt">Egypt</option>
                                    <option value="El Salvador">El Salvador</option>
                                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                                    <option value="Eritrea">Eritrea</option>
                                    <option value="Estonia">Estonia</option>
                                    <option value="Ethiopia">Ethiopia</option>
                                    <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                                    <option value="Faroe Islands">Faroe Islands</option>
                                    <option value="Fiji">Fiji</option>
                                    <option value="Finland">Finland</option>
                                    <option value="France">France</option>
                                    <option value="French Guiana">French Guiana</option>
                                    <option value="French Polynesia">French Polynesia</option>
                                    <option value="French Southern Territories">French Southern Territories</option>
                                    <option value="Gabon">Gabon</option>
                                    <option value="Gambia">Gambia</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Ghana">Ghana</option>
                                    <option value="Gibraltar">Gibraltar</option>
                                    <option value="Greece">Greece</option>
                                    <option value="Greenland">Greenland</option>
                                    <option value="Grenada">Grenada</option>
                                    <option value="Guadeloupe">Guadeloupe</option>
                                    <option value="Guam">Guam</option>
                                    <option value="Guatemala">Guatemala</option>
                                    <option value="Guernsey">Guernsey</option>
                                    <option value="Guinea">Guinea</option>
                                    <option value="Guinea-bissau">Guinea-bissau</option>
                                    <option value="Guyana">Guyana</option>
                                    <option value="Haiti">Haiti</option>
                                    <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                                    <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                                    <option value="Honduras">Honduras</option>
                                    <option value="Hong Kong">Hong Kong</option>
                                    <option value="Hungary">Hungary</option>
                                    <option value="Iceland">Iceland</option>
                                    <option value="India">India</option>
                                    <option value="Indonesia">Indonesia</option>
                                    <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                                    <option value="Iraq">Iraq</option>
                                    <option value="Ireland">Ireland</option>
                                    <option value="Isle of Man">Isle of Man</option>
                                    <option value="Israel">Israel</option>
                                    <option value="Italy">Italy</option>
                                    <option value="Jamaica">Jamaica</option>
                                    <option value="Japan">Japan</option>
                                    <option value="Jersey">Jersey</option>
                                    <option value="Jordan">Jordan</option>
                                    <option value="Kazakhstan">Kazakhstan</option>
                                    <option value="Kenya">Kenya</option>
                                    <option value="Kiribati">Kiribati</option>
                                    <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                                    <option value="Korea, Republic of">Korea, Republic of</option>
                                    <option value="Kuwait">Kuwait</option>
                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                    <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                                    <option value="Latvia">Latvia</option>
                                    <option value="Lebanon">Lebanon</option>
                                    <option value="Lesotho">Lesotho</option>
                                    <option value="Liberia">Liberia</option>
                                    <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                    <option value="Liechtenstein">Liechtenstein</option>
                                    <option value="Lithuania">Lithuania</option>
                                    <option value="Luxembourg">Luxembourg</option>
                                    <option value="Macao">Macao</option>
                                    <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                                    <option value="Madagascar">Madagascar</option>
                                    <option value="Malawi">Malawi</option>
                                    <option value="Malaysia">Malaysia</option>
                                    <option value="Maldives">Maldives</option>
                                    <option value="Mali">Mali</option>
                                    <option value="Malta">Malta</option>
                                    <option value="Marshall Islands">Marshall Islands</option>
                                    <option value="Martinique">Martinique</option>
                                    <option value="Mauritania">Mauritania</option>
                                    <option value="Mauritius">Mauritius</option>
                                    <option value="Mayotte">Mayotte</option>
                                    <option value="Mexico">Mexico</option>
                                    <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                                    <option value="Moldova, Republic of">Moldova, Republic of</option>
                                    <option value="Monaco">Monaco</option>
                                    <option value="Mongolia">Mongolia</option>
                                    <option value="Montenegro">Montenegro</option>
                                    <option value="Montserrat">Montserrat</option>
                                    <option value="Morocco">Morocco</option>
                                    <option value="Mozambique">Mozambique</option>
                                    <option value="Myanmar">Myanmar</option>
                                    <option value="Namibia">Namibia</option>
                                    <option value="Nauru">Nauru</option>
                                    <option value="Nepal">Nepal</option>
                                    <option value="Netherlands">Netherlands</option>
                                    <option value="Netherlands Antilles">Netherlands Antilles</option>
                                    <option value="New Caledonia">New Caledonia</option>
                                    <option value="New Zealand">New Zealand</option>
                                    <option value="Nicaragua">Nicaragua</option>
                                    <option value="Niger">Niger</option>
                                    <option value="Nigeria">Nigeria</option>
                                    <option value="Niue">Niue</option>
                                    <option value="Norfolk Island">Norfolk Island</option>
                                    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                    <option value="Norway">Norway</option>
                                    <option value="Oman">Oman</option>
                                    <option value="Pakistan">Pakistan</option>
                                    <option value="Palau">Palau</option>
                                    <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                                    <option value="Panama">Panama</option>
                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                    <option value="Paraguay">Paraguay</option>
                                    <option value="Peru">Peru</option>
                                    <option value="Philippines">Philippines</option>
                                    <option value="Pitcairn">Pitcairn</option>
                                    <option value="Poland">Poland</option>
                                    <option value="Portugal">Portugal</option>
                                    <option value="Puerto Rico">Puerto Rico</option>
                                    <option value="Qatar">Qatar</option>
                                    <option value="Reunion">Reunion</option>
                                    <option value="Romania">Romania</option>
                                    <option value="Russian Federation">Russian Federation</option>
                                    <option value="Rwanda">Rwanda</option>
                                    <option value="Saint Helena">Saint Helena</option>
                                    <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                    <option value="Saint Lucia">Saint Lucia</option>
                                    <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                    <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                                    <option value="Samoa">Samoa</option>
                                    <option value="San Marino">San Marino</option>
                                    <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                    <option value="Senegal">Senegal</option>
                                    <option value="Serbia">Serbia</option>
                                    <option value="Seychelles">Seychelles</option>
                                    <option value="Sierra Leone">Sierra Leone</option>
                                    <option value="Singapore">Singapore</option>
                                    <option value="Slovakia">Slovakia</option>
                                    <option value="Slovenia">Slovenia</option>
                                    <option value="Solomon Islands">Solomon Islands</option>
                                    <option value="Somalia">Somalia</option>
                                    <option value="South Africa">South Africa</option>
                                    <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                                    <option value="Spain">Spain</option>
                                    <option value="Sri Lanka">Sri Lanka</option>
                                    <option value="Sudan">Sudan</option>
                                    <option value="Suriname">Suriname</option>
                                    <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                    <option value="Swaziland">Swaziland</option>
                                    <option value="Sweden">Sweden</option>
                                    <option value="Switzerland">Switzerland</option>
                                    <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                    <option value="Taiwan">Taiwan</option>
                                    <option value="Tajikistan">Tajikistan</option>
                                    <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                                    <option value="Thailand">Thailand</option>
                                    <option value="Timor-leste">Timor-leste</option>
                                    <option value="Togo">Togo</option>
                                    <option value="Tokelau">Tokelau</option>
                                    <option value="Tonga">Tonga</option>
                                    <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                    <option value="Tunisia">Tunisia</option>
                                    <option value="Turkey">Turkey</option>
                                    <option value="Turkmenistan">Turkmenistan</option>
                                    <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                    <option value="Tuvalu">Tuvalu</option>
                                    <option value="Uganda">Uganda</option>
                                    <option value="Ukraine">Ukraine</option>
                                    <option value="United Arab Emirates">United Arab Emirates</option>
                                    <option value="United Kingdom">United Kingdom</option>
                                    <option value="United States">United States</option>
                                    <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                    <option value="Uruguay">Uruguay</option>
                                    <option value="Uzbekistan">Uzbekistan</option>
                                    <option value="Vanuatu">Vanuatu</option>
                                    <option value="Venezuela">Venezuela</option>
                                    <option value="Viet Nam">Viet Nam</option>
                                    <option value="Virgin Islands, British">Virgin Islands, British</option>
                                    <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                                    <option value="Wallis and Futuna">Wallis and Futuna</option>
                                    <option value="Western Sahara">Western Sahara</option>
                                    <option value="Yemen">Yemen</option>
                                    <option value="Zambia">Zambia</option>
                                    <option value="Zimbabwe">Zimbabwe</option>
                                </select>
                                <div>
                                    {Object.keys(countryErr).map((key) => {
                                        return <div style={{ color: "red" }}>{countryErr[key]}</div>
                                    })}
                                </div>
                            </div>

                        </div>

                        <div className="form-row ">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputAR">Select Currency</label>

                                <select value={symbolText} onChange={(e)=>SetsymbolText(e.target.value)} className="form-control" id="currency" name="currency">
                                    <option value=""> Select Currency...</option>
                                    <option value="AFN - Afghan Afghani - ؋">AFN - Afghan Afghani - ؋</option>
                                    <option value="ALL - Albanian Lek - Lek">ALL - Albanian Lek - Lek</option>
                                    <option value="DZD - Algerian Dinar - دج">DZD - Algerian Dinar - دج</option>
                                    <option value="AOA - Angolan Kwanza - Kz">AOA - Angolan Kwanza - Kz</option>
                                    <option value="ARS - Argentine Peso - $">ARS - Argentine Peso - $</option>
                                    <option value="AMD - Armenian Dram - ֏">AMD - Armenian Dram - ֏</option>
                                    <option value="AWG - Aruban Florin - ƒ">AWG - Aruban Florin - ƒ</option>
                                    <option value="AUD - Australian Dollar - $">AUD - Australian Dollar - $</option>
                                    <option value="AZN - Azerbaijani Manat - m">AZN - Azerbaijani Manat - m</option>
                                    <option value="BSD - Bahamian Dollar - B$">BSD - Bahamian Dollar - B$</option>
                                    <option value=" Bahraini Dinar - .د.ب">BHD - Bahraini Dinar - .د.ب</option>
                                    <option value="BDT - Bangladeshi Taka - ৳">BDT - Bangladeshi Taka - ৳</option>
                                    <option value="BBD - Barbadian Dollar - Bds$">BBD - Barbadian Dollar - Bds$</option>
                                    <option value="BYR - Belarusian Ruble - Br">BYR - Belarusian Ruble - Br</option>
                                    <option value="BEF - Belgian Franc - fr">BEF - Belgian Franc - fr</option>
                                    <option value="BZD - Belize Dollar - $">BZD - Belize Dollar - $</option>
                                    <option value="BMD - Bermudan Dollar - $">BMD - Bermudan Dollar - $</option>
                                    <option value="BTN - Bhutanese Ngultrum - Nu">BTN - Bhutanese Ngultrum - Nu.</option>
                                    <option value="BTC - Bitcoin - ฿">BTC - Bitcoin - ฿</option>
                                    <option value="BOB - Bolivian Boliviano - Bs.">BOB - Bolivian Boliviano - Bs.</option>
                                    <option value="BAM - Bosnia-Herzegovina Convertible Mark - KM">BAM - Bosnia-Herzegovina Convertible Mark - KM</option>
                                    <option value="BWP - Botswanan Pula - P">BWP - Botswanan Pula - P</option>
                                    <option value="BRL - Brazilian Real - R$">BRL - Brazilian Real - R$</option>
                                    <option value="GBP - British Pound Sterling - £">GBP - British Pound Sterling - £</option>
                                    <option value="BND - Brunei Dollar - B$">BND - Brunei Dollar - B$</option>
                                    <option value="BGN - Bulgarian Lev - Лв.">BGN - Bulgarian Lev - Лв.</option>
                                    <option value="BIF - Burundian Franc - FBu">BIF - Burundian Franc - FBu</option>
                                    <option value="KHR - Cambodian Riel - KHR">KHR - Cambodian Riel - KHR</option>
                                    <option value="CAD - Canadian Dollar - $">CAD - Canadian Dollar - $</option>
                                    <option value="CVE - Cape Verdean Escudo - $">CVE - Cape Verdean Escudo - $</option>
                                    <option value="KYD - Cayman Islands Dollar - $">KYD - Cayman Islands Dollar - $</option>
                                    <option value="XOF - CFA Franc BCEAO - CFA">XOF - CFA Franc BCEAO - CFA</option>
                                    <option value="XAF - CFA Franc BEAC - FCFA">XAF - CFA Franc BEAC - FCFA</option>
                                    <option value="XPF - CFP Franc - ₣">XPF - CFP Franc - ₣</option>
                                    <option value="CLP - Chilean Peso - $">CLP - Chilean Peso - $</option>
                                    <option value="CNY - Chinese Yuan - ¥">CNY - Chinese Yuan - ¥</option>
                                    <option value="COP - Colombian Peso - $">COP - Colombian Peso - $</option>
                                    <option value="KMF - Comorian Franc - CF">KMF - Comorian Franc - CF</option>
                                    <option value="CDF - Congolese Franc - FC">CDF - Congolese Franc - FC</option>
                                    <option value="CRC - Costa Rican ColÃ³n - ₡">CRC - Costa Rican ColÃ³n - ₡</option>
                                    <option value="HRK - Croatian Kuna - kn">HRK - Croatian Kuna - kn</option>
                                    <option value="CUC - Cuban Convertible Peso - $, CUC">CUC - Cuban Convertible Peso - $, CUC</option>
                                    <option value="CZK - Czech Republic Koruna - Kč">CZK - Czech Republic Koruna - Kč</option>
                                    <option value="DKK - Danish Krone - Kr.">DKK - Danish Krone - Kr.</option>
                                    <option value="DJF - Djiboutian Franc - Fdj">DJF - Djiboutian Franc - Fdj</option>
                                    <option value="DOP - Dominican Peso - $">DOP - Dominican Peso - $</option>
                                    <option value="XCD - East Caribbean Dollar - $">XCD - East Caribbean Dollar - $</option>
                                    <option value="EGP - Egyptian Pound - ج.م">EGP - Egyptian Pound - ج.م</option>
                                    <option value="ERN - Eritrean Nakfa - Nfk">ERN - Eritrean Nakfa - Nfk</option>
                                    <option value="EEK - Estonian Kroon - kr">EEK - Estonian Kroon - kr</option>
                                    <option value="ETB - Ethiopian Birr - Nkf">ETB - Ethiopian Birr - Nkf</option>
                                    <option value="EUR - Euro - €">EUR - Euro - €</option>
                                    <option value="FKP - Falkland Islands Pound - £">FKP - Falkland Islands Pound - £</option>
                                    <option value="FJD - Fijian Dollar - FJ$">FJD - Fijian Dollar - FJ$</option>
                                    <option value="GMD - Gambian Dalasi - D">GMD - Gambian Dalasi - D</option>
                                    <option value="GEL - Georgian Lari - ლ">GEL - Georgian Lari - ლ</option>
                                    <option value="DEM - German Mark - DM">DEM - German Mark - DM</option>
                                    <option value="GHS - Ghanaian Cedi - GH₵">GHS - Ghanaian Cedi - GH₵</option>
                                    <option value="GIP - Gibraltar Pound - £">GIP - Gibraltar Pound - £</option>
                                    <option value="GRD - Greek Drachma - ₯, Δρχ, Δρ">GRD - Greek Drachma - ₯, Δρχ, Δρ</option>
                                    <option value="GTQ - Guatemalan Quetzal - Q">GTQ - Guatemalan Quetzal - Q</option>
                                    <option value="GNF - Guinean Franc - FG">GNF - Guinean Franc - FG</option>
                                    <option value="GYD - Guyanaese Dollar - $">GYD - Guyanaese Dollar - $</option>
                                    <option value="HTG - Haitian Gourde - G">HTG - Haitian Gourde - G</option>
                                    <option value="HNL - Honduran Lempira - L">HNL - Honduran Lempira - L</option>
                                    <option value="HKD - Hong Kong Dollar - $">HKD - Hong Kong Dollar - $</option>
                                    <option value="HUF - Hungarian Forint - Ft">HUF - Hungarian Forint - Ft</option>
                                    <option value="ISK - Icelandic KrÃ³na - kr">ISK - Icelandic KrÃ³na - kr</option>
                                    <option value="INR - Indian Rupee - ₹">INR - Indian Rupee - ₹</option>
                                    <option value="IDR - Indonesian Rupiah - Rp">IDR - Indonesian Rupiah - Rp</option>
                                    <option value="IRR - Iranian Rial - ﷼">IRR - Iranian Rial - ﷼</option>
                                    <option value="IQD - Iraqi Dinar - د.ع">IQD - Iraqi Dinar - د.ع</option>
                                    <option value="ILS - Israeli New Sheqel - ₪">ILS - Israeli New Sheqel - ₪</option>
                                    <option value="ITL - Italian Lira - L,£">ITL - Italian Lira - L,£</option>
                                    <option value="JMD - Jamaican Dollar - J$">JMD - Jamaican Dollar - J$</option>
                                    <option value="JPY - Japanese Yen - ¥">JPY - Japanese Yen - ¥</option>
                                    <option value="JOD - Jordanian Dinar - ا.د">JOD - Jordanian Dinar - ا.د</option>
                                    <option value="KZT - Kazakhstani Tenge - лв">KZT - Kazakhstani Tenge - лв</option>
                                    <option value="KES - Kenyan Shilling - KSh">KES - Kenyan Shilling - KSh</option>
                                    <option value="KWD - Kuwaiti Dinar - ك.د">KWD - Kuwaiti Dinar - ك.د</option>
                                    <option value="KGS - Kyrgystani Som - лв">KGS - Kyrgystani Som - лв</option>
                                    <option value="LAK - Laotian Kip - ₭">LAK - Laotian Kip - ₭</option>
                                    <option value="LVL - Latvian Lats - Ls">LVL - Latvian Lats - Ls</option>
                                    <option value="LBP - Lebanese Pound - £">LBP - Lebanese Pound - £</option>
                                    <option value="LSL - Lesotho Loti - L">LSL - Lesotho Loti - L</option>
                                    <option value="LRD - Liberian Dollar - $">LRD - Liberian Dollar - $</option>
                                    <option value="LYD - Libyan Dinar - د.ل">LYD - Libyan Dinar - د.ل</option>
                                    <option value="LTL - Lithuanian Litas - Lt">LTL - Lithuanian Litas - Lt</option>
                                    <option value="MOP - Macanese Pataca - $">MOP - Macanese Pataca - $</option>
                                    <option value="MKD - Macedonian Denar - ден">MKD - Macedonian Denar - ден</option>
                                    <option value="MGA - Malagasy Ariary - Ar">MGA - Malagasy Ariary - Ar</option>
                                    <option value="MWK - Malawian Kwacha - MK">MWK - Malawian Kwacha - MK</option>
                                    <option value="MYR - Malaysian Ringgit - RM">MYR - Malaysian Ringgit - RM</option>
                                    <option value="MVR - Maldivian Rufiyaa - Rf">MVR - Maldivian Rufiyaa - Rf</option>
                                    <option value="MRO - Mauritanian Ouguiya - MRU">MRO - Mauritanian Ouguiya - MRU</option>
                                    <option value="MUR - Mauritian Rupee - ₨">MUR - Mauritian Rupee - ₨</option>
                                    <option value="MXN - Mexican Peso - $">MXN - Mexican Peso - $</option>
                                    <option value="MDL - Moldovan Leu - L">MDL - Moldovan Leu - L</option>
                                    <option value="MNT - Mongolian Tugrik - ₮">MNT - Mongolian Tugrik - ₮</option>
                                    <option value="MAD - Moroccan Dirham - MAD">MAD - Moroccan Dirham - MAD</option>
                                    <option value="MZM - Mozambican Metical - MT">MZM - Mozambican Metical - MT</option>
                                    <option value="MMK - Myanmar Kyat - K">MMK - Myanmar Kyat - K</option>
                                    <option value="NAD - Namibian Dollar - $">NAD - Namibian Dollar - $</option>
                                    <option value="NPR - Nepalese Rupee - ₨">NPR - Nepalese Rupee - ₨</option>
                                    <option value="ANG - Netherlands Antillean Guilder - ƒ">ANG - Netherlands Antillean Guilder - ƒ</option>
                                    <option value="TWD - New Taiwan Dollar - $">TWD - New Taiwan Dollar - $</option>
                                    <option value="NZD - New Zealand Dollar - $">NZD - New Zealand Dollar - $</option>
                                    <option value="NIO - Nicaraguan CÃ³rdoba - C$">NIO - Nicaraguan CÃ³rdoba - C$</option>
                                    <option value="NGN - Nigerian Naira - ₦">NGN - Nigerian Naira - ₦</option>
                                    <option value="KPW - North Korean Won - ₩">KPW - North Korean Won - ₩</option>
                                    <option value="NOK - Norwegian Krone - kr">NOK - Norwegian Krone - kr</option>
                                    <option value="OMR - Omani Rial - .ع.ر">OMR - Omani Rial - .ع.ر</option>
                                    <option value="PKR - Pakistani Rupee - ₨">PKR - Pakistani Rupee - ₨</option>
                                    <option value="PAB - Panamanian Balboa - B/.">PAB - Panamanian Balboa - B/.</option>
                                    <option value="PGK - Papua New Guinean Kina - K">PGK - Papua New Guinean Kina - K</option>
                                    <option value="PYG - Paraguayan Guarani - ₲">PYG - Paraguayan Guarani - ₲</option>
                                    <option value="PEN - Peruvian Nuevo Sol - S/.">PEN - Peruvian Nuevo Sol - S/.</option>
                                    <option value="PHP - Philippine Peso - ₱">PHP - Philippine Peso - ₱</option>
                                    <option value="PLN - Polish Zloty - zł">PLN - Polish Zloty - zł</option>
                                    <option value="QAR - Qatari Rial - ق.ر">QAR - Qatari Rial - ق.ر</option>
                                    <option value="RON - Romanian Leu - lei">RON - Romanian Leu - lei</option>
                                    <option value="RUB - Russian Ruble - ₽">RUB - Russian Ruble - ₽</option>
                                    <option value="RWF - Rwandan Franc - FRw">RWF - Rwandan Franc - FRw</option>
                                    <option value="SVC - Salvadoran ColÃ³n - ₡">SVC - Salvadoran ColÃ³n - ₡</option>
                                    <option value="WST - Samoan Tala - SAT">WST - Samoan Tala - SAT</option>
                                    <option value="SAR - Saudi Riyal - ﷼">SAR - Saudi Riyal - ﷼</option>
                                    <option value="RSD - Serbian Dinar - din">RSD - Serbian Dinar - din</option>
                                    <option value="SCR - Seychellois Rupee - SRe">SCR - Seychellois Rupee - SRe</option>
                                    <option value="SLL - Sierra Leonean Leone - Le">SLL - Sierra Leonean Leone - Le</option>
                                    <option value="SGD - Singapore Dollar - $">SGD - Singapore Dollar - $</option>
                                    <option value="SKK">SKK - Slovak Koruna - Sk</option>
                                    <option value="SBD - Solomon Islands Dollar - Si$">SBD - Solomon Islands Dollar - Si$</option>
                                    <option value="SOS - Somali Shilling - Sh.so.">SOS - Somali Shilling - Sh.so.</option>
                                    <option value="ZAR - South African Rand - R">ZAR - South African Rand - R</option>
                                    <option value="KRW - South Korean Won - ₩">KRW - South Korean Won - ₩</option>
                                    <option value="XDR - Special Drawing Rights - SDR">XDR - Special Drawing Rights - SDR</option>
                                    <option value="LKR - Sri Lankan Rupee - Rs">LKR - Sri Lankan Rupee - Rs</option>
                                    <option value="SHP - St. Helena Pound - £">SHP - St. Helena Pound - £</option>
                                    <option value="SDG - Sudanese Pound - .س.ج">SDG - Sudanese Pound - .س.ج</option>
                                    <option value="SRD - Surinamese Dollar - $">SRD - Surinamese Dollar - $</option>
                                    <option value="SZL - Swazi Lilangeni - E">SZL - Swazi Lilangeni - E</option>
                                    <option value="SEK - Swedish Krona - kr">SEK - Swedish Krona - kr</option>
                                    <option value="CHF - Swiss Franc - CHf">CHF - Swiss Franc - CHf</option>
                                    <option value="SYP - Syrian Pound - LS">SYP - Syrian Pound - LS</option>
                                    <option value="STD - São Tomé and Príncipe Dobra - Db">STD - São Tomé and Príncipe Dobra - Db</option>
                                    <option value="TJS - Tajikistani Somoni - SM">TJS - Tajikistani Somoni - SM</option>
                                    <option value="TZS - Tanzanian Shilling - TSh">TZS - Tanzanian Shilling - TSh</option>
                                    <option value="THB - Thai Baht - ฿">THB - Thai Baht - ฿</option>
                                    <option value="TOP - Tongan pa'anga - $">TOP - Tongan pa'anga - $</option>
                                    <option value="TTD - Trinidad & Tobago Dollar - $">TTD - Trinidad & Tobago Dollar - $</option>
                                    <option value="TND - Tunisian Dinar - ت.د">TND - Tunisian Dinar - ت.د</option>
                                    <option value="TRY - Turkish Lira - ₺">TRY - Turkish Lira - ₺</option>
                                    <option value="TMT - Turkmenistani Manat - T">TMT - Turkmenistani Manat - T</option>
                                    <option value="UGX - Ugandan Shilling - USh">UGX - Ugandan Shilling - USh</option>
                                    <option value="UAH - Ukrainian Hryvnia - ₴">UAH - Ukrainian Hryvnia - ₴</option>
                                    <option value="AED - United Arab Emirates Dirham - إ.د">AED - United Arab Emirates Dirham - إ.د</option>
                                    <option value="UYU - Uruguayan Peso - $">UYU - Uruguayan Peso - $</option>
                                    <option value="USD - US Dollar - $">USD - US Dollar - $</option>
                                    <option value="UZS - Uzbekistan Som - лв">UZS - Uzbekistan Som - лв</option>
                                    <option value="VUV - Vanuatu Vatu - VT">VUV - Vanuatu Vatu - VT</option>
                                    <option value="VEF - Venezuelan BolÃ­var - Bs">VEF - Venezuelan BolÃ­var - Bs</option>
                                    <option value="VND - Vietnamese Dong - ₫">VND - Vietnamese Dong - ₫</option>
                                    <option value="YER - Yemeni Rial - ﷼">YER - Yemeni Rial - ﷼</option>
                                    <option value="ZMK - Zambian Kwacha - ZK">ZMK - Zambian Kwacha - ZK</option>
                                </select>
                                <div>
                                    {Object.keys(symbolTextErr).map((key) => {
                                        return <div style={{ color: "red" }}>{symbolTextErr[key]}</div>
                                    })}
                                </div>
                            </div>

                        </div>



                    </form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={()=>AddData()}  >
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>



            {/* Modal 2  EDIT */}


            <Modal
                size="md"
                aria-labelledby="example-modal-sizes-title-lg"
                show={show2}
                onHide={handleClose2}>
                <Modal.Header   >
                    <Modal.Title>Edit Data </Modal.Title>
                    <i onClick={handleClose2} style={{ fontSize: "24px" }} className=" fa-10X fas fa-window-close"></i>
                </Modal.Header>
                <Modal.Body>

                    <form>

                        <div className="form-row text-center " style={{ display: "block" }}>


                            <div className="row">
                                <div className="col-md-12 ">
                                    <center>
                                        <div className="upload_photo_main">
                                            <input
                                                onChange={e => handleInputFileChange(e,setprofileimage)}
                                                accept="image/*"
                                                className={classes.input}
                                                id="icon-button-file"
                                                type="file"

                                            />
                                            <label htmlFor="icon-button-file">
                                                <IconButton
                                                    color="#414141"
                                                    aria-label="upload picture"
                                                    component="span"
                                                    className="upload_photo_main"
                                                >
                                                    <img  src={Selectedprofile ? Selectedprofile : `${(profileimage)}` !== "https://backend.fridaymart.mlnull" ? `${(profileimage)}` : 'assets/img/cam.jpg'}

                                                        style={{ minWidth: "120px", maxWidth: "120px", borderRadius: "20px" }} className="upload_photo_main" />
                                                    <div>
                            {Object.keys(profileimageErr).map((key) => {
                                return <div style={{ color: "red" }}>{profileimageErr[key]}</div>
                            })}
                        </div>
                                                </IconButton>
                                            </label>
                                        </div>
                                    </center>
                                </div>
                            </div>





                        </div>
                        <div className="form-row mt-4">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputAR">Select Country</label>

                                <select value={ucountry} onChange={(e)=>Setucountry(e.target.value)} className="form-control">
                                    <option value="">select country...</option>
                                    <option value="Afghanistan">Afghanistan</option>
                                    <option value="Åland Islands">Åland Islands</option>
                                    <option value="Albania">Albania</option>
                                    <option value="Algeria">Algeria</option>
                                    <option value="American Samoa">American Samoa</option>
                                    <option value="Andorra">Andorra</option>
                                    <option value="Angola">Angola</option>
                                    <option value="Anguilla">Anguilla</option>
                                    <option value="Antarctica">Antarctica</option>
                                    <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                    <option value="Argentina">Argentina</option>
                                    <option value="Armenia">Armenia</option>
                                    <option value="Aruba">Aruba</option>
                                    <option value="Australia">Australia</option>
                                    <option value="Austria">Austria</option>
                                    <option value="Azerbaijan">Azerbaijan</option>
                                    <option value="Bahamas">Bahamas</option>
                                    <option value="Bahrain">Bahrain</option>
                                    <option value="Bangladesh">Bangladesh</option>
                                    <option value="Barbados">Barbados</option>
                                    <option value="Belarus">Belarus</option>
                                    <option value="Belgium">Belgium</option>
                                    <option value="Belize">Belize</option>
                                    <option value="Benin">Benin</option>
                                    <option value="Bermuda">Bermuda</option>
                                    <option value="Bhutan">Bhutan</option>
                                    <option value="Bolivia">Bolivia</option>
                                    <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                    <option value="Botswana">Botswana</option>
                                    <option value="Bouvet Island">Bouvet Island</option>
                                    <option value="Brazil">Brazil</option>
                                    <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                    <option value="Brunei Darussalam">Brunei Darussalam</option>
                                    <option value="Bulgaria">Bulgaria</option>
                                    <option value="Burkina Faso">Burkina Faso</option>
                                    <option value="Burundi">Burundi</option>
                                    <option value="Cambodia">Cambodia</option>
                                    <option value="Cameroon">Cameroon</option>
                                    <option value="Canada">Canada</option>
                                    <option value="Cape Verde">Cape Verde</option>
                                    <option value="Cayman Islands">Cayman Islands</option>
                                    <option value="Central African Republic">Central African Republic</option>
                                    <option value="Chad">Chad</option>
                                    <option value="Chile">Chile</option>
                                    <option value="China">China</option>
                                    <option value="Christmas Island">Christmas Island</option>
                                    <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                    <option value="Colombia">Colombia</option>
                                    <option value="Comoros">Comoros</option>
                                    <option value="Congo">Congo</option>
                                    <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                                    <option value="Cook Islands">Cook Islands</option>
                                    <option value="Costa Rica">Costa Rica</option>
                                    <option value="Cote D'ivoire">Cote D'ivoire</option>
                                    <option value="Croatia">Croatia</option>
                                    <option value="Cuba">Cuba</option>
                                    <option value="Cyprus">Cyprus</option>
                                    <option value="Czech Republic">Czech Republic</option>
                                    <option value="Denmark">Denmark</option>
                                    <option value="Djibouti">Djibouti</option>
                                    <option value="Dominica">Dominica</option>
                                    <option value="Dominican Republic">Dominican Republic</option>
                                    <option value="Ecuador">Ecuador</option>
                                    <option value="Egypt">Egypt</option>
                                    <option value="El Salvador">El Salvador</option>
                                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                                    <option value="Eritrea">Eritrea</option>
                                    <option value="Estonia">Estonia</option>
                                    <option value="Ethiopia">Ethiopia</option>
                                    <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                                    <option value="Faroe Islands">Faroe Islands</option>
                                    <option value="Fiji">Fiji</option>
                                    <option value="Finland">Finland</option>
                                    <option value="France">France</option>
                                    <option value="French Guiana">French Guiana</option>
                                    <option value="French Polynesia">French Polynesia</option>
                                    <option value="French Southern Territories">French Southern Territories</option>
                                    <option value="Gabon">Gabon</option>
                                    <option value="Gambia">Gambia</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Ghana">Ghana</option>
                                    <option value="Gibraltar">Gibraltar</option>
                                    <option value="Greece">Greece</option>
                                    <option value="Greenland">Greenland</option>
                                    <option value="Grenada">Grenada</option>
                                    <option value="Guadeloupe">Guadeloupe</option>
                                    <option value="Guam">Guam</option>
                                    <option value="Guatemala">Guatemala</option>
                                    <option value="Guernsey">Guernsey</option>
                                    <option value="Guinea">Guinea</option>
                                    <option value="Guinea-bissau">Guinea-bissau</option>
                                    <option value="Guyana">Guyana</option>
                                    <option value="Haiti">Haiti</option>
                                    <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                                    <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                                    <option value="Honduras">Honduras</option>
                                    <option value="Hong Kong">Hong Kong</option>
                                    <option value="Hungary">Hungary</option>
                                    <option value="Iceland">Iceland</option>
                                    <option value="India">India</option>
                                    <option value="Indonesia">Indonesia</option>
                                    <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                                    <option value="Iraq">Iraq</option>
                                    <option value="Ireland">Ireland</option>
                                    <option value="Isle of Man">Isle of Man</option>
                                    <option value="Israel">Israel</option>
                                    <option value="Italy">Italy</option>
                                    <option value="Jamaica">Jamaica</option>
                                    <option value="Japan">Japan</option>
                                    <option value="Jersey">Jersey</option>
                                    <option value="Jordan">Jordan</option>
                                    <option value="Kazakhstan">Kazakhstan</option>
                                    <option value="Kenya">Kenya</option>
                                    <option value="Kiribati">Kiribati</option>
                                    <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                                    <option value="Korea, Republic of">Korea, Republic of</option>
                                    <option value="Kuwait">Kuwait</option>
                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                    <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                                    <option value="Latvia">Latvia</option>
                                    <option value="Lebanon">Lebanon</option>
                                    <option value="Lesotho">Lesotho</option>
                                    <option value="Liberia">Liberia</option>
                                    <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                    <option value="Liechtenstein">Liechtenstein</option>
                                    <option value="Lithuania">Lithuania</option>
                                    <option value="Luxembourg">Luxembourg</option>
                                    <option value="Macao">Macao</option>
                                    <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                                    <option value="Madagascar">Madagascar</option>
                                    <option value="Malawi">Malawi</option>
                                    <option value="Malaysia">Malaysia</option>
                                    <option value="Maldives">Maldives</option>
                                    <option value="Mali">Mali</option>
                                    <option value="Malta">Malta</option>
                                    <option value="Marshall Islands">Marshall Islands</option>
                                    <option value="Martinique">Martinique</option>
                                    <option value="Mauritania">Mauritania</option>
                                    <option value="Mauritius">Mauritius</option>
                                    <option value="Mayotte">Mayotte</option>
                                    <option value="Mexico">Mexico</option>
                                    <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                                    <option value="Moldova, Republic of">Moldova, Republic of</option>
                                    <option value="Monaco">Monaco</option>
                                    <option value="Mongolia">Mongolia</option>
                                    <option value="Montenegro">Montenegro</option>
                                    <option value="Montserrat">Montserrat</option>
                                    <option value="Morocco">Morocco</option>
                                    <option value="Mozambique">Mozambique</option>
                                    <option value="Myanmar">Myanmar</option>
                                    <option value="Namibia">Namibia</option>
                                    <option value="Nauru">Nauru</option>
                                    <option value="Nepal">Nepal</option>
                                    <option value="Netherlands">Netherlands</option>
                                    <option value="Netherlands Antilles">Netherlands Antilles</option>
                                    <option value="New Caledonia">New Caledonia</option>
                                    <option value="New Zealand">New Zealand</option>
                                    <option value="Nicaragua">Nicaragua</option>
                                    <option value="Niger">Niger</option>
                                    <option value="Nigeria">Nigeria</option>
                                    <option value="Niue">Niue</option>
                                    <option value="Norfolk Island">Norfolk Island</option>
                                    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                    <option value="Norway">Norway</option>
                                    <option value="Oman">Oman</option>
                                    <option value="Pakistan">Pakistan</option>
                                    <option value="Palau">Palau</option>
                                    <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                                    <option value="Panama">Panama</option>
                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                    <option value="Paraguay">Paraguay</option>
                                    <option value="Peru">Peru</option>
                                    <option value="Philippines">Philippines</option>
                                    <option value="Pitcairn">Pitcairn</option>
                                    <option value="Poland">Poland</option>
                                    <option value="Portugal">Portugal</option>
                                    <option value="Puerto Rico">Puerto Rico</option>
                                    <option value="Qatar">Qatar</option>
                                    <option value="Reunion">Reunion</option>
                                    <option value="Romania">Romania</option>
                                    <option value="Russian Federation">Russian Federation</option>
                                    <option value="Rwanda">Rwanda</option>
                                    <option value="Saint Helena">Saint Helena</option>
                                    <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                    <option value="Saint Lucia">Saint Lucia</option>
                                    <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                    <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                                    <option value="Samoa">Samoa</option>
                                    <option value="San Marino">San Marino</option>
                                    <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                    <option value="Senegal">Senegal</option>
                                    <option value="Serbia">Serbia</option>
                                    <option value="Seychelles">Seychelles</option>
                                    <option value="Sierra Leone">Sierra Leone</option>
                                    <option value="Singapore">Singapore</option>
                                    <option value="Slovakia">Slovakia</option>
                                    <option value="Slovenia">Slovenia</option>
                                    <option value="Solomon Islands">Solomon Islands</option>
                                    <option value="Somalia">Somalia</option>
                                    <option value="South Africa">South Africa</option>
                                    <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                                    <option value="Spain">Spain</option>
                                    <option value="Sri Lanka">Sri Lanka</option>
                                    <option value="Sudan">Sudan</option>
                                    <option value="Suriname">Suriname</option>
                                    <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                    <option value="Swaziland">Swaziland</option>
                                    <option value="Sweden">Sweden</option>
                                    <option value="Switzerland">Switzerland</option>
                                    <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                    <option value="Taiwan">Taiwan</option>
                                    <option value="Tajikistan">Tajikistan</option>
                                    <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                                    <option value="Thailand">Thailand</option>
                                    <option value="Timor-leste">Timor-leste</option>
                                    <option value="Togo">Togo</option>
                                    <option value="Tokelau">Tokelau</option>
                                    <option value="Tonga">Tonga</option>
                                    <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                    <option value="Tunisia">Tunisia</option>
                                    <option value="Turkey">Turkey</option>
                                    <option value="Turkmenistan">Turkmenistan</option>
                                    <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                    <option value="Tuvalu">Tuvalu</option>
                                    <option value="Uganda">Uganda</option>
                                    <option value="Ukraine">Ukraine</option>
                                    <option value="United Arab Emirates">United Arab Emirates</option>
                                    <option value="United Kingdom">United Kingdom</option>
                                    <option value="United States">United States</option>
                                    <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                    <option value="Uruguay">Uruguay</option>
                                    <option value="Uzbekistan">Uzbekistan</option>
                                    <option value="Vanuatu">Vanuatu</option>
                                    <option value="Venezuela">Venezuela</option>
                                    <option value="Viet Nam">Viet Nam</option>
                                    <option value="Virgin Islands, British">Virgin Islands, British</option>
                                    <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                                    <option value="Wallis and Futuna">Wallis and Futuna</option>
                                    <option value="Western Sahara">Western Sahara</option>
                                    <option value="Yemen">Yemen</option>
                                    <option value="Zambia">Zambia</option>
                                    <option value="Zimbabwe">Zimbabwe</option>
                                </select>
                                <div>
                                    {Object.keys(ucountryErr).map((key) => {
                                        return <div style={{ color: "red" }}>{ucountryErr[key]}</div>
                                    })}
                                </div>
                            </div>

                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputAR">Select Currency</label>

                                <select value={suymbolText} onChange={(e)=>SetusymbolText(e.target.value)} className="form-control" id="currency" name="currency">
                                    <option value="">Select Currency...</option>
                                    <option value="AFN - Afghan Afghani - ؋">AFN - Afghan Afghani - ؋</option>
                                    <option value="ALL - Albanian Lek - Lek">ALL - Albanian Lek - Lek</option>
                                    <option value="DZD - Algerian Dinar - دج">DZD - Algerian Dinar - دج</option>
                                    <option value="AOA - Angolan Kwanza - Kz">AOA - Angolan Kwanza - Kz</option>
                                    <option value="ARS - Argentine Peso - $">ARS - Argentine Peso - $</option>
                                    <option value="AMD - Armenian Dram - ֏">AMD - Armenian Dram - ֏</option>
                                    <option value="AWG - Aruban Florin - ƒ">AWG - Aruban Florin - ƒ</option>
                                    <option value="AUD - Australian Dollar - $">AUD - Australian Dollar - $</option>
                                    <option value="AZN - Azerbaijani Manat - m">AZN - Azerbaijani Manat - m</option>
                                    <option value="BSD - Bahamian Dollar - B$">BSD - Bahamian Dollar - B$</option>
                                    <option value=" Bahraini Dinar - .د.ب">BHD - Bahraini Dinar - .د.ب</option>
                                    <option value="BDT - Bangladeshi Taka - ৳">BDT - Bangladeshi Taka - ৳</option>
                                    <option value="BBD - Barbadian Dollar - Bds$">BBD - Barbadian Dollar - Bds$</option>
                                    <option value="BYR - Belarusian Ruble - Br">BYR - Belarusian Ruble - Br</option>
                                    <option value="BEF - Belgian Franc - fr">BEF - Belgian Franc - fr</option>
                                    <option value="BZD - Belize Dollar - $">BZD - Belize Dollar - $</option>
                                    <option value="BMD - Bermudan Dollar - $">BMD - Bermudan Dollar - $</option>
                                    <option value="BTN - Bhutanese Ngultrum - Nu">BTN - Bhutanese Ngultrum - Nu.</option>
                                    <option value="BTC - Bitcoin - ฿">BTC - Bitcoin - ฿</option>
                                    <option value="BOB - Bolivian Boliviano - Bs.">BOB - Bolivian Boliviano - Bs.</option>
                                    <option value="BAM - Bosnia-Herzegovina Convertible Mark - KM">BAM - Bosnia-Herzegovina Convertible Mark - KM</option>
                                    <option value="BWP - Botswanan Pula - P">BWP - Botswanan Pula - P</option>
                                    <option value="BRL - Brazilian Real - R$">BRL - Brazilian Real - R$</option>
                                    <option value="GBP - British Pound Sterling - £">GBP - British Pound Sterling - £</option>
                                    <option value="BND - Brunei Dollar - B$">BND - Brunei Dollar - B$</option>
                                    <option value="BGN - Bulgarian Lev - Лв.">BGN - Bulgarian Lev - Лв.</option>
                                    <option value="BIF - Burundian Franc - FBu">BIF - Burundian Franc - FBu</option>
                                    <option value="KHR - Cambodian Riel - KHR">KHR - Cambodian Riel - KHR</option>
                                    <option value="CAD - Canadian Dollar - $">CAD - Canadian Dollar - $</option>
                                    <option value="CVE - Cape Verdean Escudo - $">CVE - Cape Verdean Escudo - $</option>
                                    <option value="KYD - Cayman Islands Dollar - $">KYD - Cayman Islands Dollar - $</option>
                                    <option value="XOF - CFA Franc BCEAO - CFA">XOF - CFA Franc BCEAO - CFA</option>
                                    <option value="XAF - CFA Franc BEAC - FCFA">XAF - CFA Franc BEAC - FCFA</option>
                                    <option value="XPF - CFP Franc - ₣">XPF - CFP Franc - ₣</option>
                                    <option value="CLP - Chilean Peso - $">CLP - Chilean Peso - $</option>
                                    <option value="CNY - Chinese Yuan - ¥">CNY - Chinese Yuan - ¥</option>
                                    <option value="COP - Colombian Peso - $">COP - Colombian Peso - $</option>
                                    <option value="KMF - Comorian Franc - CF">KMF - Comorian Franc - CF</option>
                                    <option value="CDF - Congolese Franc - FC">CDF - Congolese Franc - FC</option>
                                    <option value="CRC - Costa Rican ColÃ³n - ₡">CRC - Costa Rican ColÃ³n - ₡</option>
                                    <option value="HRK - Croatian Kuna - kn">HRK - Croatian Kuna - kn</option>
                                    <option value="CUC - Cuban Convertible Peso - $, CUC">CUC - Cuban Convertible Peso - $, CUC</option>
                                    <option value="CZK - Czech Republic Koruna - Kč">CZK - Czech Republic Koruna - Kč</option>
                                    <option value="DKK - Danish Krone - Kr.">DKK - Danish Krone - Kr.</option>
                                    <option value="DJF - Djiboutian Franc - Fdj">DJF - Djiboutian Franc - Fdj</option>
                                    <option value="DOP - Dominican Peso - $">DOP - Dominican Peso - $</option>
                                    <option value="XCD - East Caribbean Dollar - $">XCD - East Caribbean Dollar - $</option>
                                    <option value="EGP - Egyptian Pound - ج.م">EGP - Egyptian Pound - ج.م</option>
                                    <option value="ERN - Eritrean Nakfa - Nfk">ERN - Eritrean Nakfa - Nfk</option>
                                    <option value="EEK - Estonian Kroon - kr">EEK - Estonian Kroon - kr</option>
                                    <option value="ETB - Ethiopian Birr - Nkf">ETB - Ethiopian Birr - Nkf</option>
                                    <option value="EUR - Euro - €">EUR - Euro - €</option>
                                    <option value="FKP - Falkland Islands Pound - £">FKP - Falkland Islands Pound - £</option>
                                    <option value="FJD - Fijian Dollar - FJ$">FJD - Fijian Dollar - FJ$</option>
                                    <option value="GMD - Gambian Dalasi - D">GMD - Gambian Dalasi - D</option>
                                    <option value="GEL - Georgian Lari - ლ">GEL - Georgian Lari - ლ</option>
                                    <option value="DEM - German Mark - DM">DEM - German Mark - DM</option>
                                    <option value="GHS - Ghanaian Cedi - GH₵">GHS - Ghanaian Cedi - GH₵</option>
                                    <option value="GIP - Gibraltar Pound - £">GIP - Gibraltar Pound - £</option>
                                    <option value="GRD - Greek Drachma - ₯, Δρχ, Δρ">GRD - Greek Drachma - ₯, Δρχ, Δρ</option>
                                    <option value="GTQ - Guatemalan Quetzal - Q">GTQ - Guatemalan Quetzal - Q</option>
                                    <option value="GNF - Guinean Franc - FG">GNF - Guinean Franc - FG</option>
                                    <option value="GYD - Guyanaese Dollar - $">GYD - Guyanaese Dollar - $</option>
                                    <option value="HTG - Haitian Gourde - G">HTG - Haitian Gourde - G</option>
                                    <option value="HNL - Honduran Lempira - L">HNL - Honduran Lempira - L</option>
                                    <option value="HKD - Hong Kong Dollar - $">HKD - Hong Kong Dollar - $</option>
                                    <option value="HUF - Hungarian Forint - Ft">HUF - Hungarian Forint - Ft</option>
                                    <option value="ISK - Icelandic KrÃ³na - kr">ISK - Icelandic KrÃ³na - kr</option>
                                    <option value="INR - Indian Rupee - ₹">INR - Indian Rupee - ₹</option>
                                    <option value="IDR - Indonesian Rupiah - Rp">IDR - Indonesian Rupiah - Rp</option>
                                    <option value="IRR - Iranian Rial - ﷼">IRR - Iranian Rial - ﷼</option>
                                    <option value="IQD - Iraqi Dinar - د.ع">IQD - Iraqi Dinar - د.ع</option>
                                    <option value="ILS - Israeli New Sheqel - ₪">ILS - Israeli New Sheqel - ₪</option>
                                    <option value="ITL - Italian Lira - L,£">ITL - Italian Lira - L,£</option>
                                    <option value="JMD - Jamaican Dollar - J$">JMD - Jamaican Dollar - J$</option>
                                    <option value="JPY - Japanese Yen - ¥">JPY - Japanese Yen - ¥</option>
                                    <option value="JOD - Jordanian Dinar - ا.د">JOD - Jordanian Dinar - ا.د</option>
                                    <option value="KZT - Kazakhstani Tenge - лв">KZT - Kazakhstani Tenge - лв</option>
                                    <option value="KES - Kenyan Shilling - KSh">KES - Kenyan Shilling - KSh</option>
                                    <option value="KWD - Kuwaiti Dinar - ك.د">KWD - Kuwaiti Dinar - ك.د</option>
                                    <option value="KGS - Kyrgystani Som - лв">KGS - Kyrgystani Som - лв</option>
                                    <option value="LAK - Laotian Kip - ₭">LAK - Laotian Kip - ₭</option>
                                    <option value="LVL - Latvian Lats - Ls">LVL - Latvian Lats - Ls</option>
                                    <option value="LBP - Lebanese Pound - £">LBP - Lebanese Pound - £</option>
                                    <option value="LSL - Lesotho Loti - L">LSL - Lesotho Loti - L</option>
                                    <option value="LRD - Liberian Dollar - $">LRD - Liberian Dollar - $</option>
                                    <option value="LYD - Libyan Dinar - د.ل">LYD - Libyan Dinar - د.ل</option>
                                    <option value="LTL - Lithuanian Litas - Lt">LTL - Lithuanian Litas - Lt</option>
                                    <option value="MOP - Macanese Pataca - $">MOP - Macanese Pataca - $</option>
                                    <option value="MKD - Macedonian Denar - ден">MKD - Macedonian Denar - ден</option>
                                    <option value="MGA - Malagasy Ariary - Ar">MGA - Malagasy Ariary - Ar</option>
                                    <option value="MWK - Malawian Kwacha - MK">MWK - Malawian Kwacha - MK</option>
                                    <option value="MYR - Malaysian Ringgit - RM">MYR - Malaysian Ringgit - RM</option>
                                    <option value="MVR - Maldivian Rufiyaa - Rf">MVR - Maldivian Rufiyaa - Rf</option>
                                    <option value="MRO - Mauritanian Ouguiya - MRU">MRO - Mauritanian Ouguiya - MRU</option>
                                    <option value="MUR - Mauritian Rupee - ₨">MUR - Mauritian Rupee - ₨</option>
                                    <option value="MXN - Mexican Peso - $">MXN - Mexican Peso - $</option>
                                    <option value="MDL - Moldovan Leu - L">MDL - Moldovan Leu - L</option>
                                    <option value="MNT - Mongolian Tugrik - ₮">MNT - Mongolian Tugrik - ₮</option>
                                    <option value="MAD - Moroccan Dirham - MAD">MAD - Moroccan Dirham - MAD</option>
                                    <option value="MZM - Mozambican Metical - MT">MZM - Mozambican Metical - MT</option>
                                    <option value="MMK - Myanmar Kyat - K">MMK - Myanmar Kyat - K</option>
                                    <option value="NAD - Namibian Dollar - $">NAD - Namibian Dollar - $</option>
                                    <option value="NPR - Nepalese Rupee - ₨">NPR - Nepalese Rupee - ₨</option>
                                    <option value="ANG - Netherlands Antillean Guilder - ƒ">ANG - Netherlands Antillean Guilder - ƒ</option>
                                    <option value="TWD - New Taiwan Dollar - $">TWD - New Taiwan Dollar - $</option>
                                    <option value="NZD - New Zealand Dollar - $">NZD - New Zealand Dollar - $</option>
                                    <option value="NIO - Nicaraguan CÃ³rdoba - C$">NIO - Nicaraguan CÃ³rdoba - C$</option>
                                    <option value="NGN - Nigerian Naira - ₦">NGN - Nigerian Naira - ₦</option>
                                    <option value="KPW - North Korean Won - ₩">KPW - North Korean Won - ₩</option>
                                    <option value="NOK - Norwegian Krone - kr">NOK - Norwegian Krone - kr</option>
                                    <option value="OMR - Omani Rial - .ع.ر">OMR - Omani Rial - .ع.ر</option>
                                    <option value="PKR - Pakistani Rupee - ₨">PKR - Pakistani Rupee - ₨</option>
                                    <option value="PAB - Panamanian Balboa - B/.">PAB - Panamanian Balboa - B/.</option>
                                    <option value="PGK - Papua New Guinean Kina - K">PGK - Papua New Guinean Kina - K</option>
                                    <option value="PYG - Paraguayan Guarani - ₲">PYG - Paraguayan Guarani - ₲</option>
                                    <option value="PEN - Peruvian Nuevo Sol - S/.">PEN - Peruvian Nuevo Sol - S/.</option>
                                    <option value="PHP - Philippine Peso - ₱">PHP - Philippine Peso - ₱</option>
                                    <option value="PLN - Polish Zloty - zł">PLN - Polish Zloty - zł</option>
                                    <option value="QAR - Qatari Rial - ق.ر">QAR - Qatari Rial - ق.ر</option>
                                    <option value="RON - Romanian Leu - lei">RON - Romanian Leu - lei</option>
                                    <option value="RUB - Russian Ruble - ₽">RUB - Russian Ruble - ₽</option>
                                    <option value="RWF - Rwandan Franc - FRw">RWF - Rwandan Franc - FRw</option>
                                    <option value="SVC - Salvadoran ColÃ³n - ₡">SVC - Salvadoran ColÃ³n - ₡</option>
                                    <option value="WST - Samoan Tala - SAT">WST - Samoan Tala - SAT</option>
                                    <option value="SAR - Saudi Riyal - ﷼">SAR - Saudi Riyal - ﷼</option>
                                    <option value="RSD - Serbian Dinar - din">RSD - Serbian Dinar - din</option>
                                    <option value="SCR - Seychellois Rupee - SRe">SCR - Seychellois Rupee - SRe</option>
                                    <option value="SLL - Sierra Leonean Leone - Le">SLL - Sierra Leonean Leone - Le</option>
                                    <option value="SGD - Singapore Dollar - $">SGD - Singapore Dollar - $</option>
                                    <option value="SKK">SKK - Slovak Koruna - Sk</option>
                                    <option value="SBD - Solomon Islands Dollar - Si$">SBD - Solomon Islands Dollar - Si$</option>
                                    <option value="SOS - Somali Shilling - Sh.so.">SOS - Somali Shilling - Sh.so.</option>
                                    <option value="ZAR - South African Rand - R">ZAR - South African Rand - R</option>
                                    <option value="KRW - South Korean Won - ₩">KRW - South Korean Won - ₩</option>
                                    <option value="XDR - Special Drawing Rights - SDR">XDR - Special Drawing Rights - SDR</option>
                                    <option value="LKR - Sri Lankan Rupee - Rs">LKR - Sri Lankan Rupee - Rs</option>
                                    <option value="SHP - St. Helena Pound - £">SHP - St. Helena Pound - £</option>
                                    <option value="SDG - Sudanese Pound - .س.ج">SDG - Sudanese Pound - .س.ج</option>
                                    <option value="SRD - Surinamese Dollar - $">SRD - Surinamese Dollar - $</option>
                                    <option value="SZL - Swazi Lilangeni - E">SZL - Swazi Lilangeni - E</option>
                                    <option value="SEK - Swedish Krona - kr">SEK - Swedish Krona - kr</option>
                                    <option value="CHF - Swiss Franc - CHf">CHF - Swiss Franc - CHf</option>
                                    <option value="SYP - Syrian Pound - LS">SYP - Syrian Pound - LS</option>
                                    <option value="STD - São Tomé and Príncipe Dobra - Db">STD - São Tomé and Príncipe Dobra - Db</option>
                                    <option value="TJS - Tajikistani Somoni - SM">TJS - Tajikistani Somoni - SM</option>
                                    <option value="TZS - Tanzanian Shilling - TSh">TZS - Tanzanian Shilling - TSh</option>
                                    <option value="THB - Thai Baht - ฿">THB - Thai Baht - ฿</option>
                                    <option value="TOP - Tongan pa'anga - $">TOP - Tongan pa'anga - $</option>
                                    <option value="TTD - Trinidad & Tobago Dollar - $">TTD - Trinidad & Tobago Dollar - $</option>
                                    <option value="TND - Tunisian Dinar - ت.د">TND - Tunisian Dinar - ت.د</option>
                                    <option value="TRY - Turkish Lira - ₺">TRY - Turkish Lira - ₺</option>
                                    <option value="TMT - Turkmenistani Manat - T">TMT - Turkmenistani Manat - T</option>
                                    <option value="UGX - Ugandan Shilling - USh">UGX - Ugandan Shilling - USh</option>
                                    <option value="UAH - Ukrainian Hryvnia - ₴">UAH - Ukrainian Hryvnia - ₴</option>
                                    <option value="AED - United Arab Emirates Dirham - إ.د">AED - United Arab Emirates Dirham - إ.د</option>
                                    <option value="UYU - Uruguayan Peso - $">UYU - Uruguayan Peso - $</option>
                                    <option value="USD - US Dollar - $">USD - US Dollar - $</option>
                                    <option value="UZS - Uzbekistan Som - лв">UZS - Uzbekistan Som - лв</option>
                                    <option value="VUV - Vanuatu Vatu - VT">VUV - Vanuatu Vatu - VT</option>
                                    <option value="VEF - Venezuelan BolÃ­var - Bs">VEF - Venezuelan BolÃ­var - Bs</option>
                                    <option value="VND - Vietnamese Dong - ₫">VND - Vietnamese Dong - ₫</option>
                                    <option value="YER - Yemeni Rial - ﷼">YER - Yemeni Rial - ﷼</option>
                                    <option value="ZMK - Zambian Kwacha - ZK">ZMK - Zambian Kwacha - ZK</option>
                                </select>
                                <div>
                                    {Object.keys(usymbolTextErr).map((key) => {
                                        return <div style={{ color: "red" }}>{usymbolTextErr[key]}</div>
                                    })}
                                </div>
                            </div>

                        </div>


                    </form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose2}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={()=>UpdateData()}  >
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}