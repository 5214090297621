import React, { useState, useEffect } from "react"
import SuperAdminHeader from "../components/SAheader/Header"
import SuperAdminFooter from "../components/SAfooter/Footer"
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from 'material-table'
import { useHistory } from "react-router";
import Swal from 'sweetalert2'
import BaseUrl from "../../../../config/BaseUrl"
import image_url from "../../../../config/BaseUrl"
import { getApiWithToken, PutApi, PostApi, DeleteApi } from "../../../../config/Api/FakeuserApi";
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import IconButton from "@material-ui/core/IconButton";
import Country from "../../../components/countries/countries";

// import{ MaterialTable} from "@material-ui/core/MaterialTable";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    input: {
        display: "none",
    },
}));

export default function Membership() {
    const [Loader, setLoader] = useState(false)
    const classes = useStyles();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [disabledate,Setdisabledate ] = useState("");

     // ADD MODAL STATES
     const [MemName, SetMemName] = useState("")
     const [MemDescription, SetMemDescription] = useState("")
     const [MemDurationStart, SetMemDurationStart] = useState("")
     const [MemDurationEnd, SetMemDurationEnd] = useState("")
     const [Status, SetStatus] = useState("")
     const [MemPrice, SetMemPrice] = useState("")
     const [MemCountry, SetMemCountry] = useState("")
     const [Membership, SetMembership] = useState([])

     const [MemNameErr, setMemNameErr] = useState({});
     const [MemDescriptionErr, setMemDescriptionErr] = useState({});
     const [MemDurationStartErr, setMemDurationStartErr] = useState({});
     const [MemDurationEndErr, setMemDurationEndErr] = useState({});
     const [MemPriceErr, setMemPriceErr] = useState({});
     const [MemCountryErr, setMemCountryErr] = useState({});
     const [StatusErr, setStatusErr] = useState({});

       // Edit MODAL STATES
    const [MemId, setMemId] = useState('')
    const [EditStatus, SetEditStatus] = useState("")
    const [EditAdmin, SetEditPackAdmin] = useState("")
    const [EditMemPrice, SetEditMemPrice] = useState("")
    const [EditMemName, SetEditMemName] = useState("")
    const [EditMemDurationStart, SetEditMemDurationStart] = useState("")
    const [EditMemDurationEnd, SetEditMemDurationEnd] = useState("")
    const [EditMemDescription, SetEditMemDescription] = useState("")
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const [EditMemCountry, setEditMemCountry] = useState('')

    const [MemNameuErr, setMemNameuErr] = useState({});
     const [MemDescriptionuErr, setMemDescriptionuErr] = useState({});
     const [MemDurationStartuErr, setMemDurationStartuErr] = useState({});
     const [MemDurationEnduErr, setMemDurationEnduErr] = useState({});
     const [MemPriceuErr, setMemPriceuErr] = useState({});
     const [MemCountryuErr, setMemCountryuErr] = useState({});
     const [StatusuErr, setStatusuErr] = useState({});



    const Token = localStorage.getItem('SuperToken');
    useEffect(() => {

        var dateObj = new Date();
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
        if(month < 10){
        month = '0' + month.toString();
        }
        var day = dateObj.getUTCDate();
        var year = dateObj.getUTCFullYear();
      var  newdate = year + "-" + month + "-" + day;
      console.log("mewdate",newdate)
      Setdisabledate(newdate)
        ViewAllMembership()

    }, [])

     // VIEW ALL PACKAGES API

     const ViewAllMembership = () => {
        setLoader(true);
        getApiWithToken(`${BaseUrl.Super_Admin_Url}/Membership_Plans`, "", Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    // SetGetAccounts(data.data)
                    console.log("membership==>", data)
                    SetMembership(data.data)
                    // setdisable(true);
                    // $(".loader").hide();

                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })

    }



    // ADD ACCOUNTS API

    const AddNewMembership = () => {
        const isValid = formValidation();
        if (isValid== true){
        setLoader(true);
        var formdata = new FormData();

        formdata.append("Membership_Name", MemName);
        formdata.append("Membership_Description", MemDescription);
        formdata.append("Membership_DurationStart", MemDurationStart);
        formdata.append("Membership_DurationEnd", MemDurationEnd);
        formdata.append("Country", MemCountry);
        formdata.append("Membership_Price", MemPrice);
        formdata.append("Status", Status);



        PostApi(`${BaseUrl.Super_Admin_Url}/Membership_Plans`, formdata, Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    console.log("Addmember==>", data)
                    ViewAllMembership()
                    handleClose()

                    SetMemCountry("")
                    SetMemDescription("")
                    SetMemDurationEnd("")
                    SetMemDurationStart("")
                    SetMemName("")
                    SetStatus("")
                    SetMemPrice("")

                    Swal.fire({
                        title: "success",
                        text: data.message,
                        icon: "success"
                    })
                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })


    }else{
        formValidation()
    }
}



    const formValidation = () => {
        const MemNameErr = {};
        const MemDescriptionErr = {};
        const MemDurationStartErr = {};
        const MemDurationEndErr = {};
        const MemPriceErr = {};
        const MemCountryErr = {}
        const StatusErr = {}
        const ImageErr = {}




        let isValid = true;

        if (MemName.trim().length == 0) {
            MemNameErr.firstNamenull = "Required Membership Name";
            isValid = false;
        }
        if (MemDescription.trim().length == 0) {
            MemDescriptionErr.lastNamenull = "Required Description";
            isValid = false;
        }
       if (MemDurationStart.trim().length == 0) {
        MemDurationStartErr.emailnull = "Required Durantion time Start";
            isValid = false;
        }

         if (MemDurationEnd.trim().length == 0) {
            MemDurationEndErr.numbernull = "Required Durantion time End";
            isValid = false;
        }


        if (MemPrice.trim().length == 0) {
            MemPriceErr.rollnull = "Required Price";
            isValid = false;
        }


        if (MemCountry.trim().length == 0) {
            MemCountryErr.passwordnull = "Required Country";
            isValid = false;
        }



        if (Status == 0) {
            StatusErr.passwordnull = "Status";
            isValid = false;
        }





     setMemNameErr(MemNameErr);
     setMemDescriptionErr(MemDescriptionErr);
     setMemDurationStartErr(MemDurationStartErr);
     setMemDurationEndErr(MemDurationEndErr)
     setMemPriceErr(MemPriceErr)
     setMemCountryErr(MemCountryErr)
     setStatusErr(StatusErr)





        return isValid;
    }


    // console.log("Country",memContry)

    // Edit function
    const edit = (Id, packname, packdes, packDstart, packDend, packprice, memContry, packstatus) => {
        handleShow2()
        SetEditMemName(packname)
        SetEditMemDescription(packdes)
        SetEditMemDurationStart(packDstart)
        SetEditMemDurationEnd(packDend)
        SetEditMemPrice(packprice)
        setEditMemCountry(memContry)
        SetEditStatus(packstatus)
        setMemId(Id)


    }

    // EDITAPI EDIT SUBADMIN PANEL
    const UpdateMembership = () => {
          const updatevalid = UpdateformValidation();
        if (updatevalid == true) {
        setLoader(true);
        var formdata = new FormData();
        formdata.append("id", MemId);
        formdata.append("Membership_Description", EditMemDescription);
        formdata.append("Status", EditStatus);
        formdata.append("Membership_DurationStart", EditMemDurationStart);
        formdata.append("Membership_DurationEnd", EditMemDurationEnd);
        formdata.append("Membership_Name", EditMemName);
        formdata.append("Membership_Price", EditMemPrice);
        formdata.append("Country", EditMemCountry);
        PutApi(`${BaseUrl.Super_Admin_Url}/Membership_Plans`, formdata, Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    console.log("dataaupdate==>", data)
                    ViewAllMembership()
                    handleClose2()

                    Swal.fire({
                        title: "success",
                        text: data.message,
                        icon: "success"
                    })
                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })
    } else{
        UpdateformValidation()
    }
}



const UpdateformValidation = () => {
    const MemNameuErr = {};
    const MemDescriptionuErr = {};
    const MemDurationStartuErr = {};
    const MemDurationEnduErr = {};
    const MemPriceuErr = {};
    const MemCountryuErr = {}
    const StatusuErr = {}




    let isValid = true;

    if (EditMemName.length == 0) {
        MemNameuErr.firstNamenull = "Required Membership Name";
        isValid = false;
    }
    if (EditMemDescription.length == 0) {
        MemDescriptionuErr.lastNamenull = "Required Description";
        isValid = false;
    }
   if (EditMemDurationStart.length == 0) {
    MemDurationStartuErr.emailnull = "Required Durantion time Start";
        isValid = false;
    }

     if (EditMemDurationEnd.length == 0) {
        MemDurationEnduErr.numbernull = "Required Durantion time End";
        isValid = false;
    }


    if (EditMemPrice.length == 0) {
        MemPriceuErr.rollnull = "Required Price";
        isValid = false;
    }


    if (EditMemCountry.length == 0) {
        MemCountryuErr.passwordnull = "Required Country";
        isValid = false;
    }



    if (EditStatus == 0) {
        StatusuErr.passwordnull = "Status";
        isValid = false;
    }





 setMemNameuErr(MemNameuErr);
 setMemDescriptionuErr(MemDescriptionuErr);
 setMemDurationStartuErr(MemDurationStartuErr);
 setMemDurationEnduErr(MemDurationEnduErr)
 setMemPriceuErr(MemPriceuErr)
 setMemCountryuErr(MemCountryuErr)
 setStatusuErr(StatusuErr)





    return isValid;
}



     // DELETE API

     const Delete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete it",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteMembership(id)
            }
        })

    }

    const DeleteMembership = (id) => {
        // console.log("token==>",Token)

        setLoader(true);
        // var formdata = new FormData();
        // formdata.append("PackId", id);

        DeleteApi(`${BaseUrl.Super_Admin_Url}/Membership_Plans?id=${id}`, "", Token)
            .then(({ data }) => {
                if (data.status) {
                    setLoader(false);
                    console.log("dELETE==>", data)
                    ViewAllMembership()
                    // handleClose2()

                    Swal.fire({
                        title: "success",
                        text: data.message,
                        icon: "success"
                    })
                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })
    }






    return (
        <>
           {Loader? <div class="loader"></div>:null}
            <div id="app">
                <SuperAdminHeader />
                <div class="main-wrapper main-wrapper-1">

                    <div class="main-content">
                        <section class="section">
                            <ul class="breadcrumb breadcrumb-style ">
                                <li class="breadcrumb-item">
                                    <h4 class="page-title m-b-0">MEMBERSHIP</h4>
                                </li>
                                <div class="row">
                                    <div class="col-md-12  text-right">
                                        <button type="button" onClick={()=>handleShow()} className="btn btn-outline-primary">Add Membership</button>

                                    </div>
                                </div>

                            </ul>

                            <div class="section-body">
                                <div class="row">

                                        {Membership.map((e)=>

                                        <div class=" col-md-4 ">
                                            <div class="pricing pricing-highlight">
                                                <div class="pricing-title">
                                                  {e.Membership_Name}
                                                    </div>
                                                <div class="pricing-padding">
                                                    <div class="pricing-price">
                                                        <div>$ {e.Membership_Price} </div>
                                                        <div>Membership Price</div>
                                                    </div>
                                                    <div class="pricing-details">
                                                        <div class="pricing-item">
                                                            <div class="pricing-item-icon bg-primary" ><i class="fas fa-check"></i></div>
                                                            <div class="pricing-item-label">Membership Country: {e.Country}</div>
                                                        </div>
                                                        <div class="pricing-item">
                                                            <div class="pricing-item-icon bg-primary"><i class="fas fa-check"></i></div>
                                                            <div class="pricing-item-label">Membership Duration Start: {e.Membership_DurationStart} </div>
                                                        </div>
                                                        <div class="pricing-item">
                                                            <div class="pricing-item-icon bg-primary"><i class="fas fa-check"></i></div>
                                                            <div class="pricing-item-label"> Membership Duration End: {e.Membership_DurationEnd} </div>
                                                        </div>
                                                        <div class="pricing-item">
                                                            <div class="pricing-item-icon bg-primary"><i class="fas fa-check"></i></div>
                                                            <div class="pricing-item-label">Status: {e.Status} </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 text-center">

                                                                <button type="button" onClick={()=>edit(e.id, e.Membership_Name, e.Membership_Description, e.Membership_DurationStart, e.Membership_DurationEnd, e.Membership_Price,e.Country, e.Status)}  class="btn btn-primary btn-lg">Edit</button>
                                                            </div>

                                                            <div className="col-md-6 text-center">
                                                                <button  onClick={(a) => Delete(e.id)} type="button" class="btn btn-primary btn-lg">Delete</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        )}

                                </div>
                            </div>
                        </section>

                    </div>

                    <SuperAdminFooter />
                </div>
            </div>





            {/* Modal 1 Add   */}


            <Modal className="btn-close"
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg"
                show={show}
                onHide={handleClose}>
                <Modal.Header >
                    <Modal.Title>Add New Membership</Modal.Title>
                    <i onClick={handleClose} style={{ fontSize: "24px" }} className=" fa-10X fas fa-window-close"></i>
                </Modal.Header>
                <Modal.Body>

                    <form>
                        <div className="form-row text-center " style={{ display: "block" }}>




                        </div>
                        <div className="form-row mt-4">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputFN">Membership Name</label>
                                <input value={MemName} onChange={(e)=>SetMemName(e.target.value)}  type="text" className="form-control" id="inputFN" placeholder="Enter Membership Name" />
                                <div>
                                    {Object.keys(MemNameErr).map((key) => {
                                        return <div style={{ color: "red" }}>{MemNameErr[key]}</div>
                                    })}
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputLN">Membership Description</label>
                                <textarea value={MemDescription} onChange={(e)=>SetMemDescription(e.target.value)}  type="text" className="form-control" id="inputLN" placeholder="Enter Membership Description" />
                                <div>
                                    {Object.keys(MemDescriptionErr).map((key) => {
                                        return <div style={{ color: "red" }}>{MemDescriptionErr[key]}</div>
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputEmail">Membership Duration Start</label>
                                <input value={MemDurationStart} onChange={(e)=>SetMemDurationStart(e.target.value)} min={disabledate}  type="date" className="form-control" id="inputEmail" placeholder=" " />
                                <div>
                                    {Object.keys(MemDurationStartErr).map((key) => {
                                        return <div style={{ color: "red" }}>{MemDurationStartErr[key]}</div>
                                    })}
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputPS">Membership Duration End</label>
                                <input value={MemDurationEnd} onChange={(e)=>SetMemDurationEnd(e.target.value)} min={disabledate} type="date" className="form-control" id="inputPS" placeholder="" />
                                <div>
                                    {Object.keys(MemDurationEndErr).map((key) => {
                                        return <div style={{ color: "red" }}>{MemDurationEndErr[key]}</div>
                                    })}
                                </div>
                            </div>

                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputCN">Membership Price</label>
                                <input  value={MemPrice} onChange={(e)=>SetMemPrice(e.target.value)}   min="0" max="111111111111111111111" type="number" className="form-control" id="inputCN" placeholder="Enter Membership Price" />
                                <div>
                                    {Object.keys(MemPriceErr).map((key) => {
                                        return <div style={{ color: "red" }}>{MemPriceErr[key]}</div>
                                    })}
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                            <label for="exampleFormControlSelect1">Status</label>
                                <select  onClick={(e)=>SetStatus(e.target.value)}  class="form-control" id="exampleFormControlSelect1">
                                <option>Select Status...</option>
                                    <option value={"True"} >True</option>
                                    <option value={"False"} >False</option>



                                </select>
                                <div>
                                    {Object.keys(StatusErr).map((key) => {
                                        return <div style={{ color: "red" }}>{StatusErr[key]}</div>
                                    })}
                                </div>
                            </div>


                            <div class="form-group col-md-6 ">
                            <label for="exampleFormControlSelect1">Select Membership Country</label>

                            <Country
                             value={MemCountry}
                             onSelect={(country) => {
                                SetMemCountry(country)
                             }}
                            />
                             <div>
                                    {Object.keys(MemCountryErr).map((key) => {
                                        return <div style={{ color: "red" }}>{MemCountryErr[key]}</div>
                                    })}
                                </div>

                            </div>

                        </div>



                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={()=>AddNewMembership()} >
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>


              {/* MODAL 2 FOR EDIT  */}


              <Modal className="btn-close"
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg"
                show={show2}
                onHide={handleClose2}>
                <Modal.Header >
                    <Modal.Title>Edit Membership</Modal.Title>
                    <i onClick={handleClose2} style={{ fontSize: "24px" }} className=" fa-10X fas fa-window-close"></i>
                </Modal.Header>
                <Modal.Body>

                    <form>
                        <div className="form-row text-center " style={{ display: "block" }}>




                        </div>
                        <div className="form-row mt-4">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputFN">Membership Name</label>
                                <input value={EditMemName} onChange={(e)=>SetEditMemName(e.target.value)}  type="text" className="form-control" id="inputFN" placeholder="Enter Membership Name" />
                                <div>
                                    {Object.keys(MemNameuErr).map((key) => {
                                        return <div style={{ color: "red" }}>{MemNameuErr[key]}</div>
                                    })}
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputLN">Membership Description</label>
                                <textarea value={EditMemDescription} onChange={(e)=>SetEditMemDescription(e.target.value)}  type="text" className="form-control" id="inputLN" placeholder="Enter Membership Description" />
                                <div>
                                    {Object.keys(MemDescriptionuErr).map((key) => {
                                        return <div style={{ color: "red" }}>{MemDescriptionuErr[key]}</div>
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputEmail">Membership Duration Start</label>
                                <input value={EditMemDurationStart} onChange={(e)=>SetEditMemDurationStart(e.target.value)} min={disabledate} type="date" className="form-control" id="inputEmail" placeholder=" " />
                                <div>
                                    {Object.keys(MemDurationStartuErr).map((key) => {
                                        return <div style={{ color: "red" }}>{MemDurationStartuErr[key]}</div>
                                    })}
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputPS">Membership Duration End</label>
                                <input value={EditMemDurationEnd} onChange={(e)=>SetEditMemDurationEnd(e.target.value)} min={disabledate}  type="date" className="form-control" id="inputPS" placeholder="" />
                                <div>
                                    {Object.keys(MemDurationEnduErr).map((key) => {
                                        return <div style={{ color: "red" }}>{MemDurationEnduErr[key]}</div>
                                    })}
                                </div>
                            </div>

                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputCN">Membership Price</label>
                                <input value={EditMemPrice} onChange={(e)=>SetEditMemPrice(e.target.value)} min="0" max="111111111111111111111"  type="number" className="form-control" id="inputCN" placeholder="Enter Package Price" />
                                <div>
                                    {Object.keys(MemPriceuErr).map((key) => {
                                        return <div style={{ color: "red" }}>{MemPriceuErr[key]}</div>
                                    })}
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                            <label for="exampleFormControlSelect1">Status</label>
                                <select value={EditStatus} onChange={(e) => SetEditStatus(e.target.value)} class="form-control" id="exampleFormControlSelect1">
                                <option>Select Status...</option>
                                    <option  >True</option>
                                    <option  >False</option>
                                </select>
                                <div>
                                    {Object.keys(StatusuErr).map((key) => {
                                        return <div style={{ color: "red" }}>{StatusuErr[key]}</div>
                                    })}
                                </div>
                            </div>


                            <div class="form-group col-md-6 ">
                                <label for="exampleFormControlSelect1">Select Membership Country</label>
                               <Country
                               value={EditMemCountry}
                               onSelect={(country) => {
                                setEditMemCountry(country)
                            }}/>
                             <div>
                                    {Object.keys(MemCountryuErr).map((key) => {
                                        return <div style={{ color: "red" }}>{MemCountryuErr[key]}</div>
                                    })}
                                </div>
                            </div>

                        </div>



                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose2}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={()=>UpdateMembership()}  >
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>


        </>
    )
}