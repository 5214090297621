import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import Country from "../components/countries/countries";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/semantic-ui.css'
import { connect } from "react-redux";
import { checkEmailorPhone, setValue } from "../../config/Redux/Actions/Index";
import background from "../auth/background.png"



const BusinessDetails = (props) => {
    console.log("Mobile verification code",props.data.MobileverificationCode)
    const history = useHistory();
    const [firstName, setFirstname] = useState('');
    const [middleName, setMiddlename] = useState('');
    const [lastName, setLastname] = useState('');
    // const [Code, setCode] = useState('');
    const [Number, setNumber] = useState('');
    const [state, SetState] = useState("")
    const [SelectedCountry, setSelectedCountry] = useState("")
    const [BusinessType, SetBusinessType] = useState("")
    const [Loader, setLoader] = useState(false)


    const [firstNameErr, setFirstnameErr] = useState({});
    const [middleNameErr, setMiddlenameErr] = useState({});
    const [lastNameErr, setLastnameErr] = useState({});
    const [NumberErr, setNumberErr] = useState({});
    const [CodeErr, setCodeErr] = useState({});
    const [BusinessTypeErr, SetBusinessTypeErr] = useState({})
    const [SelectedCountryErr, setSelectedCountryErr] = useState({})





    const onSubmit = (e) => {
        // setLoader(true)

        const isValid = formValidation();
        if (isValid == true) {
            setLoader(true)

            const formdata = new FormData()
            formdata.append("Email", props.data.email)
            formdata.append("Businessadress_Number", Number)
            formdata.append("Businessadress_Country_Code", state)


            props.checkEmailorPhone(formdata)
                .then(({ status }) => {
                    setLoader(false);
                    if (status) {
                        props.setValue("Businessadress_Number", Number)
                        props.setValue("Businessadress_Country_Code", state)
                        props.setValue("Business_Location", SelectedCountry)
                        props.setValue("Business_Type", BusinessType)
                        props.setValue("First_name", firstName)
                        props.setValue("last_name", lastName)
                        props.setValue("Middle_name", middleName)



                        history.push("./signupdetails")
                    }
                })
        }
        else {
            formValidation();
        }
    }



    const formValidation = () => {
        const firstNameErr = {};
        // const middleNameErr = {};
        const lastNameErr = {};
        const numberErr = {};
        const CodeErr = {}
        const BusinessTypeErr ={};
        const SelectedCountryErr ={}


        let isValid = true;

        if (firstName.trim().length == 0) {
            firstNameErr.firstNamenull = "Enter your Firstname";
            isValid = false;
        }
        // if (middleName.trim().length == 0) {
        //     middleNameErr.middleNamenull = "Enter your Middlename";
        //     isValid = false;
        // }
        if (lastName.length == 0) {
            lastNameErr.lastNamenull = "Enter your Lastname";
            isValid = false;
        }
        if (state.trim().length == 0) {
            CodeErr.codenull = "Enter your country code";
            isValid = false;
        }
        if (Number.length == 0) {
            numberErr.numbernull = "Enter your phone number";
            isValid = false;
        }
        // if (Number.trim().length < 11) {
        //     numberErr.numbernull = "Enter 11 digits Number";
        //     isValid = false;
        // }

        // if (Number.trim().length > 11) {
        //     numberErr.numbernull = "Enter max 11 digits Number";
        //     isValid = false;
        // }
        if (BusinessType.length == 0) {
            BusinessTypeErr.numbernull = "Select Business Type";
            isValid = false;
        }

        if (SelectedCountry.length == 0) {
            SelectedCountryErr.numbernull = "Select Business Location";
            isValid = false;
        }

        setFirstnameErr(firstNameErr);
        setMiddlenameErr(middleNameErr);
        setLastnameErr(lastNameErr);
        setNumberErr(numberErr)
        setCodeErr(CodeErr)
        SetBusinessTypeErr(BusinessTypeErr)
        setSelectedCountryErr(SelectedCountryErr)

        return isValid;
    }

    return (
        <>
           {Loader? <div className="loader" />:null}
            <div id="app" style={{backgroundImage: `url(${background})`, marginTop: '-50px', backgroundSize: 'cover', height: '130vh' }}>
                <section className="section">
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <img style={{ width: "20%" }} src="assets/logo/superman.png"></img>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h4>Business Details</h4>
                                    </div>
                                    <div className="card-body">
                                        <form>
                                            <div className="form-group mb-1">
                                                <label htmlFor="email">Business Location</label>
                                                <Country
                                                value={SelectedCountry}
                                                    onSelect={(country) => {
                                                        setSelectedCountry(country)

                                                    }}

                                                />
                                                  <div>
                                                            {Object.keys(SelectedCountryErr).map((key) => {
                                                                return <div style={{ color: "red" }}>{SelectedCountryErr[key]}</div>
                                                            })}
                                                        </div>

                                                <div className="invalid-feedback">
                                                    Please fill in your Name
                                                </div>
                                                <small>If you don't have a business, enter your country of residence.</small>
                                                <br />
                                                <small>If you don't have a business, enter your country of residence.</small>
                                            </div>
                                            <div className="form-group mt-3 mb-1">
                                                <label >Business Type</label>
                                                <select value={BusinessType} onChange={(e) =>{SetBusinessType(e.target.value)}} id="businessType" name="businessType" className="form-control">
                                                    <option value="None, I am an ">None, I am an </option>
                                                    <option value="None, I am  individual">None, I am  individual</option>
                                                    <option value="None, I am  an individual">None, I am an individual</option>
                                                </select>
                                                <div>
                                                            {Object.keys(BusinessTypeErr).map((key) => {
                                                                return <div style={{ color: "red" }}>{BusinessTypeErr[key]}</div>
                                                            })}
                                                        </div>
                                                {/* <input id="email" type="email" className="form-control" name="email" tabIndex={1} required autofocus /> */}
                                                <div className="invalid-feedback">
                                                    Please fill in your email
                                                </div>
                                            </div>
                                            <div className="form-group mb-1">
                                                <div className="d-block">
                                                    <label htmlFor="password" className="control-label">Your Name</label>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <input value={firstName} onChange={(e) => setFirstname(e.target.value)} type="text" className="form-control" name="first" placeholder="First name" tabIndex={2} />
                                                        <div>
                                                            {Object.keys(firstNameErr).map((key) => {
                                                                return <div style={{ color: "red" }}>{firstNameErr[key]}</div>
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <input value={middleName} onChange={(e) => setMiddlename(e.target.value)} type="text" className="form-control" name="middle" placeholder="Middle name(s)" tabIndex={2} />
                                                        {/* <div>
                                                            {Object.keys(middleNameErr).map((key) => {
                                                                return <div style={{ color: "red" }}>{middleNameErr[key]}</div>
                                                            })}
                                                        </div> */}
                                                    </div>
                                                    <div className="col-md-4">
                                                        <input value={lastName} onChange={(e) => setLastname(e.target.value)} type="text" className="form-control" name="last" placeholder="Last Name" tabIndex={2} />
                                                        <div>
                                                            {Object.keys(lastNameErr).map((key) => {
                                                                return <div style={{ color: "red" }}>{lastNameErr[key]}</div>
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-md-4">
                                                        <PhoneInput
                                                            style={{ marginleft: "30px" }}
                                                            country={'pk'}
                                                            // preferredCountries
                                                            value={state}
                                                            onChange={(e) => SetState(e)}

                                                        />
                                                         {Object.keys(CodeErr).map((key) => {
                                                                return <div style={{ color: "red" }}>{CodeErr[key]}</div>
                                                            })}


                                                    </div>


                                                    <div className="col-md-7 ">
                                                        <input value={Number} onChange={(e) => setNumber(e.target.value)} type="number" className="form-control" placeholder="Number" />
                                                        <div>
                                                            {Object.keys(NumberErr).map((key) => {
                                                                return <div style={{ color: "red" }}>{NumberErr[key]}</div>
                                                            })}
                                                        </div>
                                                    </div>



                                                </div>



                                            </div>
                                            <div className="form-group mt-4">
                                                <p style={{ lineHeight: "20px" }} className="text-muted">By clicking on 'Agree and continue', you accept the following policies, agreement and notices;</p>
                                                <p style={{ lineHeight: "20px" }} className="text-muted">Babylon Survices Business Solutions Agreement - <a href="#">North America</a> International Seller Rules - <a href="#">Global</a> </p>
                                            </div>
                                            <div className="form-group">
                                                <button  type="button" onClick={()=>onSubmit()} className="btn btn-primary btn-lg btn-block">
                                                    NEXT
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                    {/* <div className="mb-4 text-muted text-center">
                                        Already Registered? <a className='customPointer' onClick={() => history.push('/login')}>Login</a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    data: state.Register

})

const mapDispatchToProps = {
    checkEmailorPhone,
    setValue
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessDetails)