import React, { useState, useEffect } from "react"
import AdminHeader from "../components/adminheader/AdminHeader"
import AdminFooter from "../components/adminfooter/AdminFooter"
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from 'material-table'
import { useHistory } from "react-router";
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2'
import BaseUrl from "../../../../config/BaseUrl"
import image_url from "../../../../config/BaseUrl"
// import default_image from "../../../../../"
import { getApiWithToken, PutApi, PostApi, DeleteApi } from "../../../../config/Api/FakeuserApi";
import IconButton from "@material-ui/core/IconButton";
import { mapToStyles } from "@popperjs/core/lib/modifiers/computeStyles";
import { Reorder } from "@material-ui/icons";
import {FormCheck} from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { Link } from "react-router-dom";

const background={
    color: "white",
    backgroundColor:"rgba(0,0,0,.65)"
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    input: {
        display: "none",
    },
}));

export default function SendSmsList() {
    const [Loader, setLoader] = useState(false)
    const history = useHistory();
    const classes = useStyles();



     //for send modal

     const [show3, setShow3] = useState(false);
     const handleClose3 = () => setShow3(false);
     const handleShow3 = () => setShow3(true);

     //GETAPI
     const [GetSmslist, setGetSmslist] = useState([]);
     const [record, setrecord] = useState([]);




     const Token = localStorage.getItem('Token');
     useEffect(() => {
        GetSmsLlist()

     }, [])



// EMAIL DATA LIST GET APAI

const GetSmsLlist = () => {

    setLoader(true);
    getApiWithToken(`${BaseUrl.Admin_Url}/GetSendSmsList`, "", Token)

        .then(({ data }) => {
            setLoader(false);
            if (data.status) {
                setGetSmslist(data.data)
                console.log("SMSLIST=======>", data)


            } else {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: data.message,
                    icon: "error"
                })
            }
        }).catch(function (e) {
            // $(".loader").hide();
            Swal.fire({
                title: "Opps",
                text: e.message,
                icon: "error"
            })
        })

}




const ConvertEval=(e)=>{
    // console.log("eval",e)
    eval(e)
    setrecord(eval(e))
    handleShow3()

    // console.log("eval", eval(e))

}



    return (
        <>
            {Loader ? <div className="loader" ></div> : null}
            <div id="app">
                <AdminHeader />
                <div class="main-wrapper main-wrapper-1">

                    <div class="main-content">
                        <section class="section">
                            <ul class="breadcrumb breadcrumb-style ">
                                <li class="breadcrumb-item">
                                    <h4 class="page-title m-b-0">SEND SMS LIST</h4>
                                </li>
                                <div class="row">
                                    <div class="col-md-12  text-right">
                                        {/* <button type="button" className="btn btn-outline-primary">Add Membership</button> */}

                                    </div>
                                </div>

                            </ul>

                            <div class="section-body">
                                <div class="row">

                              {GetSmslist.map((e)=>

                                        <div class=" col-md-4 ">
                                            <div class="pricing pricing-highlight">
                                                <div class="pricing-title">
                                                    {e.Name}

                                                    </div>
                                                <div class="pricing-padding">

                                                    <div class="pricing-details">
                                                        <div class="pricing-item">
                                                            <div class="pricing-item-icon bg-primary" ><i class="fas fa-check"></i></div>
                                                            <div class="pricing-item-label">Date : {e.Date}</div>
                                                        </div>
                                                        <div class="pricing-item">
                                                            <div class="pricing-item-icon bg-primary"><i class="fas fa-check"></i></div>
                                                            <div class="pricing-item-label">Time : {e.Time}  </div>
                                                        </div>
                                                        <div class="pricing-item">
                                                            <div class="pricing-item-icon bg-primary"><i class="fas fa-check"></i></div>
                                                            <div class="pricing-item-label"> Link :<a href={e.Link}>{e.Link}</a>   </div>
                                                        </div>
                                                        <div class="pricing-item">
                                                            <div class="pricing-item-icon bg-primary"><i class="fas fa-check"></i></div>
                                                            <div class="pricing-item-label">Description :{e.Description.slice(0,60)} .......  </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12 text-center">

                                                                <button type="button" onClick={()=>ConvertEval(e.UserRecords)}  class="btn btn-primary btn-lg">View</button>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        )}


                                </div>
                            </div>
                        </section>

                    </div>

                    <AdminFooter />
                </div>
            </div>




            {/* modal for send  */}



            <Modal
                scrollable={true}
                size="lg"
                // aria-labelledby="example-modal-sizes-title-lg"
                show={show3}
                onHide={handleClose3}>
                <Modal.Header >
                    <Modal.Title> View Send Sms List</Modal.Title>
                    <hr />
                    <i onClick={handleClose3} style={{ fontSize: "24px" }} className=" fa-10X fas fa-window-close"></i>
                </Modal.Header>




                <Modal.Body  >

                    <form>



                    {record.map((e)=>
                        <div className="row">

                            <div className="col-md-3">
                                <small style={{ fontSize: "13px" }}>Name</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{e.name}</p>

                            </div>
                            <div className="col-md-4">
                                <small style={{ fontSize: "13px" }}>Mobile Number</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{e['Mobile Number']}</p>

                            </div>

                            <div className="col-md-3" >
                                <small style={{ fontSize: "13px" }}> Date</small>

                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{e.date}</p>

                            </div>

                            <div className="col-md-2" >
                                <small style={{ fontSize: "13px" }}>Time</small>

                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{e.time}</p>

                            </div>

                        </div>
                    )}









                    </form>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="primary" onClick={handleClose3}>
                        Cancel
                    </Button>

                </Modal.Footer>
            </Modal>




        </>
    )
}