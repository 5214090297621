import React, { useState, useEffect } from "react"
import Footer from '../../../components/footer/footer';
import Header from '../../../components/header/header';
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import image_url from "../../../../config/BaseUrl"
import Swal from 'sweetalert2'
import BaseUrl from "../../../../config/BaseUrl"
import { useHistory } from "react-router-dom";
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

import Country from "../../../components/countries/countries"
import { connect } from "react-redux";
import { checkEmailorPhone, setValue } from "../../../../config/Redux/Actions/Index";



import { getApiWithToken,PostApiWithOutToken, PutApi, PostApi, DeleteApi } from "../../../../config/Api/FakeuserApi";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    input: {
        display: "none",
    },
}));


const Profile=(props)=> {

    /// details

    console.log("PROPS", props.data)
    const history = useHistory();
    const [Step1, setStep1] = useState(true)
    const [Step2, setStep2] = useState(false)
    const [Step3, setStep3] = useState(false)
    const [Step4, setStep4] = useState(false)
    const [Step5, setStep5] = useState(false)
    const [Step6, setStep6] = useState(false)

    // STATES FOR SHOW DATA AND UPDATE DATA
    const classes = useStyles();
    const [fname, setFname] = useState("")
    const [lname, setLname] = useState("")
    const [email, setEmail] = useState("")
    const [contact, setContact] = useState("")
    const [profileimage, setprofileimage] = useState(null)
    const [passsword, setPassword] = useState("")
    const [Loader, setLoader] = useState(false)
    const [Business_Address_StreetNumber, setBusiness_Address_StreetNumber] = useState('');

    // const [Role, setRole] = useState("")


    //    FOR PIC UPLOAD
    const [UpdateThumbnail, setUpdateThumbnail] = useState(null)
    const [Selectedprofile, setSelectedprofile] = useState("")


    // PERSONAL INFO VALIDATION
    const [passportNum, setPassportnum] = useState();
    const [addressLine1, setAddressline1] = useState('');
    const [addressLine2, setAddressline2] = useState('');
    const [streetNo, setStreetno] = useState('');
    const [phoneNum, setPhonenum] = useState();
    const [ZipCode, setZipCode] = useState();
    const [EditBusinessType, SetEditBusinessType] = useState("")
    const [SelectedCountry, setSelectedCountry] = useState("")

    const [CountryofCitizenship, SetCountryofCitizenship] = useState("")
    const [CountryofBirth, SetCountryofBirth] = useState("")
    const [ProofofIdentity, SetProofofIdentity] = useState("")
    const [Countryofissue, SetCountryofissue] = useState("")
    const [DateofBirth, SetDateofBirth] = useState("")


    const [BusinessAddressCountry, SetBusinessAddressCountry] = useState("")
    const [passportNumErr, setPassportnumErr] = useState({});
    const [addressLine1Err, setAddressline1Err] = useState({});
    const [addressLine2Err, setAddressline2Err] = useState({});
    const [streetNoErr, setStreetnoErr] = useState({});
    const [phoneNumErr, setPhonenumErr] = useState({});
    const [ZipcodeErr, setZipcodeErr] = useState({});
    const [DateofBirthErr, setDateofBirthErr] = useState({});


    // BILLING INFO VALIDATION
    const [creditCardnum, setCreditcardnum] = useState("");
    const [cardHoldername, setCardholdername] = useState("");
    const [expiry, setExpiry] = useState("");
    const [expiryear, setexpiryear] = useState("");

    const [cvc, setCvc] = useState("");
    const [Focus, setFocus] = useState("");
    const [Billing_Address, setBilling_Address] = useState("");
    const [Billing_Addres2, setBilling_Addres2] = useState("");
    const [Billing_Addres_Country, setBilling_Addres_Country] = useState("");
    const [Billing_Addres_StreetNumber, setBilling_Addres_StreetNumber] = useState("");


    const [creditCardnumErr, setCreditcardnumErr] = useState({});
    const [expiryMonthErr, setExpirymonthErr] = useState({});
    const [cardHoldernameErr, setCardholdernameErr] = useState({});

     // STORE VALIDATION
     const [storeName, setStoreName] = useState("");

     const [storeNameErr, setStoreNameErr] = useState({});
     const [UniversalProduct, SetUniversalProduct] = useState("")
     const [Brandowner, SetBrandowner] = useState("")
     const [GovernmentRegistered, SetGovernmentRegistered] = useState("")

       // CONFIRMATION CODE VALIDATION
    const [confirmationCode, setConfirmationcode] = useState("");
    const [confirmationCodeErr, setConfirmationcodeErr] = useState({});
    const [Ctrue, setCtrue] = useState(false);
    const [disabled, setdisabled] = useState(true);



    useEffect(() => {
        GetProfileData()

    }, [])


    const Token = localStorage.getItem('SellerToken');



    // GET PROFILE DATA API

    const GetProfileData = () => {
        setLoader(true);

        getApiWithToken(`${BaseUrl.base_url}/GetSellerProfile`, "", Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    const Data = data.data
                    console.log("Data==", Data)
                    // console.log("profiledata==", data)
                    localStorage.setItem('seller', JSON.stringify(Data));

                    setprofileimage(Data.SProfile)
                    setFname(Data.First_name);
                    setLname(Data.Name);
                    SetEditBusinessType(Data.Business_Type);
                    setSelectedCountry(Data.Business_Location)

                    setEmail(Data.Email);
                    setPassword(Data.passsword);
                    // setContact(Data.Businessadress_Number);
                    setPhonenum(Data.Businessadress_Number);
                    setStreetno(Data.Business_Address_StreetNumber)

                    SetCountryofCitizenship(Data.Country_of_Citizenship);
                    SetDateofBirth(Data.DOB);
                    SetCountryofBirth(Data.Country_of_Birth);
                    SetProofofIdentity(Data.Proof_of_Identity);
                    SetCountryofissue(Data.Country_of_issue);
                    setPassportnum(Data.PassportNumber)

                    setAddressline1(Data.Business_address1);
                    setAddressline2(Data.Business_address2);
                    SetBusinessAddressCountry(Data.Business_Address_Country);
                    setBusiness_Address_StreetNumber(Data.Business_Address_StreetNumber);
                    setZipCode(Data.Business_Address_Zipcode);
                   setCreditcardnum(Data.CardDetails.Credit_Card_Number)
                   setCardholdername(Data.CardDetails.Card_Holder_Name)
                   setExpiry(Data.CardDetails.Expiration_month)
                   setexpiryear(Data.CardDetails.Expiration_Year)

                   setCvc(Data.CardDetails.Cvc)
                   setBilling_Address(Data.Billing_Address)
                   setBilling_Addres2(Data.Billing_Addres2)
                   setBilling_Addres_Country(Data.Billing_Addres_Country)
                   setBilling_Addres_StreetNumber(Data.Billing_Addres_StreetNumber)
                   setStoreName(Data.Store_Name)
                   SetUniversalProduct(Data.Universal_Products)
                   SetBrandowner(Data.Are_you_Brand_Owner)
                   SetGovernmentRegistered(Data.Do_You_Goverment_registered_trademark)



                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })

    }
    console.log("Data.DOB",DateofBirth)


    // FOR PROFILE UPLOAD CODE
    const handleInputFileChange = (event, func) => {
        var file = event.target.files[0];
        var reader = new FileReader();
        // console.log(file);
        const filesize = file.size
        const filesizeround = Math.round((filesize / 1024))
        if (filesizeround > 1024) {
            // setupdateProfileImage(null)
            // setselectProfileImage(null)
            Swal.fire({
                title: "Opps",
                text: "plz select your image less then and equals to 1mb",
                icon: "warning"
            });
        } else {
            var url = reader.readAsDataURL(file);
            // console.log(url)
            reader.onloadend = function (e) {
                setSelectedprofile(reader.result);
                setUpdateThumbnail(file);


                // console.log("data-======>",reader.result)
            };
            // console.log(selectProfileImage)
            func(event.target.files[0])
            // setdisable(false)
        }
    }




    // console.log("CountryofCitizenship",CountryofCitizenship)
    // console.log("passportNum",passportNum)




    console.log("props.data.MobileverificationCode====", props)




    const NextStep = (val) => {
        if (val == 1) {
            setStep1(false)
            setStep2(true)
            setStep3(false)
            setStep4(false)
            setStep5(false)
            setStep6(false)
        } else if (val == 2) {
            setStep1(false)
            setStep2(false)
            setStep3(true)
            setStep4(false)
            setStep5(false)
            setStep6(false)
        } else if (val == 3) {
            setStep1(false)
            setStep2(false)
            setStep3(false)
            setStep4(true)
            setStep5(false)
            setStep6(false)
        } else if (val == 4) {
            setStep1(false)
            setStep2(false)
            setStep3(false)
            setStep4(false)
            setStep5(true)
            setStep6(false)
        } else if (val == 5) {

            setTimeout(() => {
                alert("your emaiverificationcode is" + " " + props.data.MobileverificationCode)

            }, 500);

            setStep1(false)
            setStep2(false)
            setStep3(false)
            setStep4(false)
            setStep5(false)
            setStep6(true)
        } else if (val == 6) {
            setStep1(false)
            setStep2(false)
            setStep3(false)
            setStep4(false)
            setStep5(false)
            setStep6(true)
        }
    }
    const PreviousStep = (val) => {
        if (val == 1) {
            setStep1(true);
            setStep2(false)
            setStep3(false)
            setStep4(false)
            setStep5(false)
            setStep6(false)
        } else if (val == 2) {
            setStep1(false)
            setStep2(true)
            setStep3(false)
            setStep4(false)
            setStep5(false)
            setStep6(false)
        } else if (val == 3) {
            setStep1(false)
            setStep2(true)
            setStep3(false)
            setStep4(false)
            setStep5(false)
            setStep6(false)
        } else if (val == 4) {
            setStep1(false)
            setStep2(false)
            setStep3(true)
            setStep4(false)
            setStep5(false)
            setStep6(false)
        } else if (val == 5) {
            setStep1(false)
            setStep2(false)
            setStep3(false)
            setStep4(true)
            setStep5(false)
            setStep6(false)
        }
        else if (val == 6) {
            setStep1(false)
            setStep2(false)
            setStep3(false)
            setStep4(false)
            setStep5(true)
            setStep6(false)
        }
    }
    const CurruntStep = (val) => {
        if (val == 1) {
            setStep1(true);
            setStep2(false)
            setStep3(false)
            setStep4(false)
            setStep5(false)
            setStep6(false)
        } else if (val == 2) {
            setStep1(false)
            setStep2(true)
            setStep3(false)
            setStep4(false)
            setStep5(false)
            setStep6(false)
        } else if (val == 3) {
            setStep1(false)
            setStep2(false)
            setStep3(true)
            setStep4(false)
            setStep5(false)
            setStep6(false)
        } else if (val == 4) {
            setStep1(false)
            setStep2(false)
            setStep3(false)
            setStep4(true)
            setStep5(false)
            setStep6(false)
        } else if (val == 5) {
            setStep1(false)
            setStep2(false)
            setStep3(false)
            setStep4(false)
            setStep5(true)
            setStep6(false)
        }
        else if (val == 6) {


            setStep1(false)
            setStep2(false)
            setStep3(false)
            setStep4(false)
            setStep5(false)
            setStep6(true)
        }
    }

    /// details


    return (
        <>
            {Loader ? <div class="loader"></div> : null}
            <div id="app">
                <Header />
                <div class="main-wrapper main-wrapper-1">
                    <div className="main-content">
                        <section className="section">
                            <ul className="breadcrumb breadcrumb-style ">
                                <li className="breadcrumb-item">
                                    <h4 className="page-title m-b-0">Profile</h4>
                                </li>

                                <li className="breadcrumb-item active">Profile</li>
                            </ul>

                            <form>
                                <div className="form-row text-center " style={{ display: "block" }}>


                                    <div className="row">
                                        <div className="col-md-12 ">
                                            <center>
                                                <div className="upload_photo_main">
                                                    <input
                                                        onChange={(e) => handleInputFileChange(e, setprofileimage)}
                                                        accept="image/*"
                                                        className={classes.input}
                                                        id="icon-button-file"
                                                        type="file"

                                                    />
                                                    <label htmlFor="icon-button-file">
                                                        <IconButton
                                                            color="#414141"
                                                            aria-label="upload picture"
                                                            component="span"
                                                            className="upload_photo_main"
                                                        >
                                                            {/* <img src={Selectedprofile ? Selectedprofile : `${image_url.image_url(profileimage)}`} style={{ minWidth: "120px", maxWidth: "120px", borderRadius: "20px" }} className="upload_photo_main" /> */}
                                                        </IconButton>
                                                    </label>
                                                </div>
                                            </center>
                                        </div>
                                    </div>

                                    {/* <div className="author-box-center">
                                            <img alt="image" src="assets/img/users/user-1.png" width="100px"   className="rounded-circle author-box-picture img-fluid" />
                                            <div className="clearfix" />


                                        </div> */}
                                </div>
                                <div className="form-row mt-4">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputFN"> Name</label>
                                        <input disabled type="text" value={lname} onChange={e => setLname(e.target.value)} className="form-control" id="inputFN" placeholder="Enter Your Name" />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputEmail">Email</label>
                                        <input value={email} disabled onChange={e => setEmail(e.target.value)} type="email" className="form-control" id="inputEmail" placeholder="Email " />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                    <label htmlFor="email">Business Location</label>
                                    <input  value={SelectedCountry} disabled  onSelect={(country) => { setSelectedCountry(country) }} type="email" className="form-control" id="inputEmail" placeholder="Email " />


                                    </div>
                                    <div className="form-group col-md-6">
                                    <label >Business Type</label>
                                                <input disabled value={EditBusinessType} onChange={(e) =>{SetEditBusinessType(e.target.value)}}  className="form-control" />

                                    </div>
                                </div>
                            </form>
                            <div className="card card-primary">
                                    <div className="card-header">
                                        <h4>Profile Details</h4>
                                    </div>
                                    <div className="card-body">
                                        {Step1 ?
                                            <>
                                                {/* <h3>Personal Information</h3> */}
                                                <form >
                                                    <div className="row">
                                                        <div className="col-md-12 mb-2 text-center">
                                                            <button onClick={() => CurruntStep(1)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary">Personal Information</button>
                                                            {/* <button disabled onClick={() => CurruntStep(2)} style={{ width: "20%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Marketplaces</button> */}
                                                            <button disabled onClick={() => CurruntStep(3)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Billing</button>
                                                            <button disabled onClick={() => CurruntStep(4)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Store</button>
                                                            {/* <button disabled onClick={() => CurruntStep(5)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Verification</button> */}
                                                            {/* <button disabled onClick={() => CurruntStep(6)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Confirmation</button> */}
                                                        </div>
                                                    </div>
                                                    <fieldset style={{ border: "2px solid rgb(212, 212, 212)", padding: "12px", boxShadow: " 0px 0px 20px #cdd3d8" }}>
                                                        <div className="form-group form-float">
                                                            <div className="form-line">
                                                                <label className="form-label"><b>Country of citizenship</b></label>
                                                           <input  value={CountryofCitizenship} disabled  onSelect={(country) => { SetCountryofCitizenship(country) }} type="email" className="form-control" id="inputEmail" placeholder="Email " />


                                                            </div>
                                                        </div>
                                                        <div className="form-group form-float">
                                                            <div className="form-line">
                                                                <label className="form-label"><b>Country of birth</b></label>
                                                           <input  value={CountryofBirth} disabled  onSelect={(country) => { SetCountryofBirth(country) }} type="text" className="form-control" id="inputEmail"  />


                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group form-float">
                                                                    <div className="form-line">
                                                                        <label className="form-label"><b>Proof of Identity</b></label>

                                                                        <input  disabled value={ProofofIdentity} onChange={(e) => SetProofofIdentity(e.target.value)} type ="text" className="form-control" />

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <label className="form-label"> &nbsp;</label>
                                                                <input disabled value={passportNum} onChange={(e) => setPassportnum(e.target.value)} placeholder="Enter your Passport/Id NO" type="number" className="form-control" />

                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                        <div className="col-md-6">

                                                        <div className="form-group form-float">
                                                            <div className="form-line">
                                                                <label className="form-label"><b>Country of issue</b></label>
                                                                <input  value={Countryofissue} disabled  onSelect={(country) =>SetCountryofissue(country)} type="text" className="form-control" />

                                                                {/* <Country
                                                                    value={Countryofissue}
                                                                    onSelect={(country) => {
                                                                        SetCountryofissue(country)
                                                                    }} /> */}
                                                            </div>
                                                        </div>
                                                        </div>
                                                        <div className="col-md-6">

                                                        <div className="form-group form-float">
                                                            <div className="form-line">
                                                                <label className="form-label"><b>Date Of Birth</b></label>
                                                                <input disabled value={DateofBirth} onChange={(e) => SetDateofBirth(e.target.value)}  placeholder="Enter Date Of Birth" type="text"  className="form-control" />

                                                            </div>
                                                        </div>
                                                        </div>
                                                        </div>
                                                        <hr />
                                                        <div className="form-group form-float">
                                                            <div className="form-line">
                                                                <label className="form-label"><b>Business Address</b></label>
                                                                <div className="alert alert-secondary" role="alert">
                                                                    {/* <h4 className="alert-heading">Well done!</h4> */}
                                                                    {/* <p>Aww yeah, you successfully read this important alert message. This example text is going to run a bit longer so that you can see how spacing within an alert works with this kind of content.</p> */}
                                                                    {/* <hr /> */}
                                                                    <div className="row">
                                                                        <div className="col-lg-1">
                                                                            <i style={{ fontSize: "55px" }} className="fas fa-exclamation-circle"></i>

                                                                        </div>
                                                                        <div className="col-md-11">
                                                                            <p className="mb-0" style={{ color: 'rgb(82, 80, 80)' }}> We will validate this address by sending you a postcard containing a varification code. To avoid any delays please enter your address in English characters only.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <input disabled value={addressLine1} onChange={(e) => setAddressline1(e.target.value)} placeholder="Address Line 1" type="text" className="form-control" />

                                                            </div>
                                                            <div className="col-md-6">
                                                                <input disabled value={addressLine2} onChange={(e) => setAddressline2(e.target.value)} placeholder="Address Line 2" type="text" className="form-control" />

                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-6">
                                                            <input  value={BusinessAddressCountry} disabled  onSelect={(country) =>SetBusinessAddressCountry(country)} type="text" className="form-control" />


                                                            </div>
                                                            <div className="col-md-6">
                                                                <input disabled value={streetNo} onChange={(e) => setStreetno(e.target.value)} placeholder="Street No" type="text" className="form-control" />

                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="row mt-2">
                                                            <div className="col-md-6">
                                                                <input disabled value={phoneNum} onChange={(e) => setPhonenum(e.target.value)} placeholder="Phone Number" type="number" className="form-control" placeholder="Enter Phone No" />

                                                            </div>
                                                            <div className="col-md-6">
                                                                <input disabled value={ZipCode} onChange={(e) => setZipCode(e.target.value)} placeholder="zip code" type="number" className="form-control" placeholder="Enter Zip Code" />

                                                            </div>
                                                        </div>
                                                        {/* <intlTelInput/> */}
                                                    </fieldset>
                                                    <div className="row">
                                                        <div className="col-md-12 pt-3 text-center">
                                                            <hr />
                                                            {/* <button className="btn btn-primary col-md-3 mx-2" onClick={() => PreviousStep(1)}>Previous</button> */}
                                                            <button className="btn btn-primary col-md-3 mx-2" type="button" onClick={() =>NextStep(1)}>Next</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </>
                                            : null
                                        }



                                        {Step2 ?
                                            <>
                                                {/* <h3>Billing</h3> */}
                                                <form >
                                                    <div className="row">
                                                        <div className="col-md-12 mb-2 text-center">
                                                            <button onClick={() => CurruntStep(1)} style={{ width: "16%", height: "50px", fontSize: "15px" }} className="btn btn-primary">Personal Information</button>
                                                            {/* <button onClick={() => CurruntStep(2)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Marketplaces</button> */}
                                                            <button onClick={() => CurruntStep(3)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Billing</button>
                                                            <button disabled onClick={() => CurruntStep(4)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Store</button>
                                                            {/* <button disabled onClick={() => CurruntStep(5)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Verification</button> */}
                                                            {/* <button disabled onClick={() => CurruntStep(6)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Confirmation</button> */}
                                                        </div>
                                                    </div>
                                                    <fieldset style={{ marginInline: "78px", border: "2px solid rgb(212, 212, 212)", padding: "12px", boxShadow: " 0px 0px 20px #cdd3d8" }}>
                                                        <div className="form-group form-float">
                                                            <div className="form-line">
                                                                {/* <label className="form-label">Username*</label>
                                                                                <input type="text" className="form-control" name="username" required /> */}
                                                                <h5 className="mb-0">Billing Information</h5>
                                                                <p className="text-muted" >Credit card details</p>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="form-group form-float">
                                                            <div className="form-line">
                                                                <p className="text-muted" style={{ fontSize: "15px" }}>We use credit card for account varification. A one time only one US dollar ($ 1) account varification charge will be applied to your credit card upon complition of account setup.</p>
                                                            </div>
                                                        </div>
                                                        <div className="row " id="SignupDetails">
                                                            <div className="col-md-12 text-center">

                                                                <Cards
                                                                    number={creditCardnum}
                                                                    name={cardHoldername}
                                                                    expiry={expiry}
                                                                    cvc={cvc}
                                                                    focused={Focus}
                                                                />
                                                            </div>
                                                        </div>


                                                        <div className="row">
                                                            <div className="col-md-6">

                                                                <div className="form-group form-float">
                                                                    <div className="form-line">
                                                                        <label className="form-label"><b>Credit card number</b></label>
                                                                        <input disabled value={creditCardnum} onChange={(e) => setCreditcardnum(e.target.value)} placeholder="Enter Credit Card Number" type="text" name="creditCardnum" className="form-control" />
                                                                        {/* <div>
                                                                            {Object.keys(creditCardnumErr).map((key) => {
                                                                                return <div style={{ color: "red" }}>{creditCardnumErr[key]}</div>
                                                                            })}
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">


                                                                <div className="form-group form-float">
                                                                    <div className="form-line">
                                                                        <label className="form-label"><b>Card holder's name</b></label>
                                                                        <input disabled value={cardHoldername} onChange={(e) => setCardholdername(e.target.value)} placeholder="Enter Card Holder Name" name="cardHoldername" type="text" className="form-control" />
                                                                        {/* <div>
                                                                            {Object.keys(cardHoldernameErr).map((key) => {
                                                                                return <div style={{ color: "red" }}>{cardHoldernameErr[key]}</div>
                                                                            })}
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group form-float">
                                                                    <div className="form-line">
                                                                        <label className="form-label"><b>Expiry Month </b></label>
                                                                        <input disabled value={expiry} onChange={(e) => setExpiry(e.target.value)}  name="month"  type="text" className="form-control" />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group form-float">
                                                                    <div className="form-line">
                                                                        <label className="form-label"><b>Expiry year</b></label>
                                                                        <input disabled value={expiryear} onChange={(e) => setexpiryear(e.target.value)}   name="year" type="text" className="form-control" />

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                        <div className="col-md-6">
                                                                <div className="form-group form-float">
                                                                    <div className="form-line">
                                                                        <label className="form-label"><b>Cvc</b></label>
                                                                        <input disabled value={cvc} onChange={(e) => setCvc(e.target.value)}  placeholder="Enter Cvc" name="cvc" type="text" className="form-control" />

                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12 ml-4">
                                                                <div className="form-group form-float">
                                                                    <div className="form-line">
                                                                        {/* <label className="form-label">Username*</label> */}
                                                                        <h5 className=" mb-0" >Billing Address</h5>
                                                                        {/* <div className="custom-control custom-checkbox mt-2 ml-2">
                                                                            <input type="checkbox" name="address" className="custom-control-input" tabIndex={3} id="address" />
                                                                            <label className="custom-control-label" htmlFor="address">14 Evergreen Terrace, New Brunswick, NJ, 08904, US</label>
                                                                        </div> */}
                                                                        {/* <a href="#" className="mt-2 ml-2">+ Add a new address</a> */}
                                                                        <p>
                                                                            <a className="mt-2 ml-2" data-toggle="collapse" href="#collapseExample" role="button"
                                                                                aria-expanded="false" aria-controls="collapseExample">
                                                                                + Add new billing address
                                                                            </a>
                                                                        </p>
                                                                        <div className="collapse" style={{ width: "98%" }} id="collapseExample">
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <input disabled value={Billing_Address} onChange={(e) => setBilling_Address(e.target.value)} placeholder="Address Line 1" type="text" className="form-control" />
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <input disabled value={Billing_Addres2} onChange={(e) => setBilling_Addres2(e.target.value)} placeholder="Address Line 2" type="text" className="form-control" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="row mt-2">
                                                                                <div className="col-md-6">
                                                            <input  value={Billing_Addres_Country} disabled  onSelect={(country) =>setBilling_Addres_Country(country)} type="text" className="form-control" />


                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <input disabled value={Billing_Addres_StreetNumber} onChange={(e) => setBilling_Addres_StreetNumber(e.target.value)} placeholder="Street No" type="text" className="form-control" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                    <div className="row">
                                                        <div className="col-md-12 pt-3 text-center">
                                                            <hr />
                                                            <button className="btn btn-primary col-md-3 mx-2" type="button" onClick={() => PreviousStep(1)}>Previous</button>
                                                            <button className="btn btn-primary col-md-3 mx-2" type="button" onClick={() =>NextStep(3)}>Next</button>
                                                        </div>
                                                    </div>
                                                </form>

                                            </>
                                            : null
                                        }
                                        {Step4 ?
                                            <>
                                                {/* <h3>Store</h3> */}
                                                <form >
                                                    <div className="row">
                                                        <div className="col-md-12 mb-2 text-center">
                                                            <button onClick={() => CurruntStep(1)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary">Personal Information</button>
                                                            {/* <button onClick={() => CurruntStep(2)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Marketplaces</button> */}
                                                            <button onClick={() => CurruntStep(3)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Billing</button>
                                                            <button onClick={() => CurruntStep(4)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Store</button>
                                                            {/* <button disabled onClick={() => CurruntStep(5)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Verification</button> */}
                                                            {/* <button disabled onClick={() => CurruntStep(6)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Confirmation</button> */}
                                                        </div>
                                                    </div>
                                                    <fieldset style={{ marginInline: "78px", border: "2px solid rgb(212, 212, 212)", padding: "12px", boxShadow: " 0px 0px 20px #cdd3d8" }}>
                                                        <div className="form-group form-float">
                                                            <div className="form-line">
                                                                {/* <label className="form-label">Username*</label>
                                                                                <input type="text" className="form-control" name="username" required /> */}
                                                                <h5 className="mb-0">Store Information</h5>
                                                                <p className="text-muted" >Store name and product information</p>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="form-group form-float">
                                                            <div className="form-line">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <label className="form-label"><b>Store name</b></label>
                                                                    </div>
                                                                    <div className="col-md-6 text-right">
                                                                        <i className="fas fa-check-circle text-success"></i>
                                                                        <label className="form-label"> Available</label>
                                                                        {/* <i className="fas fa-times-circle text-danger"></i>
                                                                                        <label className="form-label"> Not Available</label> */}
                                                                    </div>
                                                                </div>
                                                                <input disabled type="text" name="name" className="form-control" value={storeName} onChange={(e) => setStoreName(e.target.value)} />

                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div>
                                                            {/* <label className="form-label d-block"><b>Do you have Universal Product Codes (UPCs) for all yor products</b></label> */}
                                                            <div className="section-title">Do you have Universal Product Codes (UPCs) for all yor products</div>
                                                            <div className="custom-control custom-radio ml-2">
                                                                <input disabled value={"Yes"} onClick={e => SetUniversalProduct(e.target.value)} defaultChecked="Yes" type="radio" id="customRadio1" name="customRadio" className="custom-control-input" />
                                                                <label className="custom-control-label" htmlFor="customRadio1">Yes</label>
                                                            </div>
                                                            <div className="custom-control custom-radio ml-2">
                                                                <input disabled value={"No"} onClick={e => SetUniversalProduct(e.target.value)} type="radio"  defaultChecked="Yes" id="customRadio2" name="customRadio" className="custom-control-input" />
                                                                <label className="custom-control-label" htmlFor="customRadio2">No</label>
                                                            </div>
                                                            {/* <label className="form-label d-block"><b>Are you manufacturer or brand owner (or agent or representative of the brand) for any of the products you want to sell on Babylon?</b></label> */}
                                                            <div className="section-title">Are you manufacturer or brand owner (or agent or representative of the brand) for any of the products you want to sell on Babylon?</div>
                                                            <div className="custom-control custom-radio ml-2">
                                                                <input disabled value={"Yes"} onClick={e => SetBrandowner(e.target.value)} defaultChecked="Yes" type="radio" id="customRadio3" name="customRadio1" className="custom-control-input" />
                                                                <label className="custom-control-label" htmlFor="customRadio3">Yes</label>
                                                            </div>
                                                            <div className="custom-control custom-radio ml-2">
                                                                <input disabled value={"No"} onClick={e => SetBrandowner(e.target.value)} defaultChecked="No" type="radio" id="customRadio4" name="customRadio1" className="custom-control-input" />
                                                                <label className="custom-control-label" htmlFor="customRadio4">No</label>
                                                            </div>
                                                            {/* <label className="form-label d-block"><b>Do you own government-registered trademark for the branded products you want to sell on Babylon?</b></label> */}
                                                            <div className="section-title">Do you own government-registered trademark for the branded products you want to sell on Babylon?</div>
                                                            <div className="custom-control custom-radio ml-2">
                                                                <input disabled value={"Yes"} onClick={e => SetGovernmentRegistered(e.target.value)} defaultChecked="Yes" type="radio" id="customRadio5" name="customRadio2" className="custom-control-input" />
                                                                <label className="custom-control-label" htmlFor="customRadio5">Yes</label>
                                                            </div>
                                                            <div className="custom-control custom-radio ml-2">
                                                                <input disabled value={"No"} onClick={e => SetGovernmentRegistered(e.target.value)} defaultChecked="No" type="radio" id="customRadio6" name="customRadio2" className="custom-control-input" />
                                                                <label className="custom-control-label" htmlFor="customRadio6">No</label>
                                                            </div>
                                                            <div className="custom-control custom-radio ml-2">
                                                                <input disabled value={"Someofthem"} onClick={e => SetGovernmentRegistered(e.target.value)} defaultChecked="Someofthem" type="radio" id="customRadio7" name="customRadio2" className="custom-control-input" />
                                                                <label className="custom-control-label" htmlFor="customRadio7">Some of them</label>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                    <div className="row">
                                                        <div className="col-md-12 pt-3 text-center">
                                                            <hr />
                                                            <button className="btn btn-primary col-md-3 mx-2" onClick={() => PreviousStep(2)}>Previous</button>
                                                            {/* <button className="btn btn-primary col-md-3 mx-2" type="button"  >Finish</button> */}
                                                        </div>
                                                    </div>
                                                </form>
                                            </> : null}

                                        {/* {Step5 ?
                                            <>
                                                {/* <h3>varification</h3> */}
                                                {/* <div className="row">
                                                    <div className="col-md-12 mb-2 text-center">
                                                        <button onClick={() => CurruntStep(1)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary">Personal Information</button>
                                                        {/* <button onClick={() => CurruntStep(2)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Marketplaces</button> */}
                                                        {/* <button onClick={() => CurruntStep(3)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Billing</button> */}
                                                        {/* <button onClick={() => CurruntStep(4)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Store</button> */}
                                                        {/* <button onClick={() => CurruntStep(5)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Verification</button> */}
                                                        {/* <button disabled onClick={() => CurruntStep(6)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Confirmation</button> */}
                                                    {/* </div> */}
                                                {/* </div>  */}
                                                {/* <fieldset style={{ marginInline: "78px", border: "2px solid rgb(212, 212, 212)", padding: "12px", boxShadow: " 0px 0px 20px #cdd3d8" }}>
                                                    <div className="form-group form-float">
                                                        <div className="form-line">
                                                            <h5 className="text-center mb-0">Address Validation</h5>
                                                            <hr />
                                                            <p className="text-muted">A postcard containing a code will bemailed to the adress mentioned below. please confirm the address to receive the postcard. Once you confirm the address it will take 7 days for the mail to arrive. In case you want to change the address than click on previous.</p>
                                                            <h5 className="mb-0" >{addressLine1} {streetNo}</h5>
                                                            <p className="text-muted mb-0">{BusinessAddressCountry}</p>
                                                            <p className="text-muted">{phoneNum}</p>
                                                        </div>
                                                    </div>
                                                </fieldset> */}
                                                {/* <div className="row">
                                                    <div className="col-md-12 pt-3 text-center">
                                                        <hr />
                                                        <button className="btn btn-primary col-md-3 mx-2" onClick={() => PreviousStep(5)}>Previous</button>
                                                        <button className="btn btn-primary col-md-3 mx-2">update</button>
                                                    </div>
                                                </div>
                                            </>
                                            : null} */}
                                        {/* {Step6 ? */}
                                            {/* <> */}
                                                {/* <h3>Confirmation</h3> */}
                                                {/* <form>
                                                    <div className="row">
                                                        <div className="col-md-12 mb-2 text-center">
                                                            <button onClick={() => CurruntStep(1)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary">Personal Information</button>
                                                            {/* <button onClick={() => CurruntStep(2)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Marketplaces</button> */}
                                                            {/* <button onClick={() => CurruntStep(3)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Billing</button>
                                                            <button onClick={() => CurruntStep(4)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Store</button>
                                                            <button onClick={() => CurruntStep(5)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Verification</button>
                                                            <button onClick={() => CurruntStep(6)} style={{ width: "14%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Confirmation</button>
                                                        </div>
                                                    </div>
                                                    <fieldset style={{ marginInline: "78px", border: "2px solid rgb(212, 212, 212)", padding: "12px", boxShadow: " 0px 0px 20px #cdd3d8" }}>

                                                        <div className="form-group form-float">
                                                            <div className="form-line">
                                                                <h5 className="text-center mb-0">Address Validation</h5>
                                                                <hr />
                                                                <p className="text-muted">A postcard with your code was mailed to the address mentioned below. It takes 7 days for the mail to arrive and you can track the status below.</p>
                                                                <h5 className="mb-0">{addressLine1} {streetNo}</h5>
                                                                <p className="text-muted mb-0">{BusinessAddressCountry}</p>
                                                                <p className="text-muted">{phoneNum}</p>
                                                                <h5 className="text-danger mb-0">Preparing</h5>
                                                                <h5 className="d-inline mb-0">Address Validation</h5>
                                                                <p className="text-success d-inline mb-0"> 15-May-2020</p>
                                                            </div>
                                                        </div>
                                                        <div className="form-group form-float">
                                                            <div className="form-line">
                                                                <div className="row">
                                                                    <div className="col-md-3">
                                                                        <label className="form-label text-info"><b>Enter code below</b></label>
                                                                        <input type="number" className="form-control" value={confirmationCode} onChange={(e) => setConfirmationcode(e.target.value)} name="OTP" />
                                                                        {Ctrue ?
                                                                            <div>
                                                                                {Object.keys(confirmationCodeErr).map((key) => {
                                                                                    return <div style={{ color: "green" }}>{confirmationCodeErr[key]}</div>
                                                                                })}
                                                                            </div>
                                                                            :
                                                                            <div>
                                                                                {Object.keys(confirmationCodeErr).map((key) => {
                                                                                    return <div style={{ color: "red" }}>{confirmationCodeErr[key]}</div>
                                                                                })}
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </fieldset>
                                                    <div className="row">
                                                        <div className="col-md-12 pt-3 text-center">
                                                            <hr />
                                                            <button className="btn btn-primary col-md-3 mx-2" onClick={() => PreviousStep(6)}>Previous</button>

                                                            <button className="btn btn-primary col-md-3 mx-2" type="submit" >Finish</button>
                                                        </div>
                                                    </div> */}
                                                {/* </form> */}
                                            {/* </> */}
                                            {/* : null} */}
                                    </div>
                                </div>
                        </section>

                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    data: state.Register

})

const mapDispatchToProps = {
    checkEmailorPhone,
    setValue
}



export default connect(mapStateToProps, mapDispatchToProps)(Profile)