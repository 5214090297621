import React, { useState, useEffect, useRef } from "react"
import Footer from '../../../components/footer/footer'
import { useHistory } from "react-router"
import Header from '../../../components/header/header'
import './newAddproduct.css'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import Swal from 'sweetalert2';
import BaseUrl from "../../../../../src/config/BaseUrl"
import { getApi, getApiWithToken, PutApi, PostApi, DeleteApi } from "../../../../config/Api/FakeuserApi";
import { Typeahead, TypeaheadMenu } from 'react-bootstrap-typeahead';
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import LinearProgressWithLabel from './imageLoader';
import { Link } from "react-router-dom"
import { style } from "@mui/system"


const mystyle = {
   float: "right",
   marginTop:"30px"
  };


export default function NewAddProduct() {

    const history = useHistory();
    const [disabledate,Setdisabledate ] = useState("");

    // -------- Wizard Navigator States --------

    const [Step1, setStep1] = useState(true)
    const [Step2, setStep2] = useState(false)
    const [Step3, setStep3] = useState(false)
    const [Step4, setStep4] = useState(false)
    const [Loader, setLoader] = useState(false)
    const [imgLoader, setImgLoader] = useState(false);
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const [Available, setAvailable] = useState(true);
    const [BrandName, setBrandName] = useState("");
      // CONFIRMATION CODE VALIDATION
      const [confirmationCode, setConfirmationcode] = useState("");
      const [confirmationCodeErr, setConfirmationcodeErr] = useState({});
      const [Ctrue, setCtrue] = useState(false);
      const [ApiErr, setApiErr] = useState(false);




    // const handleInputChange = (event, func) => {
    //     // const array1 = [{shopName:"J.A Desire"}, {shopName:"K.A Store"}, "Walls", "Omore", 44];

    //     const found = BrandName.find(element => element.BrandName == event.target.value);
    //     console.log("found", found);

    //     if (found?.BrandName === event.target.value) {
    //         setAvailable(false);
    //         func("alreadyexist")
    //         // Swal.fire({
    //         //     title: "Opps",
    //         //     text: "Store Name alredy exist",
    //         //     icon: "error"
    //         // })
    //     } else {
    //         setAvailable(true);
    //         func(event.target.value)
    //     }

    // }


    //get brand names

    const confirmationCodeErr2 = {};
    const BrandRequest = () => {
        const isValid = formValidationConf();
        if (isValid == true) {
        setLoader(true);
        var formdata = new FormData();
        formdata.append("BrandName", BrandName);


        PostApi(`${BaseUrl.base_url}/RequestBrand`, formdata, Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    // console.log("dataBrand", data);
                    // setBrandName(Data)
                    Swal.fire({
                        title: "Success",
                        text: data.message,
                        icon: "success"
                    })
                    BrandNames()
                    handleClose2()
                    setBrandName(" ")
                    setConfirmationcodeErr("")


                } else {
                    // $(".loader").hide();
                    // setApiErr(true)
                    formValidationConf("alreadyExist")
                    // Swal.fire({
                    //     title: "Opps",
                    //     text: data.message,
                    //     icon: "error"
                    // })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })
        }else{
                formValidationConf()
            }
        }









    const formValidationConf = (status) => {
        // setApiErr(false)


        let isValid = true;

        if (BrandName === "") {
            confirmationCodeErr2.confirmationCodenull = "Enter Brand Name";
            isValid = false;
            setConfirmationcodeErr(confirmationCodeErr2);
            setCtrue(false);
            return isValid;
        }
        else if (status == "alreadyExist" ) {
            confirmationCodeErr2.confirmationCodenull = "already exist";
            isValid = false;
            setConfirmationcodeErr(confirmationCodeErr2);
            setCtrue(false);
            return isValid;
        }
        else {
            confirmationCodeErr2.confirmationCodenull = "Success";
            isValid = true;
            setConfirmationcodeErr(confirmationCodeErr2);
            setCtrue(true);
            return isValid;
        }



    }



    const Token = localStorage.getItem('SellerToken');

    // ============== BASIC INFO ==============

    const [productName, setProductName] = useState("");
    const [productNameErr, setProductNameErr] = useState({});
    const [brandErr, setbrandErr] = useState({});


    const [VideoUrl, SetVideoUrl] = useState("")
    const [BrandId, SetBrandId] = useState("")

    // --------- Category Suggestion States ----------

    const [showCategory, setShowCategory] = useState("");
    const [categories, setCategories] = useState([]);
    const [categoriesId, setCategoriesId] = useState([]);

    const [selectCateAttr, setSelectCateAttr] = useState(false);
    const [categoryChecker1, setCategoryChecker1] = useState(false);
    const [categoryChecker2, setCategoryChecker2] = useState(false);
    const [categoryChecker3, setCategoryChecker3] = useState(false);
    const [categoryChecker4, setCategoryChecker4] = useState(false);
    const [categoryChecker5, setCategoryChecker5] = useState(false);
    const [categoryChecker6, setCategoryChecker6] = useState(false);
    const [categoryChecker7, setCategoryChecker7] = useState(false);
    const [categoryChecker8, setCategoryChecker8] = useState(false);

    const [showDropdown, setShowDropdown] = useState(false);
    const [showSubDropdown1, setShowSubDropdown1] = useState(false);
    const [showSubDropdown2, setShowSubDropdown2] = useState(false);
    const [showSubDropdown3, setShowSubDropdown3] = useState(false);
    const [showSubDropdown4, setShowSubDropdown4] = useState(false);
    const [showSubDropdown5, setShowSubDropdown5] = useState(false);
    const [showSubDropdown6, setShowSubDropdown6] = useState(false);
    const [showSubDropdown7, setShowSubDropdown7] = useState(false);
    const [showSubDropdown8, setShowSubDropdown8] = useState(false);

    const [mainCategory, setMainCategory] = useState([]);
    // console.log("Main Categories => ",mainCategory)
    const [subCategory1, setSubCategory1] = useState([]);
    // console.log("Sub Categories 1 => ",subCategory1)
    const [subCategory2, setSubCategory2] = useState([]);
    // console.log("Sub Categories 2 => ",subCategory2)
    const [subCategory3, setSubCategory3] = useState([]);
    const [subCategory4, setSubCategory4] = useState([]);
    const [subCategory5, setSubCategory5] = useState([]);
    const [subCategory6, setSubCategory6] = useState([]);
    const [subCategory7, setSubCategory7] = useState([]);
    const [subCategory8, setSubCategory8] = useState([]);

    const [showCategoryErr, setShowCategoryErr] = useState({});
    const [container, setContainer] = useState(false);
    const [cateSelection, setCateSelection] = useState(false);
    // const [brandsname, setbrandsname] = useState([ "shakeeeb","hassan","safdar" ]);


    var selectCate;

    useEffect(() => {
        var dateObj = new Date();
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
        if(month < 10){
        month = '0' + month.toString();
        }
        var day = dateObj.getUTCDate();
        var year = dateObj.getUTCFullYear();
      var  newdate = year + "-" + month + "-" + day;
    //   console.log("mewdate",newdate)
      Setdisabledate(newdate)

        BrandNames()
    }, [])

    // Not Null Validation fn:

    const onSubmitBasicinfo = (e) => {
        e.preventDefault();
        const isValid = formValidationBasicinfo();
        if (isValid == true) {
            NextStep(1);
        }
        // console.log(productName);
    }

    const formValidationBasicinfo = () => {
        const productNameErr = {};
        const brandErr = {};
        const showCategoryErr = {};

        let isValid = true;

        if (productName.trim().length == 0) {
            productNameErr.productNameNull = "Product name is required";
            isValid = false;
        }
        if (showCategory.trim().length == "") {
            showCategoryErr.categoryNull = "Category required";
            isValid = false;
        }

        if (brandName == "") {
            brandErr.categorNull = "Brand Required";
            isValid = false;
            // setbrandErr(brandErr);
        }

        setProductNameErr(productNameErr);
        setShowCategoryErr(showCategoryErr);
        setbrandErr(brandErr);


        return isValid;
    }

    // --------- Category Suggestion API ---------

    // On hover Suggestion:

    function MouseOver(event) {
        event.target.style.cursor = "pointer";
    }

    // Main Category Suggestion API:


    const showSuggestions = (e) => {

        if (e != "") {
            setContainer(true);
            getApi(`${BaseUrl.base_url}/GetCategories`, "")
                .then((data) => {
                    let cateData = data.data.data;
                    setMainCategory(cateData);
                    // console.log("category Data ==>",mainCategory)
                }).catch((e) => {
                    console.log("error===>", e)
                })
            setShowDropdown(true);
            setSelectCateAttr(true);
        }
        if (e == "") {
            setContainer(false)
            setShowDropdown(false);
            setShowSubDropdown1(false);
            setShowSubDropdown2(false);
            setShowSubDropdown3(false);
            setShowSubDropdown4(false);
            setSelectCateAttr(false);
        }
    }

    // Sub Category Suggestion API:

    const showSubCategory0 = (name, index) => {
        console.log("main==================",name)
        categoryClear()

        mainCategory.map((v, i) => {
            (v.CategoryName != name) ?
                document.getElementById(v.CategoryName).style.backgroundColor = "#f2ebeb" :
                document.getElementById(v.CategoryName).style.backgroundColor = "lightblue"
            })
            console.log("name =>", name)

            setCateSelection(true)
            setCategories([name.CategoryName])
            setCategoriesId(name.id)
            console.log("name.id==>",name.id)

            // console.log("index =>", index)

        getApi(`${BaseUrl.base_url}/GetSubcategories?categoryName=${name.CategoryName}`, "")
            .then((data) => {
                // console.log("if================--------------0  ",cateData[0].myCategory)
                    let targetFound = document.getElementById(name.CategoryName);
                    console.log("targetFound 0 =>", targetFound)
                    targetFound.style.backgroundColor = "lightblue";

                let cateData = data.data.data;
                console.log("cateData00",cateData)
                if (cateData.length > 0){

                if (cateData[0].myCategory !== undefined) {
                    console.log("if================--------------0  ",cateData[0].myCategory)
                    let targetFound = document.getElementById(name.CategoryName);
                    console.log("targetFound 0 =>", targetFound)
                    targetFound.style.backgroundColor = "lightblue";
                    setCategoryChecker1(true);
                    setSubCategory1(cateData);
 }

                else {

                    // categoryClear()
                    setCategoryChecker1(false);
                    setCategoryChecker2(false);
                }
            }
            }).catch((e) => {
                let targetFound = document.getElementById(name.CategoryName);
                    targetFound.style.backgroundColor = "lightblue";
                console.log("error===> 1", e)
            })
        setShowSubDropdown1(true);
        console.log(subCategory1);
    }
    const showSubCategory1 = (name, index) => {


        subCategory1.map((v, i) => {
            (v.myCategory != name) ?
                document.getElementById(v.myCategory).style.backgroundColor = "#f2ebeb" :
                document.getElementById(v.myCategory).style.backgroundColor = "lightblue"
        })

        let cateList = [...categories]
        // console.log("categories22==>",[...categories])
        cateList[1] = name.myCategory
        setCategories(cateList)
        console.log("categoriesId",categoriesId)
        let cateID = [categoriesId]
        console.log("cateID1",cateID)
        cateID[1] = name.id
        setCategoriesId(cateID)
        setCateSelection(true)
        getApi(`${BaseUrl.base_url}/GetSubcategories?categoryName=${name.myCategory}`, "")

            .then((data) => {
                let targetFound = document.getElementById(name.myCategory);
                targetFound.style.backgroundColor = "lightblue";

                let cateData = data.data.data;
                if(cateData.length > 0){




                console.log("data11 ====> ", cateData);
                if (cateData[0].myCategory !== undefined) {
                    let targetFound = document.getElementById(name.myCategory);
                    console.log("targetFound 1 =>", targetFound)
                    targetFound.style.backgroundColor = "lightblue";
                    setCategoryChecker2(true);
                    setSubCategory2(cateData);
                }
                else {

                    setCategoryChecker2(false);
                }
            }
            }).catch((e) => {
                let targetFound = document.getElementById(name.myCategory);
                    targetFound.style.backgroundColor = "lightblue";
                console.log("error===>", e)
            })
        setShowSubDropdown2(true);
        console.log(subCategory1);
    }
    const showSubCategory2 = (name, index) => {
        subCategory2.map((v, i) => {
            (v.myCategory != name) ?
                document.getElementById(v.myCategory).style.backgroundColor = "#f2ebeb" :
                document.getElementById(v.myCategory).style.backgroundColor = "lightblue"
        })

        let cateList = [...categories]
        cateList[2] = name.myCategory
        setCategories(cateList)
        let cateID = categoriesId
        console.log("cateID2",cateID)
        cateID[2] = name.id
        setCategoriesId(cateID)
        setCateSelection(true)
        getApi(`${BaseUrl.base_url}/GetSubcategories?categoryName=${name.myCategory}`, "")
            .then((data) => {
                let targetFound = document.getElementById(name.myCategory);
                targetFound.style.backgroundColor = "lightblue";
                let cateData = data.data.data;
                if (cateData.length > 0) {




                if (cateData[0].myCategory !== undefined) {
                    let targetFound = document.getElementById(name.myCategory);
                    targetFound.style.backgroundColor = "lightblue";
                    console.log("targetFound 2 =>", targetFound)
                    setCategoryChecker3(true);
                    setSubCategory3(cateData);
                }
                else {
                    setCategoryChecker3(false);
                }  }
            }).catch((e) => {
                let targetFound = document.getElementById(name.myCategory);
                    targetFound.style.backgroundColor = "lightblue";
                console.log("error===>", e)
            })
        setShowSubDropdown3(true);
        console.log(subCategory1);
    }
    const showSubCategory3 = (name, index) => {
        subCategory3.map((v, i) => {
            (v.myCategory != name) ?
                document.getElementById(v.myCategory).style.backgroundColor = "#f2ebeb" :
                document.getElementById(v.myCategory).style.backgroundColor = "lightblue"
        })
        let cateList = [...categories]
        cateList[3] = name.myCategory
        setCategories(cateList)
        let cateID = categoriesId
        console.log("cateID3",cateID)

        cateID[3] = name.id
        setCategoriesId(cateID)
        setCateSelection(true)
        getApi(`${BaseUrl.base_url}/GetSubcategories?categoryName=${name.myCategory}`, "")
            .then((data) => {
                let targetFound = document.getElementById(name.myCategory);
                targetFound.style.backgroundColor = "lightblue";
                let cateData = data.data.data;
                if(cateData.length > 0) {




                if (cateData[0].myCategory !== undefined) {
                    let targetFound = document.getElementById(name.myCategory);
                    targetFound.style.backgroundColor = "lightblue";
                    console.log("targetFound 3 =>", targetFound)
                    setCategoryChecker4(true);
                    setSubCategory4(cateData);
                }
                else {
                    setCategoryChecker4(false);
                }  }

            }).catch((e) => {
                let targetFound = document.getElementById(name.myCategory);
                    console.log("targetFound 1 =>", targetFound)
                    targetFound.style.backgroundColor = "lightblue";
                console.log("error===>", e)
            })
        setShowSubDropdown4(true);
        console.log(subCategory1);
    }
    const showSubCategory4 = (name, index) => {
        subCategory4.map((v, i) => {
            (v.myCategory != name) ?
                document.getElementById(v.myCategory).style.backgroundColor = "#f2ebeb" :
                document.getElementById(v.myCategory).style.backgroundColor = "lightblue"
        })
        let cateList = [...categories]
        cateList[4] = name.myCategory
        setCategories(cateList)
        let cateID = categoriesId
        cateID[4] = name.id
       setCategoriesId(cateID)
        setCateSelection(true)
        getApi(`${BaseUrl.base_url}/GetSubcategories?categoryName=${name.myCategory}`, "")
            .then((data) => {
                let targetFound = document.getElementById(name.myCategory);
                targetFound.style.backgroundColor = "lightblue";
                let cateData = data.data.data;

                if(cateData.length > 0){



                if (cateData[0].myCategory !== undefined) {
                    let targetFound = document.getElementById(name.myCategory);
                    targetFound.style.backgroundColor = "lightblue";
                    console.log("targetFound 4 =>", targetFound)
                    setCategoryChecker5(true);
                    setSubCategory5(cateData);
                }
                else {
                    setCategoryChecker5(false);
                }  }
            }).catch((e) => {
                let targetFound = document.getElementById(name.myCategory);
                    console.log("targetFound 1 =>", targetFound)
                    targetFound.style.backgroundColor = "lightblue";
                console.log("error===>", e)
            })
        setShowSubDropdown5(true);
        console.log(subCategory1);
    }
    const showSubCategory5 = (name, index) => {
        subCategory5.map((v, i) => {
            (v.myCategory != name) ?
                document.getElementById(v.myCategory).style.backgroundColor = "#f2ebeb" :
                document.getElementById(v.myCategory).style.backgroundColor = "lightblue"
        })
        let cateList = [...categories]
        cateList[5] = name.myCategory
        setCategories(cateList)
        let cateID = categoriesId
        cateID[5] = name.id
        setCategoriesId(cateID)
        setCateSelection(true)
        getApi(`${BaseUrl.base_url}/GetSubcategories?categoryName=${name.myCategory}`, "")
            .then((data) => {
                let targetFound = document.getElementById(name.myCategory);
                targetFound.style.backgroundColor = "lightblue";
                let cateData = data.data.data;
                if(cateData.length > 0 ){




                if (cateData[0].myCategory !== undefined) {
                    let targetFound = document.getElementById(name.myCategory);
                    console.log("targetFound 5 =>", targetFound)
                    targetFound.style.backgroundColor = "lightblue";
                    setCategoryChecker6(true);
                    setSubCategory6(cateData);
                }
                else {
                    setCategoryChecker6(false);
                }  }
            }).catch((e) => {
                let targetFound = document.getElementById(name.myCategory);
                    targetFound.style.backgroundColor = "lightblue";


            })
        setShowSubDropdown6(true);
        console.log(subCategory1);
    }
    const showSubCategory6 = (name, index) => {
        subCategory6.map((v, i) => {
            (v.myCategory != name) ?
                document.getElementById(v.myCategory).style.backgroundColor = "#f2ebeb" :
                document.getElementById(v.myCategory).style.backgroundColor = "lightblue"
        })
        let cateList = [...categories]
        cateList[6] = name.myCategory
        setCategories(cateList)
        let cateID = categoriesId
        cateID[6] = name.id
        setCategoriesId(cateID)
        setCateSelection(true)
        getApi(`${BaseUrl.base_url}/GetSubcategories?categoryName=${name.myCategory}`, "")
            .then((data) => {
                let targetFound = document.getElementById(name.myCategory);
                targetFound.style.backgroundColor = "lightblue";
                let cateData = data.data.data;
                if (cateData.length > 0){




                if (cateData[0].myCategory !== undefined) {
                    let targetFound = document.getElementById(name.myCategory);
                    targetFound.style.backgroundColor = "lightblue";
                    console.log("targetFound 6 =>", targetFound)
                    setCategoryChecker7(true);
                    setSubCategory7(cateData);
                }
                else {

                    console.log("elsee===>")
                    setCategoryChecker7(false);
                }  }
            }).catch((e) => {
                let targetFound = document.getElementById(name.myCategory);
                    targetFound.style.backgroundColor = "lightblue";
                console.log("error===>", e)
            })
        setShowSubDropdown7(true);
        console.log(subCategory1);
    }
    const showSubCategory7 = (name, index) => {
        subCategory7.map((v, i) => {
            (v.myCategory != name) ?
                document.getElementById(v.myCategory).style.backgroundColor = "#f2ebeb" :
                document.getElementById(v.myCategory).style.backgroundColor = "lightblue"
        })
        let cateList = [...categories]
        cateList[7] = name.myCategory
        setCategories(cateList)
        let cateID = categoriesId
        cateID[7] = name.id
        setCategoriesId(cateID)
        setCateSelection(true)
        getApi(`${BaseUrl.base_url}/GetSubcategories?categoryName=${name.myCategory}`, "")
            .then((data) => {
                let targetFound = document.getElementById(name.myCategory);
                targetFound.style.backgroundColor = "lightblue";
                let cateData = data.data.data;
                if (cateData.length > 0){



                if (cateData[0].myCategory !== undefined) {
                    let targetFound = document.getElementById(name.myCategory);
                    targetFound.style.backgroundColor = "lightblue";
                    console.log("targetFound 7 =>", targetFound)
                    setCategoryChecker8(true);
                    setSubCategory8(cateData);
                }
                else {

                    setCategoryChecker8(false);
                }  }
            }).catch((e) => {
                let targetFound = document.getElementById(name.myCategory);
                    console.log("targetFound 1 =>", targetFound)
                    targetFound.style.backgroundColor = "lightblue";
                console.log("error===>", e)
            })
        setShowSubDropdown8(true);
        console.log(subCategory1);
    }


    // Confirm btn fn:

    const showFinalCategory = () => {
        let cateSlashFor = ""  ;
        categories.map((v, i) => cateSlashFor += `${v}/`)
        setShowCategory(cateSlashFor)
        // setShowCategory(`${selectCate[0][0].myCategory} / ${selectCate[1][0].myCategory} / ${selectCate[2][0].myCategory}`)
        // setSelectedCateId([selectCate[0][0].id, selectCate[1][0].id, selectCate[2][0].id]);
        // setFirstCateId(selectCate[0][0].id)
        setContainer(false);
    }

    // Confirm btn fn:

    const categoryClear = () => {
        mainCategory.map((v, i) => {
            document.getElementById(v.CategoryName).style.backgroundColor = "#f2ebeb"
        })
        setCateSelection(false);
        setCategoryChecker1(false);
        setCategoryChecker2(false);
        setCategoryChecker3(false);
        setCategoryChecker4(false);
        setCategoryChecker5(false);
        setCategoryChecker6(false);
        setCategoryChecker7(false);
        setCategoryChecker8(false);
    }


    // useEffect(()=>{
    //     console.log("categoryId useEffect", categoryId)
    // }, [JSON.stringify(categoryId)])
    // const setCategoryContent = (e) => {
    //     console.log(e)
    // }

    // ============== DETAILED DES ==============

    const [ShortDescription, SetShortDescription] = useState("")
    const [LongDescription, SetLongDescription] = useState("")
    const [EnglishDescription, SetEnglishDescription] = useState("")
    const [WhatsintheBox, SetWhatsintheBox] = useState("")

    const [creditCardnum, setCreditcardnum] = useState("");
    const [expiryMonth, setExpirymonth] = useState(0);
    const [expiryYear, setExpiryear] = useState(0);
    const [cardHoldername, setCardholdername] = useState("");

    const [creditCardnumErr, setCreditcardnumErr] = useState({});
    const [expiryMonthErr, setExpirymonthErr] = useState({});
    const [expiryYearErr, setExpiryearErr] = useState({});
    const [cardHoldernameErr, setCardholdernameErr] = useState({});

    const [editorState1, setEditorState1] = useState(
        () => EditorState.createEmpty()
    );
    const [editorState2, setEditorState2] = useState(
        () => EditorState.createEmpty()
    );
    const [editorState3, setEditorState3] = useState(
        () => EditorState.createEmpty(),
    );
    const [editorState1Err, seteditorState1Err] = useState({});
    const [editorState2Err, seteditorState2Err] = useState({});
    const [editorState3Err, seteditorState3Err] = useState({});
    const [BoxErr, setBoxErr] = useState({});



    // Not Null Validation fn:

    const onSubmitDesinfo = (e) => {
        e.preventDefault();
        const isValid = formValidationDesinfo();
        if (isValid == true) {

            // NextStep(3);
        }
        console.log(" NextStep(2) working")
    }



    const formValidationDesinfo = () => {
        const editorState1Err = {};
        const editorState2Err = {};
        const editorState3Err = {};
        const BoxErr = {};



        let isValid = true;

        if (editorState1 == 0) {
            editorState1Err.creditCardNumnull = "Short Description Required";
            isValid = false;
        }
        if (editorState2 == 0) {
            editorState2Err.expiryMonthNull = "Long Description Required";
            isValid = false;
        }
        if (editorState3 == 0) {
            editorState3Err.expiryYearNull = "English Description Required";
            isValid = false;
        }
        if (cardHoldername.trim().length == 0) {
            BoxErr.cardHolderNameNull = "Please enter a Card holder name";
            isValid = false;
        }


        seteditorState1Err(editorState1Err);
        seteditorState2Err(editorState2Err);
        seteditorState3Err(editorState3Err);
        setCardholdernameErr(cardHoldernameErr);

        return isValid;
    }

    // Set Editor Content:

    const onEditorStateChange1 = (e) => {
        setEditorState1(e);
    }
    const onEditorStateChange2 = (e) => {
        setEditorState2(e);
    }
    const onEditorStateChange3 = (e) => {
        setEditorState3(e);
    }

    var shortDes = draftToHtml(convertToRaw(editorState1.getCurrentContent()))
    var longDes = draftToHtml(convertToRaw(editorState2.getCurrentContent()))
    var engDes = draftToHtml(convertToRaw(editorState3.getCurrentContent()))

    // console.log("shortDes =>", shortDes)

    // ============== PRICE & STOCK ==============


    const [selectedFiles, setSelectedFiles] = useState([]);
    // const [options, setoptions] = useState(["safdar","shakeeb"]);

    // console.log("selectedFiles =>", selectedFiles)

    // Make Array of Selected Images URL fn:

    const [verifyImgObj, setVerifyImgObj] = useState([]);
    console.log("verifyImgObj", verifyImgObj);
    var verifyImg = [];
    var rejectedImg = [];

    const handleImageChange = async (e) => {
        // setLoader(true);
        setImgLoader(true);
        var formdata = new FormData();
        var filesArray = [];
        setVerifyImgObj(e.target.files)
        if (e.target.files) {
            console.log("e.target.files =>", e.target.files)
            for (var i = 0; i < e.target.files.length; i++) {
                formdata.append("image", e.target.files.item(i));
                // console.log("formdata", formdata);
                await PostApi(`${BaseUrl.base_url}/CheckImage`, formdata, Token)
                    .then(({ data }) => {
                        if (data.status) {
                            console.log(data.status);
                            verifyImg.push(e.target.files.item(i))
                            console.log("verifyImg =>", verifyImg)
                            filesArray = Array.from(verifyImg).map((file) => URL.createObjectURL(file));
                        }
                        else {
                            console.log(data.status);
                            rejectedImg.push(e.target.files[i])
                            console.log("Rejected Img =>", rejectedImg)
                        }
                    }
                    ).catch((e) => {
                        console.log("error =>", e)
                    }
                    )
            }
            // setLoader(false);
            setImgLoader(false);
            setSelectedFiles((prevImages) => prevImages.concat(filesArray));
            setVerifyImgObj(verifyImg);
            Array.from(verifyImg).map(
                (file) => URL.revokeObjectURL(file) // avoid memory leak
            );
        }



    }

    // Remove Single fn:

    const deleteImagePer = (i) => {
        console.log("i =>", verifyImgObj[i]);
        var formdata = new FormData();
        formdata.append("image", verifyImgObj[i]);

        DeleteApi(`${BaseUrl.base_url}/AddProduct?id=${i}`, formdata, Token)
            .then(({ data }) => {
                console.log("data =>", data);
            }).catch((e) => {
                console.log("e =>", e)
            })
    }

    const removeImage = (index) => {
        console.log("index =>", index)
        setSelectedFiles([
            ...selectedFiles.slice(0, index),
            ...selectedFiles.slice(index + 1)
        ]);
        setVerifyImgObj([
            ...verifyImgObj.slice(0, index),
            ...verifyImgObj.slice(index + 1)
        ]);
        deleteImagePer(index);
    }

    // Render Images fn:

    const renderPhotos = (source) => {
        return source.map((photo, index) => {
            return (
                <span className="imgUp">
                    <img style={{ border: "1px solid black" }} src={photo} width="150" height="150" alt="" key={photo} />
                    <button type="button" onClick={() => removeImage(index)}><i style={{ fontSize: 18 }} className="fas fa-trash-alt"></i></button>
                </span>
            )
        });
    };

    // Set Images for URL:

    // useEffect(() => {
    //     SetImage1(selectedFiles[0])
    //     SetImage2(selectedFiles[1])
    //     SetImage3(selectedFiles[2])
    //     SetImage4(selectedFiles[3])
    //     SetImage5(selectedFiles[4])
    //     SetImage6(selectedFiles[5])
    //     SetImage7(selectedFiles[6])
    //     SetImage8(selectedFiles[7])
    // }, [renderPhotos])

    const [Quantity, SetQuantity] = useState("")

    const [PromotionStartDate, SetPromotionStartDate] = useState("")
    const [PromotionEndDate, SetPromotionEndDate] = useState("")
    const [PromotionStartTime, SetPromotionStartTime] = useState("")
    const [PromotionEndTime, SetPromotionEndTime] = useState("")

    const [price, setPrice] = useState("");
    const [specialPrice, setSpecialPrice] = useState("");

    const [priceErr, setPriceErr] = useState({});
    const [sppriceErr, setspPriceErr] = useState({});


    const specialPriceEvent = () => {
        if (specialPrice != 0) {
            return true;
        }

    }

    // Not Null Validation fn:

    const onSubmitPrice = (e) => {
        e.preventDefault();
        const isValid = formValidationPrice();
        if (isValid == true) {
            NextStep(4);
        }
    }

    const formValidationPrice = () => {
        const priceErr = {};
        const sppriceErr = {};
console.log("specialPrice===>",specialPrice)
console.log("priceerror===>",price)


        let isValid = true;

        if (price.trim().length == 0) {
            priceErr.priceNull = "Enter Product Price";
            isValid = false;
        }
        if (specialPrice >= price){
            sppriceErr.priceNul = "Please Enter less price then Actual price";
            isValid = false;

        }

        setPriceErr(priceErr);
        setspPriceErr(sppriceErr)
        return isValid;
    }

    // ============== SERVICE & DELIVERY ==============
    const [weight, setWeight] = useState(0);
    const [length, setLength] = useState(0);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    const [SellerId, SetSellerId] = useState(3)
    const [DangerousGoods, SetDangerousGoods] = useState("")

    const [Warranty, SetWarranty] = useState("")
    const [WarrantyPeriod, SetWarrantyPeriod] = useState("")

    const [weightErr, setWeightErr] = useState({});
    const [lengthErr, setLengthErr] = useState({});
    const [widthErr, setWidthErr] = useState({});
    const [heightErr, setHeightErr] = useState({});

    // Not Null Validation fn:

    const onSubmitDelivery = (e) => {
        e.preventDefault();
        const isValid = formValidationDelivery();
        if (isValid == true) {
            console.log("isValid working")
            NextStep(4);
        }
    }

    const formValidationDelivery = () => {
        const weightErr = {};
        const lengthErr = {};
        const widthErr = {};
        const heightErr = {};

        let isValid = true;

        if (weight == 0) {
            weightErr.weightNull = "Enter product weight";
            isValid = false;
        }
        if (length == 0) {
            lengthErr.lengthNull = "Enter length";
            isValid = false;
        }
        if (width == 0) {
            widthErr.widthNull = "Enter width";
            isValid = false;
        }
        if (height == 0) {
            heightErr.heightNull = "Enter height";
            isValid = false;
        }

        setWeightErr(weightErr);
        setLengthErr(lengthErr);
        setWidthErr(widthErr);
        setHeightErr(heightErr);

        return isValid;
    }

    // Wizard Navigation fn:

    const NextStep = (val) => {
        if (val == 1) {
            setStep1(false)
            setStep2(true)
            setStep3(false)
            setStep4(false)
        } else if (val == 2) {
            setStep1(false)
            setStep2(false)
            setStep3(true)
            setStep4(false)
        } else if (val == 3) {
            setStep1(false)
            setStep2(false)
            setStep3(false)
            setStep4(true)
        } else if (val == 4) {
            setStep1(false)
            setStep2(false)
            setStep3(false)
            setStep4(true)
        }
    }
    const PreviousStep = (val) => {
        if (val == 1) {
            setStep1(true);
            setStep2(false)
            setStep3(false)
            setStep4(false)
        } else if (val == 2) {
            setStep1(true)
            setStep2(false)
            setStep3(false)
            setStep4(false)
        } else if (val == 3) {
            setStep1(false)
            setStep2(true)
            setStep3(false)
            setStep4(false)
        } else if (val == 4) {
            setStep1(false)
            setStep2(false)
            setStep3(true)
            setStep4(false)
        }
    }
    const CurruntStep = (val) => {
        if (val == 1) {
            setStep1(true);
            setStep2(false)
            setStep3(false)
            setStep4(false)
        } else if (val == 2) {
            setStep1(false)
            setStep2(true)
            setStep3(false)
            setStep4(false)
        } else if (val == 3) {
            setStep1(false)
            setStep2(false)
            setStep3(true)
            setStep4(false)
        } else if (val == 4) {
            setStep1(false)
            setStep2(false)
            setStep3(false)
            setStep4(true)
        }
    }

    // console.log(" Img URL arr =>", selectedFiles)

    // const Token = localStorage.getItem('SuperToken');
    const editor = useRef(null);
    // const editor2 = useRef2(null);
    // const editor3 = useRef(null);


    const [content, setContent] = useState("");
    const config = {
        readonly: false,
        height: 400
    };
    const handleUpdate = (event) => {
        const editorContent = event.target.innerHTML;
        setContent(editorContent);
    };
    // console.log("content---", content)

    // console.log("data==>", typeof selectedCateId, selectedCateId)
    // console.log("Array =>",typeof selectedCateId, Object.values(selectedCateId))

const [brandName,SetbrandName] = useState("")
const [brandNameErr,SetbrandNameErr] = useState({})
const [AllbrandName,SetAllbrandName] = useState([])
// console.log("AllbrandName1",AllbrandName)
// console.log("brandName",brandName)setCategories


    // ADD New Product API:

    const AddNewProduct = () => {
        console.log("categoriesId==================",categoriesId)
        setLoader(true);
        var formdata = new FormData();

        formdata.append("Product_Name", productName);
        formdata.append("Categoryid", JSON.stringify(categoriesId));
        formdata.append("VideoUrl", VideoUrl);
        formdata.append("Brand_id", brandName);
        formdata.append("Short_Description", shortDes);
        formdata.append("long_Description", longDes);
        formdata.append("English_Description", engDes);
        formdata.append("Whats_in_the_Box", WhatsintheBox);
        formdata.append("Price", price);
        formdata.append("Special_Price", specialPrice);
        formdata.append("Promotion_Start_Date", PromotionStartDate);
        formdata.append("Promotion_End_Date", PromotionEndDate);
        formdata.append("Promotion_Start_Time", PromotionStartTime);
        formdata.append("Promotion_End_Time", PromotionEndTime);
        formdata.append("Quantity", Quantity);
        // formdata.append("Warranty", Warranty);
        // formdata.append("Warranty_Period", WarrantyPeriod);
        formdata.append("Package_Weight", weight);
        formdata.append("Lenght", length);
        formdata.append("Width", width);
        formdata.append("Height", height);
        formdata.append("Seller_id", SellerId);
        // formdata.append("Dangerous_Goods", DangerousGoods);
        for (let i = 0; i < verifyImgObj.length; i++) {
            console.log("img is ", verifyImgObj[i])
            formdata.append('image', verifyImgObj[i])
        }
        formdata.append("Category",showCategory);
        var check = showCategory.split("/")
        if (check.length > 2 )
        {
            formdata.append("CategoryId", categoriesId[0]);

        }

        else{
            formdata.append("CategoryId", categoriesId);

        }



        console.log("length of cap ID==>", categoriesId.length)

        PostApi(`${BaseUrl.base_url}/AddProduct`, formdata, Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    // console.log("add new product")
                    Swal.fire({
                        title: "Success",
                        text: data.message,
                        icon: "success"
                    })
                    history.push('./newDashboard');
                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                console.log("error =>", e)
            })

    }

    const handleProductNameOnchange = (e) => {
        setProductName(e);
        showSuggestions(e)
    }



    // search brand name api

    const BrandNames = () => {


        setLoader(true);
        var formdata = new FormData();
        // console.log("formdataconsole",brandName)
        formdata.append("BrandName", brandName)



        PostApi(`${BaseUrl.base_url}/GetBrandName`, formdata, Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    SetAllbrandName(data.data)
                    console.log("databrand",data)
                    // Swal.fire({
                    //     title: "Success",
                    //     text: data.message,
                    //     icon: "success"
                    // })
                    // history.push('./newDashboard');
                } else {
                    // $(".loader").hide();
                    // Swal.fire({
                    //     title: "Opps",
                    //     text: data.message,
                    //     icon: "error"
                    // })
                }
            }).catch(function (e) {
                console.log("error =>", e)
            })


    // }else{
    //     formValidation()
    // }

    }

    //validation



    // const formValidation = () => {
    //     const brandNameErr = {};
    //     const brandName2Err = {};

    //     // console.log("validationform")


    //     let isValid = true;

    //     if (brandName == "") {
    //         brandNameErr.firstNamenull = "Required Brand Name";
    //         isValid = false;
    //     }


    //     if (AllbrandName == "") {
    //         brandName2Err.firstNamenull = "Required Brand 2 Name";
    //         isValid = false;
    //     }



    //     SetbrandNameErr(brandNameErr);
    //     SetbrandName2Err(brandName2Err);



    //     return isValid;
    // }


// console.log("AllbrandName2",AllbrandName)





    return (
        <>
            {Loader ? <div className="loader" /> : null}
            <div id="app">
                <div className="main-wrapper main-wrapper-1">
                    <Header />
                    <div className="main-content">
                        <section className="section">
                            <div id="form" className="row">
                                <div className="col-md-12">
                                    <div className="card card-primary">
                                        <div className="card-header">
                                            <h4>Add Product</h4>
                                        </div>
                                        <div className="card-body">
                                            {Step1 ?
                                                <>
                                                    {/* <h3>Personal Information</h3> */}
                                                    <form method="POST" onSubmit={onSubmitBasicinfo}>
                                                        <div className="row">
                                                            <div className="col-md-12 mb-2 text-center">
                                                                <button onClick={() => CurruntStep(1)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary">Basic Information</button>
                                                                <button disabled onClick={() => CurruntStep(2)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Detail Description</button>
                                                                <button disabled onClick={() => CurruntStep(3)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Price & Stock</button>
                                                                <button disabled onClick={() => CurruntStep(4)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Service & Delivery</button>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <fieldset>
                                                            <div className="row basicInfo px-5" >
                                                                <div className="col-md-12">
                                                                    <div className="form-group form-float">
                                                                        <div className="form-line">
                                                                            <h6 style={{ marginBottom: 10 }}>BASIC INFORMATION</h6>
                                                                            <label className="form-label"><b>Product Name</b><span style={{ color: "red" }}> *</span></label>
                                                                            <input className="form-control" value={productName} for="items" onChange={(e) => { handleProductNameOnchange(e.target.value) }} placeholder="Nikon Coolpix A300 Digital Camera" type="text" />
                                                                            {/* Category Suggestions */}
                                                                            {container ?
                                                                                <div style={{ borderRadius: 5, border: "1px solid grey", padding: 10 }}>
                                                                                    <div class="costumRow" style={{ padding: 0, display: "flex", border: "1px solid grey", backgroundColor: "white", marginRight: 15, marginLeft: 15, overflowX: "auto" }}>
                                                                                        <div>
                                                                                            {(showDropdown) ?
                                                                                                <div style={{ border: "1px solid grey", height: 318, width: 169, display: "inline-block" }}>
                                                                                                    <div style={{ margin: 10, border: "1px solid white" }}><input style={{ width: 148, border: "1px solid grey" }} type="search" placeholder="Keyword" /></div>
                                                                                                    <ul class="list-unstyled column">
                                                                                                        {/* <li style={{margin: 10}}><input type="search" placeholder="Keyword"/></li> */}
                                                                                                        {mainCategory.map((v, i) => {
                                                                                                            return (
                                                                                                                <li id={v.CategoryName} style={{ border: "1px solid white", color: "black", backgroundColor: "#f2ebeb", height: 40, padding: 10 }} onMouseOver={MouseOver} onClick={() => showSubCategory0(v, i)} key={i}>{v.CategoryName}</li>
                                                                                                            )
                                                                                                        })}
                                                                                                    </ul>
                                                                                                    {/* <SlickSlider /> */}
                                                                                                </div>
                                                                                                : null
                                                                                            }
                                                                                        </div>
                                                                                        {categoryChecker1 ?
                                                                                            <div>
                                                                                                {(showSubDropdown1) ?
                                                                                                    <div style={{ border: "1px solid grey", height: 318, width: 169, display: "inline-block" }}>
                                                                                                        <div style={{ margin: 10, border: "1px solid white" }}><input style={{ width: 148, border: "1px solid grey" }} type="search" placeholder="Keyword" /></div>
                                                                                                        <ul class="list-unstyled column">
                                                                                                            {subCategory1.map((v, i) => {
                                                                                                                return (
                                                                                                                    <li id={v.myCategory} style={{ border: "1px solid white", color: "black", backgroundColor: "#f2ebeb", height: 40, padding: 10 }} onMouseOver={MouseOver} onClick={() => showSubCategory1(v, i)} key={i}>{v.myCategory}</li>
                                                                                                                )
                                                                                                            })}
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                    : null
                                                                                                }
                                                                                            </div>
                                                                                            : null
                                                                                        }
                                                                                        {categoryChecker2 ?
                                                                                            <div>
                                                                                                {(showSubDropdown2) ?
                                                                                                    <div style={{ border: "1px solid grey", height: 318, width: 169, display: "inline-block" }}>
                                                                                                        <div style={{ margin: 10, border: "1px solid white" }}><input style={{ width: 148, border: "1px solid grey" }} type="search" placeholder="Keyword" /></div>
                                                                                                        <ul class="list-unstyled column">
                                                                                                            {subCategory2.map((v, i) => {
                                                                                                                return (
                                                                                                                    <li id={v.myCategory} style={{ border: "1px solid white", backgroundColor: "#f2ebeb", color: "black", height: 40, padding: 10 }} onClick={() => showSubCategory2(v, i)} onMouseOver={MouseOver} key={i}>{v.myCategory}</li>

                                                                                                                )
                                                                                                            })}
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                    : null
                                                                                                }
                                                                                            </div>
                                                                                            : null
                                                                                        }
                                                                                        {categoryChecker3 ?
                                                                                            <div>
                                                                                                {(showSubDropdown3) ?
                                                                                                    <div style={{ border: "1px solid grey", height: 318, width: 169, display: "inline-block" }}>
                                                                                                        <div style={{ margin: 10, border: "1px solid white" }}><input style={{ width: 148, border: "1px solid grey" }} type="search" placeholder="Keyword" /></div>
                                                                                                        <ul class="list-unstyled column">
                                                                                                            {subCategory3.map((v, i) => {
                                                                                                                return (
                                                                                                                    <li id={v.myCategory} style={{ border: "1px solid white", backgroundColor: "#f2ebeb", color: "black", height: 40, padding: 10 }} onMouseOver={MouseOver} onClick={() => showSubCategory3(v, i)} key={i}>{v.myCategory}</li>
                                                                                                                )
                                                                                                            })}
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                    : null
                                                                                                }
                                                                                            </div>
                                                                                            : null
                                                                                        }
                                                                                        {categoryChecker4 ?
                                                                                            <div>
                                                                                                {(showSubDropdown4) ?
                                                                                                    <div style={{ border: "1px solid grey", height: 318, width: 169, display: "inline-block" }}>
                                                                                                        <div style={{ margin: 10, border: "1px solid white" }}><input style={{ width: 148, border: "1px solid grey" }} type="search" placeholder="Keyword" /></div>
                                                                                                        <ul class="list-unstyled column">
                                                                                                            {subCategory4.map((v, i) => {
                                                                                                                return (
                                                                                                                    <li id={v.myCategory} style={{ border: "1px solid white", backgroundColor: "#f2ebeb", color: "black", height: 40, padding: 10 }} onMouseOver={MouseOver} onClick={() => showSubCategory4(v, i)} key={i}>{v.myCategory}</li>
                                                                                                                )
                                                                                                            })}
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                    : null
                                                                                                }
                                                                                            </div>
                                                                                            : null
                                                                                        }
                                                                                        {categoryChecker5 ?
                                                                                            <div>
                                                                                                {(showSubDropdown5) ?
                                                                                                    <div style={{ border: "1px solid grey", height: 318, width: 169, display: "inline-block" }}>
                                                                                                        <div style={{ margin: 10, border: "1px solid white" }}><input style={{ width: 148, border: "1px solid grey" }} type="search" placeholder="Keyword" /></div>
                                                                                                        <ul class="list-unstyled column">
                                                                                                            {subCategory5.map((v, i) => {
                                                                                                                return (
                                                                                                                    <li id={v.myCategory} style={{ border: "1px solid white", backgroundColor: "#f2ebeb", color: "black", height: 40, padding: 10 }} onMouseOver={MouseOver} onClick={() => showSubCategory5(v, i)} key={i}>{v.myCategory}</li>
                                                                                                                )
                                                                                                            })}
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                    : null
                                                                                                }
                                                                                            </div>
                                                                                            : null
                                                                                        }
                                                                                        {categoryChecker6 ?
                                                                                            <div>
                                                                                                {(showSubDropdown6) ?
                                                                                                    <div style={{ border: "1px solid grey", height: 318, width: 169, display: "inline-block" }}>
                                                                                                        <div style={{ margin: 10, border: "1px solid white" }}><input style={{ width: 148, border: "1px solid grey" }} type="search" placeholder="Keyword" /></div>
                                                                                                        <ul class="list-unstyled column">
                                                                                                            {subCategory6.map((v, i) => {
                                                                                                                return (
                                                                                                                    <li id={v.myCategory} style={{ border: "1px solid white", backgroundColor: "#f2ebeb", color: "black", height: 40, padding: 10 }} onMouseOver={MouseOver} onClick={() => showSubCategory6(v, i)} key={i}>{v.myCategory}</li>
                                                                                                                )
                                                                                                            })}
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                    : null
                                                                                                }
                                                                                            </div>
                                                                                            : null
                                                                                        }
                                                                                        {categoryChecker7 ?
                                                                                            <div>
                                                                                                {(showSubDropdown7) ?
                                                                                                    <div style={{ border: "1px solid grey", height: 318, width: 169, display: "inline-block" }}>
                                                                                                        <div style={{ margin: 10, border: "1px solid white" }}><input style={{ width: 148, border: "1px solid grey" }} type="search" placeholder="Keyword" /></div>
                                                                                                        <ul class="list-unstyled column">
                                                                                                            {subCategory7.map((v, i) => {
                                                                                                                return (
                                                                                                                    <li id={v.myCategory} style={{ border: "1px solid white", backgroundColor: "#f2ebeb", color: "black", height: 40, padding: 10 }} onMouseOver={MouseOver} onClick={() => showSubCategory7(v, i)} key={i}>{v.myCategory}</li>
                                                                                                                )
                                                                                                            })}
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                    : null
                                                                                                }
                                                                                            </div>
                                                                                            : null
                                                                                        }
                                                                                        {categoryChecker8 ?
                                                                                            <div>
                                                                                                {(showSubDropdown8) ?
                                                                                                    <div style={{ border: "1px solid grey", height: 318, width: 169, display: "inline-block" }}>
                                                                                                        <div style={{ margin: 10, border: "1px solid white" }}><input style={{ width: 148, border: "1px solid grey" }} type="search" placeholder="Keyword" /></div>
                                                                                                        <ul class="list-unstyled column">
                                                                                                            {subCategory8.map((v, i) => {
                                                                                                                return (
                                                                                                                    <li id={v.myCategory} style={{ border: "1px solid white", backgroundColor: "#f2ebeb", color: "black", height: 40, padding: 10 }} onMouseOver={MouseOver} key={i}>{v.myCategory}</li>
                                                                                                                )
                                                                                                            })}
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                    : null
                                                                                                }
                                                                                            </div>
                                                                                            : null
                                                                                        }
                                                                                    </div>
                                                                                    <div class="row" style={{ backgroundColor: "#f2ebeb", padding: 8, border: "1px solid grey", marginLeft: 16, width: 676, marginTop: 8, marginBottom: 8 }}>
                                                                                        <span>Current Selection:
                                                                                            <span style={{ color: "red" }}>
                                                                                                {(cateSelection) ?
                                                                                                    categories.map((v, i) => {
                                                                                                        console.log("v =>", v)
                                                                                                        return (
                                                                                                            <span>{` ${v} >`}</span>
                                                                                                        )
                                                                                                    })
                                                                                                    : null
                                                                                                }
                                                                                            </span>
                                                                                        </span>
                                                                                        <span></span>
                                                                                    </div>
                                                                                    <div class="row" style={{ marginLeft: 15, marginTop: 5 }}>
                                                                                        <button class="btn-sm btn-primary" onClick={showFinalCategory}>Confirm</button>
                                                                                        <button style={{ marginLeft: 5 }} class="btn-sm btn-primary" onClick={() => setContainer(false)}>Cancel</button>
                                                                                        <button style={{ marginLeft: 5 }} class="btn-sm btn-primary" onClick={categoryClear}>Clear</button>
                                                                                    </div>
                                                                                </div>
                                                                                : null
                                                                            }

                                                                            <div>
                                                                                {Object.keys(productNameErr).map((key) => {
                                                                                    return <div style={{ color: "red" }}>{productNameErr[key]}</div>
                                                                                })}
                                                                            </div>
                                                                            {/* Category Suggestions Hard Coded */}
                                                                            {/* <div id="suggTitle">
                                                                            </div>
                                                                            <div id="cateSugg">
                                                                                {(productName == "handfree" || productName == "Handfree") ?
                                                                                    cateSug.map((v, i) => {
                                                                                        return (
                                                                                            (i == 0) ? document.createElement('h6').appendChild(document.createTextNode("Category Suggestions: ")): null
                                                                                            <div>
                                                                                                <div key={i}>
                                                                                                    <input onClick={() => suggestionEvent(v.content)} type="radio" name="handfree" value={v.val} />
                                                                                                    <span>{v.content}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }) : null}
                                                                            </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row basicInfo px-5">
                                                                <div className="col-md-12">
                                                                    <div className="form-group form-float">
                                                                        <div className="form-line">
                                                                            <label className="form-label"><b>Category</b><span style={{ color: "red" }}> *</span></label>
                                                                            <input value={showCategory} onChange={(e) => setShowCategory(e.target.value)} placeholder="" type="text" className="form-control" />
                                                                            <div>
                                                                                {Object.keys(showCategoryErr).map((key) => {
                                                                                    return <div style={{ color: "red" }}>{showCategoryErr[key]}</div>
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* Product Attributes */}
                                                            <div>
                                                                {(selectCateAttr) ?
                                                                    <div>
                                                                        <div className="row basicInfo px-5">
                                                                            <div className="col-md-12">
                                                                                <div className="form-group form-float">
                                                                                    <div className="form-line">
                                                                                        <label className="form-label"><b>Video URL</b></label>
                                                                                        <input value={VideoUrl} onChange={e => SetVideoUrl(e.target.value)} placeholder="" type="text" className="form-control" />
                                                                                        {/* <h6 style={{ marginTop: 30 }}>PRODUCT ATTRIBUTES</h6> */}
                                                                                        {/* <div>
                                                                                    {Object.keys(categoryErr).map((key) => {
                                                                                        return <div style={{ color: "red" }}>{categoryErr[key]}</div>
                                                                                    })}
                                                                                </div> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/* <p style={{ fontWeight: 'normal', fontSize: 14, marginLeft: 50, marginTop: -20 }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus molestiae veritatis aliquid, laborum quasi atque exercitationem. Voluptate ullam adipisci voluptatibus sapiente.</p> */}
                                                                        <div className="row mx-4">
                                                                            <div className="col-md-6">
                                                                                 <div className="form-group form-float">
                                                                                <label className="form-label"><b>Brand</b><span style={{ color: "red" }}> *</span></label>
                                                                                <div className="row">
                                                                                <div className="col-md-6">
                                                                                <Typeahead
                                                                                    onChange={(brandName) =>SetbrandName(brandName) }
                                                                                    options={AllbrandName}
                                                                                    selected={brandName}

                                                                                    />

                                                                                    <div>
                                                                                    {Object.keys(brandErr).map((key) => {
                                                                                        return <div style={{ color: "red" }}>{brandErr[key]}</div>
                                                                                    })}
                                                                                </div>

                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                <Button onClick={()=>handleShow2()}  className="">Create Your Brand</Button>


                                                                                    </div>

                                                                                </div>


                                                                                </div>
                                                                            </div>

                                                                            {/* <div className="col-md-4">
                                                                                <div className="form-group form-float">
                                                                                    <div className="form-line">
                                                                                        <label className="form-label"><b>Cable Length</b></label>

                                                                                        <select className="form-control">
                                                                                            <option>Select Length</option>
                                                                                            <option>UPC</option>
                                                                                            <option>ATP</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}
                                                                        </div>
                                                                        {/* <div className="d-flex justify-content-end" style={{ marginRight: 50 }}>
                                                                            <button className="btn btn-outline-danger">Clear All</button>
                                                                        </div> */}
                                                                    </div>
                                                                    : null}
                                                            </div>
                                                        </fieldset>
                                                        {/* Next Button */}
                                                        <div className="row">
                                                            <div className="col-md-12 pt-3 text-center">
                                                                <hr />
                                                                {/* <button className="btn btn-primary col-md-3 mx-2" onClick={() => PreviousStep(1)}>Previous</button> */}
                                                                <button className="btn btn-primary col-md-3 mx-2"  type="submit">Next</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </>
                                                : null
                                            }
                                            {Step2 ?
                                                <>
                                                    {/* <h3>Detailed Description</h3> */}
                                                    <form method="POST" onSubmit={onSubmitDesinfo}>
                                                        <div className="row">
                                                            <div className="col-md-12 mb-2 text-center">
                                                                <button onClick={() => CurruntStep(1)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary">Basic Information</button>
                                                                <button onClick={() => CurruntStep(2)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Detail Description</button>
                                                                <button disabled onClick={() => CurruntStep(3)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Price & Stock</button>
                                                                <button disabled onClick={() => CurruntStep(4)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Service & Delivery</button>
                                                            </div>
                                                        </div>
                                                        <fieldset>
                                                            <div className="row">
                                                                <div className="col-md-12 d-flex justify-content-center">
                                                                    <div className="form-group form-float">
                                                                        <div className="form-line">
                                                                            <label className="form-label"><b>Short Description</b><span style={{ color: "red" }}> *</span></label>
                                                                            {/* <JoditEditor
                                                                                value={ShortDescription}
                                                                                ref={editor}
                                                                                // value={content}
                                                                                config={config}
                                                                                // onBlur={handleUpdate}
                                                                                //
                                                                                onChange={(e) => SetShortDescription(e)}


                                                                            /> */}
                                                                            <Editor
                                                                                editorState={editorState1}
                                                                                toolbarClassName="toolbarClassName"
                                                                                wrapperClassName="demoWrapper"
                                                                                editorClassName="demoEditor"
                                                                                onEditorStateChange={onEditorStateChange1}
                                                                                editorStyle={{ border: "1px solid #F0F0F0", padding: 15, minHeight: 350 }}
                                                                            />

<div>
                                                                                            {Object.keys(editorState1Err).map((key) => {
                                                                                                return <div style={{ color: "red" }}>{editorState1Err[key]}</div>
                                                                                            })}
                                                                                        </div>
                                                                            {/* <textarea
                                                                                disabled
                                                                                value={draftToHtml(convertToRaw(editorState1.getCurrentContent()))}
                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 d-flex justify-content-center">
                                                                    <div className="form-group form-float">
                                                                        <div className="form-line">
                                                                            <label className="form-label"><b>Long Description</b></label>
                                                                            {/* <JoditEditor
                                                                                value={LongDescription}
                                                                                ref={editor}
                                                                                // value={content}
                                                                                config={config}
                                                                                // onBlur={handleUpdate}

                                                                                onChange={(e) => SetLongDescription(e)}
                                                                            /> */}
                                                                            <Editor
                                                                                editorState={editorState2}
                                                                                toolbarClassName="toolbarClassName"
                                                                                wrapperClassName="wrapperClassName"
                                                                                editorClassName="editorClassName"
                                                                                onEditorStateChange={onEditorStateChange2}
                                                                                editorStyle={{ border: "1px solid #F0F0F0", padding: 15, minHeight: 350 }}
                                                                            />
                                                                             <div>
                                                                                            {Object.keys(editorState2Err).map((key) => {
                                                                                                return <div style={{ color: "red" }}>{editorState2Err[key]}</div>
                                                                                            })}
                                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col d-flex justify-content-center">
                                                                    <div className="form-group form-float">
                                                                        <div className="form-line">
                                                                            <label className="form-label"><b>English Description</b><span style={{ color: "red" }}> *</span></label>
                                                                            {/* <JoditEditor
                                                                                value={EnglishDescription}
                                                                                ref={editor}
                                                                                // value={content}
                                                                                config={config}
                                                                                // onBlur={handleUpdate}

                                                                                onChange={(e) => SetEnglishDescription(e)}
                                                                            /> */}
                                                                            {/* <div style={{ border: "1px solid lightgrey", padding: 5, height: 350 }}> */}
                                                                            <Editor
                                                                                editorState={editorState3}
                                                                                toolbarClassName="toolbarClassName"
                                                                                wrapperClassName="wrapperClassName"
                                                                                editorClassName="editorClassName"
                                                                                onEditorStateChange={onEditorStateChange3}
                                                                                editorStyle={{ border: "1px solid #F0F0F0", padding: 15, minHeight: 350 }}
                                                                            />
                                                                             <div>
                                                                                            {Object.keys(editorState3Err).map((key) => {
                                                                                                return <div style={{ color: "red" }}>{editorState3Err[key]}</div>
                                                                                            })}
                                                                                        </div>
                                                                            {/* </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 px-5">
                                                                    <div className="form-group form-float">
                                                                        <div className="form-line">
                                                                            <label className="form-label"><b>What's in the box</b></label>
                                                                            <input style={{ border: "1px solid #F0F0F0" }} value={WhatsintheBox} onChange={e => SetWhatsintheBox(e.target.value)} placeholder="" type="text" className="form-control" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                        <div className="row">
                                                            <div className="col-md-12 pt-3 text-center">
                                                                <hr />
                                                                <button className="btn btn-primary col-md-3 mx-2" onClick={() => PreviousStep(2)}>Previous</button>
                                                                {/* <button className="btn btn-primary col-md-3 mx-2"  type="submit">Next</button> */}
                                                                <button className="btn btn-primary col-md-3 mx-2" onClick={() => NextStep(2)}>Next</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </>
                                                : null}
                                            {Step3 ?
                                                <>
                                                    {/* <h3>Price & Stock</h3> */}
                                                    <form method="POST" onSubmit={onSubmitPrice}>
                                                        <div className="row">
                                                            <div className="col-md-12 mb-2 text-center">
                                                                <button onClick={() => CurruntStep(1)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary">Basic Information</button>
                                                                <button onClick={() => CurruntStep(2)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Detail Description</button>
                                                                <button onClick={() => CurruntStep(3)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Price & Stock</button>
                                                                <button disabled onClick={() => CurruntStep(4)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Service & Delivery</button>
                                                            </div>
                                                        </div>
                                                        <fieldset>
                                                            <div className="form-group form-float">
                                                                <div className="form-line" >
                                                                    <div style={{ border: "1px solid gray", borderRadius: 8, padding: 20, marginBottom: 10 }}>
                                                                        <h6 style={{ color: "black" }}>UPLOAD YOUR PRODUCT IMAGES</h6>
                                                                        {/* <div style={{ marginTop: -5, marginBottom: 10 }}>Drop images here or click to upload</div> */}
                                                                        <div style={{ marginTop: -5, marginBottom: 10, color: "red" }}>Please Upload Your Product Images greater than 330 x 330 and less than 1000 x 1000.</div>
                                                                        <hr />
                                                                        <div className="row">


                                                                            <div style={{ marginLeft: 20 }}>
                                                                                <div style={{ marginBottom: 10 }} className="label-holder">
                                                                                    <label style={{ marginBottom: 0 }} htmlFor="file" className="label">
                                                                                        <i style={{ fontSize: 35 }} className="material-icons">add_a_photo</i>
                                                                                    </label>
                                                                                    <br />
                                                                                    <input type="file" id="file" hidden multiple onChange={handleImageChange} />
                                                                                </div>

                                                                                <div className="result">
                                                                                    {imgLoader ? <LinearProgressWithLabel /> : null}
                                                                                    {renderPhotos(selectedFiles)}
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div className="" style={{ border: "1px solid gray", borderRadius: 8, padding: 20, marginBottom: 10 }} role="alert">
                                                                        <h6 style={{ color: "black" }}>PRICING & STOCK</h6>
                                                                        <hr />
                                                                        <div className="row text-dark" style={{ fontSize: 14 }}>
                                                                            {/* <div className="col-3"><b>AVALIBILITY</b></div> */}
                                                                            {/* <div className="col-3">PRICE<span style={{ color: "red" }}> *</span></div>
                                                                            <div className="col-3">SPECIAL PRICE</div> */}
                                                                            <div className="col-3"><b>QUANTITY</b></div>
                                                                        </div>
                                                                        <div className="row text-dark">
                                                                        <div className="col-md-3">
                                                                                <div className="form-group form-float">
                                                                                    <div className="form-group form-float">
                                                                                        <div className="form-line">
                                                                                            <input value={Quantity} onChange={e => SetQuantity(e.target.value)} placeholder="" type="number" className="form-control" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/* <div className="col-3">
                                                                                <div className="form-check" style={{ padding: 10 }} >
                                                                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                                                                                    <label className="form-check-label" for="defaultCheck1"><b>Check your Product Availibility</b></label>
                                                                                </div>
                                                                            </div> */}
                                                                            {/* <div className="col-md-3">
                                                                                <div className="form-group form-float">
                                                                                    <div className="form-group form-float">
                                                                                        <div className="form-line">
                                                                                            <input value={Quantity} onChange={e => SetQuantity(e.target.value)} placeholder="" type="number" className="form-control" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}
                                                                            <div className="row text-dark px-4">
                                                                                <div className="row text-dark" style={{ fontSize: 14 }}>
                                                                                    <div className="col-3"><b>PRICE</b><span style={{ color: "red" }}> *</span></div>
                                                                                    <div className="col-2"><b>SPECIAL PRICE</b></div>
                                                                                </div>
                                                                                <div className="row text-dark">
                                                                                    <div className="col-3">
                                                                                        <input value={price} onChange={(e) => setPrice(e.target.value)} placeholder="" type="number" className="form-control" />
                                                                                        <div>
                                                                                            {Object.keys(priceErr).map((key) => {
                                                                                                return <div style={{ color: "red" }}>{priceErr[key]}</div>
                                                                                            })}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-2">
                                                                                        <input value={specialPrice}  onChange={(e) => setSpecialPrice(e.target.value)} type="number" className="form-control" />
                                                                                        <div>
                                                                                            {Object.keys(sppriceErr).map((key) => {
                                                                                                return <div style={{ color: "red" }}>{sppriceErr[key]}</div>
                                                                                            })}
                                                                                        </div>
                                                                                    </div>

                                                                                    {(specialPriceEvent()) ?
                                                                                        <div>
                                                                                            <div style={{ marginTop: -20 }}>
                                                                                                <div className="col"><b>PROMOTION DATE</b></div>
                                                                                                <div className="row text-dark">
                                                                                                    <div className="col">
                                                                                                        <input value={PromotionStartDate} onChange={e => SetPromotionStartDate(e.target.value)} min={disabledate} placeholder="Start Date" type="date" className="form-control" />
                                                                                                    </div>
                                                                                                    <div className="col">
                                                                                                        <input value={PromotionEndDate} onChange={e => SetPromotionEndDate(e.target.value)} min={disabledate} placeholder="End Date" type="date" className="form-control" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div style={{ marginTop: 5 }}>
                                                                                                <div className="col"><b>PROMOTION TIME</b></div>
                                                                                                <div className="row text-dark">
                                                                                                    <div className="col">
                                                                                                        <input value={PromotionStartTime} onChange={e => SetPromotionStartTime(e.target.value)} placeholder="Start Time" type="time" className="form-control" />
                                                                                                    </div>
                                                                                                    <div className="col">
                                                                                                        <input value={PromotionEndTime} onChange={e => SetPromotionEndTime(e.target.value)} placeholder=" Time End " type="time" className="form-control" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        : null
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                        <div className="row">
                                                            <div className="col-md-12 pt-3 text-center">
                                                                <hr />
                                                                <button className="btn btn-primary col-md-3 mx-2" onClick={() => PreviousStep(3)}>Previous</button>
                                                                <button className="btn btn-primary col-md-3 mx-2" type="submit" >Next</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </>
                                                : null
                                            }
                                            {Step4 ?
                                                <>
                                                    {/* <h3>Service & Delivery</h3> */}
                                                    {/* <h3>Service & Delivery</h3> */}
                                                    <form method="POST" onSubmit={onSubmitDelivery}>
                                                        <div className="row">
                                                            <div className="col-md-12 mb-2 text-center">
                                                                <button onClick={() => CurruntStep(1)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary">Basic Information</button>
                                                                <button onClick={() => CurruntStep(2)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Detail Description</button>
                                                                <button onClick={() => CurruntStep(3)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Price & Stock</button>
                                                                <button onClick={() => CurruntStep(4)} style={{ width: "22%", height: "50px", fontSize: "15px" }} className="btn btn-primary ml-2">Service & Delivery</button>
                                                            </div>
                                                        </div>
                                                        <fieldset>
                                                            <div className="form-group form-float">
                                                                <div className="form-line">
                                                                    {/* <h5>Service</h5> */}
                                                                    <hr />
                                                                    {/* <div className="row">
                                                                        <div className="col-md-4">
                                                                            <div className="form-group form-float">
                                                                                <div className="form-line">
                                                                                    <label className="form-label"><b>Warranty Type</b><span style={{ color: "red" }}> *</span></label>
                                                                                    <select className="form-control">
                                                                                        <option>Choose Type</option>
                                                                                        <option>UPC</option>
                                                                                        <option>ATP</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group form-float">
                                                                                <div className="form-line">
                                                                                    <label className="form-label"><b>Warranty Period</b></label>
                                                                                    <select className="form-control">
                                                                                        <option>Select Period</option>
                                                                                        <option>UPC</option>
                                                                                        <option>ATP</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-10">
                                                                            <div className="form-group form-float">
                                                                                <div className="form-line">
                                                                                    <label className="form-label"><b>Warranty Policy</b></label>
                                                                                    <input placeholder="" type="text" className="form-control" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                                    <h5>Delivery</h5>
                                                                    <hr />
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <div className="form-group form-float">
                                                                                <div className="form-line">
                                                                                    <label className="form-label"><b>Package Weight(Kg)</b><span style={{ color: "red" }}> *</span></label>
                                                                                    <input value={weight} onChange={(e) => setWeight(e.target.value)} placeholder="" type="number" className="form-control" />
                                                                                    <div>
                                                                                        {Object.keys(weightErr).map((key) => {
                                                                                            return <div style={{ color: "red" }}>{weightErr[key]}</div>
                                                                                        })}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row" style={{ paddingLeft: 20 }}>
                                                                        <div className="form-group form-float">
                                                                            <div className="form-line">
                                                                                <label className="form-label"><b>Package Dimensions(cm)</b><span style={{ color: "red" }}> *</span></label>
                                                                                <div className="row">
                                                                                    <div className="col-md-4">
                                                                                        <input value={length} onChange={(e) => setLength(e.target.value)} placeholder="Length(cm)" type="number" className="form-control" />
                                                                                        <div>
                                                                                            {Object.keys(lengthErr).map((key) => {
                                                                                                return <div style={{ color: "red" }}>{lengthErr[key]}</div>
                                                                                            })}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <input value={width} onChange={(e) => setWidth(e.target.value)} placeholder="Width(cm)" type="number" className="form-control" />
                                                                                        <div>
                                                                                            {Object.keys(widthErr).map((key) => {
                                                                                                return <div style={{ color: "red" }}>{widthErr[key]}</div>
                                                                                            })}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-4">
                                                                                        <input value={height} onChange={(e) => setHeight(e.target.value)} placeholder="Height(cm)" type="number" className="form-control" />
                                                                                        <div>
                                                                                            {Object.keys(heightErr).map((key) => {
                                                                                                return <div style={{ color: "red" }}>{heightErr[key]}</div>
                                                                                            })}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </fieldset>
                                                        <div className="row">
                                                            <div className="col-md-12 pt-3 text-center">
                                                                <hr />
                                                                <button className="btn btn-primary col-md-3 mx-2" onClick={() => PreviousStep(4)}>Previous</button>
                                                                <button onClick={() => AddNewProduct()} className="btn btn-primary col-md-3 mx-2" type="button" >Finish</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    {/* <div className="mt-5 text-muted text-center">
                                        Don't have an account? <a href="auth-register.html">Create One</a>
                                    </div> */}
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>

            <Modal
                size="lg"
                aria-labelledby="example-modal-sizes-title-sm"
                show={show2}
                onHide={handleClose2}>
                <Modal.Header   >
                    <Modal.Title>Add Brand</Modal.Title>
                    <i onClick={handleClose2} style={{ fontSize: "24px" }} className=" fa-10X fas fa-window-close"></i>
                </Modal.Header>
                <Modal.Body>
                    {/* Woohoo, you're reading this text in a modal! */}
                    <form>

                    <div className="form-group form-float">
                                                            <div className="form-line">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <label className="form-label"><b>Enter Brand Name</b></label>
                                                                    </div>
                                                                    <div className="col-md-6 text-right">
                                                                        {/* {Available ? <>
                                                                            <i className="fas fa-check-circle text-success"></i>
                                                                            <label className="form-label"> Available</label>
                                                                        </> : <>
                                                                            <i className="fas fa-times-circle text-danger"></i>
                                                                            <label className="form-label"> Not Available</label>
                                                                        </>} */}
                                                                    </div>
                                                                </div>
                                                                <input type="text" value={BrandName} onChange={(e) => setBrandName(e.target.value)} name="name" className="form-control" />
                                                                {Ctrue ?
                                                                            <div>
                                                                                {Object.keys(confirmationCodeErr).map((key) => {
                                                                                    return <div style={{ color: "green" }}>{confirmationCodeErr[key]}</div>
                                                                                })}
                                                                            </div>
                                                                            :
                                                                            <div>
                                                                                {Object.keys(confirmationCodeErr).map((key) => {
                                                                                    return <div style={{ color: "red" }}>{confirmationCodeErr[key]}</div>
                                                                                })}
                                                                            </div>
                                                                        }
                                                            </div>
                                                        </div>



                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose2}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={()=>BrandRequest()} >
                        Request
                    </Button>
                </Modal.Footer>
            </Modal>
        </>


    )
}

