import React, { useState, useEffect } from "react"
import SuperAdminHeader from "../components/SAheader/Header"
import SuperAdminFooter from "../components/SAfooter/Footer"
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from 'material-table'
import { useHistory } from "react-router";
import Swal from 'sweetalert2'
import BaseUrl from "../../../../config/BaseUrl"
import image_url from "../../../../config/BaseUrl"
import { getApiWithToken, PutApi, PostApi, DeleteApi } from "../../../../config/Api/FakeuserApi";
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import IconButton from "@material-ui/core/IconButton";

// import{ MaterialTable} from "@material-ui/core/MaterialTable";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    input: {
        display: "none",
    },
}));

export default function AdminAccounts() {

    const history = useHistory();
    const [selectedRow, setSelectedRow] = useState(null);

    // GETALlROLES STATES
    const [GetAccounts, SetGetAccounts] = useState([])
    const [Loader, setLoader] = useState(false)

    // AddROLES Modal States1
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const classes = useStyles();

    const [AddFName, SetAddFName] = useState("");
    const [AddLName, SetAddLName] = useState("");
    const [AddEmail, SetAddEmail] = useState("");
    const [AddContact, SetAddContact] = useState("");
    const [AddPassword, SetAddPassword] = useState("");
    const [Disable, setDisable] = useState(true);

    const [SelectedThumbnail, setSelectedThumbnail] = useState(null);
    const [AddSubProfile, SetAddSubProfile] = useState(null);
    const [Selectedprofile, setSelectedprofile] = useState(null);
    const [UpdateThumbnail, setUpdateThumbnail] = useState(null);
    // AddROLES Modal States1

    const [Package, SetPackage] = useState("");
    // update states

    const [Email, SetEmail] = useState("")
    const [Contact, SetContact] = useState("")
    const [Fname, SetFname] = useState("")
    const [Lname, SetLname] = useState("")
    const [profileimage, setprofileimage] = useState(null)
    const [Password, SetPassword] = useState("nochange")
    const [statusA, SetstatusA] = useState("")
    const [Ustatus, SetUstatus] = useState("")


    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);


    const [AllPackages, SetAllPackages] = useState([])
    const [Role, SetRole] = useState("")
    const [RoleId, SetRoleID] = useState("")
    // const [PAckID, SetPAckID] = useState("")
    const [AdminPackage, SetAdminPackage] = useState("False")






console.log("AllPackages",AllPackages)


    console.log("Ustatus", Ustatus);

    // add form error states
    const [profileErr, setprofileErr] = useState({});
    const [fnameErr, setfnameErr] = useState({});
    const [lnameErr, setlnameErr] = useState({});
    const [emailErr, setemailErr] = useState({});
    const [passwordErr, setpasswordErr] = useState({});
    const [contactErr, setcontactErr] = useState({});
    const [pakageErr, setpakageErr] = useState({});
    const [statusErr, setstatusErr] = useState({});
    const [UstatusErr, SetUstatusErr] = useState({})


    // add form  error states

    // add form validation
    const formValidation = () => {
        const profileErr = {};
        const fnameErr = {};
        const lnameErr = {};
        const emailErr = {};
        const passwordErr = {};
        const contactErr = {};
        const pakageErr = {};
        const statusErr = {}

        const validPassword = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$');

        let isValid = true;

        if (AddSubProfile == null) {
            profileErr.profileNull = "Profile Image is required";
            isValid = false;
        }
        if (AddFName == "") {
            fnameErr.fnameNull = "First Name is required";
            isValid = false;
        }
        if (AddLName == "") {
            lnameErr.lnameNull = "Last Name is required";
            isValid = false;
        }
        if (AddContact == "") {
            contactErr.contactNull = "Contact is required";
            isValid = false;
        }
        if (AddEmail == "") {
            emailErr.emailNull = "Email is required";
            isValid = false;
        }
        if (statusA == "") {
            statusErr.emailNull = "Status is required";
            isValid = false;
        }
        if (AddPassword == "") {
            passwordErr.passwordNull = "Password is required";
            isValid = false;
        }
        if (!validPassword.test(AddPassword)) {
            passwordErr.passwordNull = "Enter Strong Password";
            isValid = false;
        }
        if (Package == "") {
            pakageErr.pakageNull = "Select your Package";
            isValid = false;
        }

        setprofileErr(profileErr);
        setfnameErr(fnameErr);
        setlnameErr(lnameErr);
        setemailErr(emailErr);
        setpasswordErr(passwordErr);
        setcontactErr(contactErr);
        setpakageErr(pakageErr);
        setstatusErr(statusErr);

        return isValid;
    }
    // add form validation

    // update form error states
    const [upprofileErr, setupprofileErr] = useState({});
    const [upfnameErr, setupfnameErr] = useState({});
    const [uplnameErr, setuplnameErr] = useState({});
    const [upemailErr, setupemailErr] = useState({});
    // const [uppasswordErr, setuppasswordErr] = useState({});
    const [upcontactErr, setupcontactErr] = useState({});
    const [uppakageErr, setuppakageErr] = useState({});
    const [updatesErr, setupdatesErr] = useState({});

    // update form  error states

    // update form validation
    const updateformValidation = () => {
        const upprofileErr = {};
        const upfnameErr = {};
        const uplnameErr = {};
        const upemailErr = {};
        const updatesErr = {};
        const upcontactErr = {};
        const uppakageErr = {};

        const validPassword = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$');

        let isValid = true;

        if (profileimage == null) {
            upprofileErr.upprofileNull = "Profile Image is required";
            isValid = false;
        }
        if (Fname == "") {
            upfnameErr.upfnameNull = "First Name is required";
            isValid = false;
        }
        if (Lname == "") {
            uplnameErr.uplnameNull = "Last Name is required";
            isValid = false;
        }
        if (Contact == "") {
            upcontactErr.upcontactNull = "Contact is required";
            isValid = false;
        }
        if (Email == "") {
            upemailErr.upemailNull = "Email is required";
            isValid = false;
        }
        // if (Ustatus == "") {
        //     updatesErr.uppasswordNull = "Status required";
        //     isValid = false;
        // }

        if (Role == "") {
            uppakageErr.uppakageNull = "Package is required";
            isValid = false;
        }

        setupprofileErr(upprofileErr);
        setupfnameErr(upfnameErr);
        setuplnameErr(uplnameErr);
        setupemailErr(upemailErr);
        // setuppasswordErr(uppasswordErr);
        setupcontactErr(upcontactErr);
        setuppakageErr(uppakageErr);
        setupdatesErr(updatesErr)
        return isValid;
    }
    // update form validation


    // console.log("SetPackage==",SetPackage)

    // gETtoken from local storage
    const Token = localStorage.getItem('SuperToken');
    useEffect(() => {
        ViewAllAdminAccounts()
        GetAdminPackages()

    }, [])






    // VIEW ALL ADMIN ACCOUNTS API

    const ViewAllAdminAccounts = () => {
        setLoader(true);


        getApiWithToken(`${BaseUrl.Super_Admin_Url}/addAdminAccount`, "", Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    SetGetAccounts(data.data)
                    console.log("Accoutns==>", data)
                    // setdisable(true);
                    // SetUstatus
                    // $(".loader").hide();

                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })

    }

    // SELECT Packages FROM ADMIN GETAPI
    const GetAdminPackages = () => {

        getApiWithToken(`${BaseUrl.Super_Admin_Url}/GetAdminPackages`, "", Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    console.log("dataarole==>", data)
                    SetAllPackages(data.data)
                    // setdisable(true);
                    // $(".loader").hide();

                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })

    }



    const handleInputChange = (event, func) => {
        func(event.target.value);
        if (event.target.value !== "") {
            setDisable(false);
        } else {
            setDisable(true);
        }
    }


    // PROFILE IMAG WORK

    const handleInputFileChange = (event, func) => {
        var file = event.target.files[0];
        var reader = new FileReader();
        // console.log(file);
        const filesize = file.size
        const filesizeround = Math.round((filesize / 1024))
        if (filesizeround > 1024) {
            // setupdateProfileImage(null)
            // setselectProfileImage(null)
            Swal.fire({
                title: "Opps",
                text: "plz select your image less then and equals to 1mb",
                icon: "warning"
            });
        } else {
            var url = reader.readAsDataURL(file);

            reader.onloadend = function (e) {
                setSelectedprofile(reader.result);
                setUpdateThumbnail(file);



            };

            func(event.target.files[0])
            // setdisable(false)
        }
    }
    // ADD ACCOUNTS API

    const AddNewAccount = () => {

        const isValid = formValidation();

        if (isValid == true) {
            setLoader(true);
            var formdata = new FormData();
            formdata.append("profile", AddSubProfile);
            formdata.append("fname", AddFName);
            formdata.append("lname", AddLName);
            formdata.append("email", AddEmail);
            formdata.append("password", AddPassword);
            formdata.append("contactNo", AddContact);
            formdata.append("PackId", Package);
            formdata.append("AdminStatus", statusA);


            PostApi(`${BaseUrl.Super_Admin_Url}/addAdminAccount`, formdata, Token)
                .then(({ data }) => {
                    setLoader(false);
                    if (data.status) {
                        console.log("AddAccott==>", data)
                        // SetRole("")
                        // setSelectedThumbnail("")
                        ViewAllAdminAccounts()
                        handleClose()

                        SetAddFName("");
                        SetAddLName("");
                        SetAddEmail("");
                        SetAddContact("");
                        SetAddPassword("");
                        SetPackage("");
                        SetstatusA("")
                        SetAddSubProfile(null);
                        setSelectedprofile(null);

                        Swal.fire({
                            title: "success",
                            text: data.message,
                            icon: "success"
                        })
                    } else {
                        // $(".loader").hide();
                        Swal.fire({
                            title: "Opps",
                            text: data.message,
                            icon: "error"
                        })
                    }
                }).catch(function (e) {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: e.message,
                        icon: "error"
                    })
                })
        } else {
            formValidation()
        }

    }

    // EDITAPI EDIT SUBADMIN PANEL
    const UpdateAccount = () => {
        const updatevalid = updateformValidation();
        if (updatevalid == true) {
            setLoader(true);
            var formdata = new FormData();
            formdata.append("email", Email);
            formdata.append("password", "nochange");
            formdata.append("fname", Fname);
            formdata.append("lname", Lname);
            formdata.append("contactNo", Contact);
            formdata.append("PackId", RoleId);
            formdata.append("profile", UpdateThumbnail);
            formdata.append("AdminStatus", Ustatus);
            formdata.append("AdminPackage", AdminPackage);


            PutApi(`${BaseUrl.Super_Admin_Url}/addAdminAccount`, formdata, Token)
                .then(({ data }) => {
                    setLoader(false);
                    if (data.status) {
                        console.log("dataaPut==>", data)
                        ViewAllAdminAccounts()
                        handleClose2()
                        SetAdminPackage("False")
                        // SetUstatus("")

                        Swal.fire({
                            title: "success",
                            text: data.message,
                            icon: "success"
                        })
                    } else {
                        // $(".loader").hide();
                        Swal.fire({
                            title: "Opps",
                            text: data.message,
                            icon: "error"
                        })
                    }
                }).catch(function (e) {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: e.message,
                        icon: "error"
                    })
                })
        } else {
            updateformValidation();
        }
    }


    console.log("RoleID=====================",RoleId)


    // DELETE API

    const Delete = (email) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete it",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteAccount(email)
            }
        })

    }


    const DeleteAccount = (email) => {
        // console.log("token==>",Token)

        setLoader(true);
        var formdata = new FormData();
        formdata.append("email", email);

        DeleteApi(`${BaseUrl.Super_Admin_Url}/addAdminAccount`, formdata, Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    console.log("dELETE==>", data)
                    ViewAllAdminAccounts()
                    // handleClose2()

                    Swal.fire({
                        title: "success",
                        text: data.message,
                        icon: "success"
                    })
                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })
    }


    return (
        <>
            {Loader ? <div class="loader"></div> : null}
            <div id="app">
                <SuperAdminHeader />
                <div class="main-wrapper main-wrapper-1">
                    <div className="main-content">
                        <section className="section">
                            <ul className="breadcrumb breadcrumb-style" >
                                <li className="">
                                    <h4 className="page-title m-b-0">Admin Accounts</h4>
                                </li>

                                {/* <li className="breadcrumb-item active"></li> */}
                                <div class="row">
                                    <div class="col-md-12  text-right">
                                        <button onClick={() => handleShow()} className="btn btn-outline-primary">Add Admin Accounts</button>

                                    </div>
                                </div>
                            </ul>

                            <div style={{ maxWidth: '100%' }}>
                                <MaterialTable
                                    data={GetAccounts}
                                    title=""
                                    columns={[

                                        { title: "First Name", field: "fname" },
                                        { title: "Last Name", field: "lname" },
                                        { title: "Email", field: "email", type: "string" },
                                        { title: "Contact NO", field: "contactNo", type: "numeric" },
                                        { title: "Role", field: "role", },
                                        { title: "Package ", field: "PackName", },

                                        // { title: "Profile Image", field: "profile" },


                                    ]}


                                    onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                    options={{
                                        rowStyle: rowData => ({
                                            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                        }),
                                        actionsColumnIndex: -1,
                                    }}
                                    // other props
                                    actions={[
                                        rowData => ({
                                            icon: 'edit',
                                            tooltip: 'Edit User',

                                            onClick: () => {
                                                // console.log("PackName====>",PackName)
                                                // console.log("PackId====>",PackId)
                                                // console.log("edit ", rowData)
                                                handleShow2()
                                                SetEmail(rowData.email)
                                                SetFname(rowData.fname)
                                                SetLname(rowData.lname)
                                                SetContact(rowData.contactNo)
                                                // SetPassword(rowData.password)
                                                setprofileimage(rowData.profile)
                                                SetUstatus(rowData.AdminStatus)
                                                SetRole(rowData.PackName)
                                                SetRoleID(rowData.Packid)



                                            }

                                        }),

                                        rowData => ({
                                            icon: 'delete',
                                            tooltip: 'Delete User',
                                            onClick: () => {
                                                // console.log("rowData2==>", rowData)

                                                Delete(rowData.email)
                                                // setDeleteId()
                                            }
                                        })

                                    ]}
                                    options={{
                                        maxBodyHeight: 400,
                                        overflowX: 'x-scroll',
                                        actionsColumnIndex: 7,
                                        headerStyle: {
                                            backgroundColor: '#222',
                                            color: "white"
                                        }
                                    }}
                                />


                            </div>


                        </section>

                    </div>
                    <SuperAdminFooter />
                </div>
            </div>



            {/* Modal 1 Add   */}


            <Modal className="btn-close"
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg"
                show={show}
                onHide={handleClose}>
                <Modal.Header >
                    <Modal.Title>Add New Admin Account</Modal.Title>
                    <i onClick={handleClose} style={{ fontSize: "24px" }} className=" fa-10X fas fa-window-close"></i>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-row text-center " style={{ display: "block" }}>
                            <div className="row">
                                <div className="col-md-12 ">
                                    <center>
                                        <div className="upload_photo_main">
                                            <input onChange={(e) => handleInputFileChange(e, SetAddSubProfile)}
                                                accept="image/*"
                                                className={classes.input}
                                                id="icon-button-file"
                                                type="file"
                                            />
                                            <label htmlFor="icon-button-file">
                                                <IconButton
                                                    color="#414141"
                                                    aria-label="upload picture"
                                                    component="span"
                                                    className="upload_photo_main"
                                                >
                                                    <img style={{ minWidth: "120px", maxWidth: "120px", borderRadius: "20px" }}
                                                        src={Selectedprofile ? Selectedprofile : 'assets/img/cam.jpg'}
                                                        className="upload_photo_main"
                                                    />
                                                </IconButton>
                                            </label>
                                        </div>
                                    </center>
                                    <div>
                                        {Object.keys(profileErr).map((key) => {
                                            return <div style={{ color: "red" }}>{profileErr[key]}</div>
                                        })}
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="form-row mt-4">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputFN">First Name</label>
                                <input value={AddFName} onChange={e => handleInputChange(e, SetAddFName)} type="text" className="form-control" id="inputFN" placeholder="Enter Your First Name" />
                                <div>
                                    {Object.keys(fnameErr).map((key) => {
                                        return <div style={{ color: "red" }}>{fnameErr[key]}</div>
                                    })}
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputLN">Last Name</label>
                                <input value={AddLName} onChange={e => handleInputChange(e, SetAddLName)} type="text" className="form-control" id="inputLN" placeholder="Enter Your Last Name" />
                                <div>
                                    {Object.keys(lnameErr).map((key) => {
                                        return <div style={{ color: "red" }}>{lnameErr[key]}</div>
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputEmail">Email</label>
                                <input value={AddEmail} onChange={e => handleInputChange(e, SetAddEmail)} type="email" className="form-control" id="inputEmail" placeholder="Email " />
                                <div>
                                    {Object.keys(emailErr).map((key) => {
                                        return <div style={{ color: "red" }}>{emailErr[key]}</div>
                                    })}
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputPS">Password</label>
                                <input value={AddPassword} onChange={e => handleInputChange(e, SetAddPassword)} type="password" className="form-control" id="inputPS" placeholder="Password" />
                                <div>
                                    {Object.keys(passwordErr).map((key) => {
                                        return <div style={{ color: "red" }}>{passwordErr[key]}</div>
                                    })}
                                </div>
                            </div>

                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputCN">Contact Number</label>
                                <input value={AddContact} onChange={e => handleInputChange(e, SetAddContact)} type="number" min="0" max="11111111111111" className="form-control" id="inputCN" placeholder="Contact Number " />
                                <div>
                                    {Object.keys(contactErr).map((key) => {
                                        return <div style={{ color: "red" }}>{contactErr[key]}</div>
                                    })}
                                </div>
                            </div>
                            <div class="form-group col-md-6 ">
                                <label for="exampleFormControlSelect1">Select package</label>
                                <select onChange={(e) => handleInputChange(e, SetPackage)} class="form-control" id="exampleFormControlSelect1">
                                    {AllPackages.map((e) => (
                                        <option value={e.PackId}>{e.PackName}</option>
                                    ))}
                                </select>
                                <div>
                                    {Object.keys(pakageErr).map((key) => {
                                        return <div style={{ color: "red" }}>{pakageErr[key]}</div>
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className="form-row">
                            <div class="form-group col-md-6 ">
                                <label for="exampleFormControlSelect1">Status</label>
                                <select value={statusA} onChange={(e) => SetstatusA(e.target.value)} class="form-control" id="exampleFormControlSelect1">

                                    <option>Select Status...</option>

                                    <option value="Active" >Active</option>
                                    <option value="Disable"  >Disable</option>


                                </select>
                                <div>
                                    {Object.keys(statusErr).map((key) => {
                                        return <div style={{ color: "red" }}>{statusErr[key]}</div>
                                    })}
                                </div>
                            </div>

                        </div>


                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => AddNewAccount()} disabled={Disable}>
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Modal two Edit */}

            <Modal
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg"
                show={show2}
                onHide={handleClose2}>
                <Modal.Header   >
                    <Modal.Title>Edit Account</Modal.Title>
                    <i onClick={handleClose2} style={{ fontSize: "24px" }} className=" fa-10X fas fa-window-close"></i>
                </Modal.Header>
                <Modal.Body>
                    {/* Woohoo, you're reading this text in a modal! */}
                    <form>
                        <div className="form-row text-center " style={{ display: "block" }}>


                            <div className="row">
                                <div className="col-md-12 ">
                                    <center>
                                        <div className="upload_photo_main">
                                            <input
                                                onChange={e => handleInputFileChange(e, setprofileimage)}
                                                accept="image/*"
                                                className={classes.input}
                                                id="icon-button-file"
                                                type="file"

                                            />
                                            <label htmlFor="icon-button-file">
                                                <IconButton
                                                    color="#414141"
                                                    aria-label="upload picture"
                                                    component="span"
                                                    className="upload_photo_main"
                                                >
                                                    <img src={Selectedprofile ?   Selectedprofile   : (`${(profileimage)}` !== "https://backend.fridaymart.mlnull" ? `${(profileimage)}` : 'assets/img/cam.jpg')}

                                                        style={{ minWidth: "120px", maxWidth: "120px", borderRadius: "20px" }} className="upload_photo_main" />
                                                </IconButton>
                                            </label>
                                        </div>
                                    </center>
                                    <div>
                                        {Object.keys(upprofileErr).map((key) => {
                                            return <div style={{ color: "red" }}>{upprofileErr[key]}</div>
                                        })}
                                    </div>
                                </div>
                            </div>




                        </div>
                        <div className="form-row mt-4">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputFN">First Name</label>
                                <input value={Fname} onChange={e => handleInputChange(e, SetFname)} type="text" className="form-control" id="inputFN" placeholder="Enter Your First Name" />
                                <div>
                                    {Object.keys(upfnameErr).map((key) => {
                                        return <div style={{ color: "red" }}>{upfnameErr[key]}</div>
                                    })}
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputLN">Last Name</label>
                                <input value={Lname} onChange={e => handleInputChange(e, SetLname)} type="text" className="form-control" id="inputLN" placeholder="Enter Your Last Name" />
                                <div>
                                    {Object.keys(uplnameErr).map((key) => {
                                        return <div style={{ color: "red" }}>{uplnameErr[key]}</div>
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputEmail">Email</label>
                                <input  disabled value={Email} onChange={e => handleInputChange(e, SetEmail)} type="email" className="form-control" id="inputEmail" placeholder="Email " />
                                <div>
                                    {Object.keys(upemailErr).map((key) => {
                                        return <div style={{ color: "red" }}>{upemailErr[key]}</div>
                                    })}
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputP">Password</label>
                                <input value={Password} type="password" onChange={e => handleInputChange(e, SetPassword)} className="form-control" id="inputP" placeholder="" disabled />
                                {/* <div>
                                    {Object.keys(uppasswordErr).map((key) => {
                                        return <div style={{ color: "red" }}>{uppasswordErr[key]}</div>
                                    })}
                                </div> */}
                            </div>

                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputCN">Contact Number</label>
                                <input value={Contact} onChange={e => handleInputChange(e, SetContact)} type="number" min="0" max="11111111111111" className="form-control" id="inputCN" placeholder="Contact Number " />
                                <div>
                                    {Object.keys(upcontactErr).map((key) => {
                                        return <div style={{ color: "red" }}>{upcontactErr[key]}</div>
                                    })}
                                </div>
                            </div>
                            <div class="form-group col-md-6 ">
                                <label for="exampleFormControlSelect1">Select Package</label>
                                <select value={Role} onChange={(e) => { handleInputChange(e, SetRole); SetAdminPackage("True") ;handleInputChange(e, SetRoleID)}} class="form-control" id="exampleFormControlSelect1">
                                {/* onClick={(e) => handleInputChange(e, SetRoleID)} */}
                                    {AllPackages.map((a) => (
                                        <option key={a.PackId} value={a.PackId} >
                                            {a.PackName}
                                        </option>
                                    ))}

                                </select>
                                <div>
                                    {Object.keys(uppakageErr).map((key) => {
                                        return <div style={{ color: "red" }}>{uppakageErr[key]}</div>
                                    })}
                                </div>
                            </div>

                        </div>
                        <div className="form-row">
                            <div class="form-group col-md-6 ">
                                <label for="exampleFormControlSelect1">Status</label>
                                <select value={Ustatus} onChange={(e) => SetUstatus(e.target.value)} class="form-control" id="exampleFormControlSelect1">
                                         {/* <option>Select Status...</option> */}
                                    <option value="Active" >Active</option>
                                    <option value="Disable" >Disable</option>
                                </select>
                                <div>
                                    {Object.keys(updatesErr).map((key) => {
                                        return <div style={{ color: "red" }}>{updatesErr[key]}</div>
                                    })}
                                </div>
                            </div>

                        </div>

                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose2}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => UpdateAccount()}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>




        </>
    )
}