import React, { useState, useEffect } from "react"
import Footer from '../../components/footer/footer'
import Header from '../../components/header/header'
import './dashboard.css'

export default function Dashboard() {

    return (
        <>
            {/* <div className="loader" /> */}
            <div id="app">
                <div class="main-wrapper main-wrapper-1">
                    <Header />
                    <div className="main-content">
                        <section className="section">
                            <div class="row">
                                <div class="col colBg" id=" colA">
                                    <div class="row rowBg" style={{ paddingRight: 25 }}>
                                        <div class="dropdown">
                                            <button class="btn dropdown-toggle ddstyle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Your Orders
                                            </button>
                                            <div class="dropdown-menu ddstyle" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" href="#">Action</a>
                                                <a class="dropdown-item" href="#">Another action</a>
                                                <a class="dropdown-item" href="#">Something else here</a>
                                            </div>
                                        </div>
                                        <div className="row performacerow performancerow2">
                                            <span>Pending</span>
                                            <span>0</span>
                                        </div>
                                        <div className="row performacerow performancerow2">
                                            <span>Preminum unshipped</span>
                                            <span>0</span>
                                        </div>
                                        <div className="row performacerow performancerow2">
                                            <span>Unshipped</span>
                                            <span>0</span>
                                        </div>
                                        <div className="row performacerow performancerow2">
                                            <span>Return requests</span>
                                            <span>0</span>
                                        </div>
                                        <div style={{ paddingTop: 10, paddingLeft: 30 }}>
                                            <div className="row">
                                                <div className="h6 text-dark">Seller Fulfilled</div>
                                            </div>
                                        </div>
                                        <div className="row performancerow3">
                                            <span>In last day</span>
                                            <span>0</span>
                                        </div>
                                        <div className="row performancerow3" id="singleline">
                                            <span>In last 7 days</span>
                                            <span>0</span>
                                        </div>
                                        <div style={{ paddingTop: 10, paddingLeft: 30 }}>
                                            <div className="row">
                                                <div className="h6 text-dark">Fulfilled by Babylon</div>
                                            </div>
                                        </div>
                                        <div className="row performancerow3">
                                            <span>In last day</span>
                                            <span>0</span>
                                        </div>
                                        <div className="row performancerow3" id="singleline">
                                            <span>In last 7 days</span>
                                            <span>0</span>
                                        </div>
                                        <div className="row performancerow4">
                                            <a href="#">View Your Orders</a>
                                        </div>
                                    </div>
                                    <div class="row rowBg" style={{ paddingRight: 25 }}>
                                        <div class="dropdown">
                                            <button class="btn dropdown-toggle ddstyle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Performance
                                            </button>
                                            <div class="dropdown-menu ddstyle" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" href="#">Action</a>
                                                <a class="dropdown-item" href="#">Another action</a>
                                                <a class="dropdown-item" href="#">Something else here</a>
                                            </div>
                                        </div>
                                        <div className="row performacerow performancerow2">
                                            <span>Buyer Messages</span>
                                            <span>0</span>
                                        </div>
                                        <div className="row performacerow performancerow2">
                                            <span>A-to-Z Gaurantee claims</span>
                                            <span>0</span>
                                        </div>
                                        <div className="row performacerow performancerow2">
                                            <span>Changable claims</span>
                                            <span>0</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 colBg" id="colB">
                                    <div class="row rowBg">
                                        <div class="dropdown">
                                            <button class="btn dropdown-toggle ddstyle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Notifications
                                            </button>
                                            <div class="dropdown-menu ddstyle" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" href="#">Action</a>
                                                <a class="dropdown-item" href="#">Another action</a>
                                                <a class="dropdown-item" href="#">Something else here</a>
                                            </div>
                                        </div>
                                        <div class="notificationrow">
                                            <span style={{ color: "darkred", fontSize: 16, fontWeight: "bold" }}>Fulfillment by Babylon Anouncement</span> Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi molestias error, consequuntur, minima distinctio suscipit reprehenderit, voluptate facere tenetur id fugit. Illum placeat consequatur quisquam dolore libero aliquam facere assumenda. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ex totam aut reprehenderit, magnam beatae porro quaerat hic tempora harum labore quod, eum quia temporibus ut, quas dolor perferendis quam tenetur. <a href="#">Learn more</a>.
                                        </div>
                                    </div>
                                    <div class="row rowBg" style={{ paddingLeft: 18, paddingRight: 18 }}>
                                        <div className="row">
                                            <div class="dropdown">
                                                <button class="btn dropdown-toggle ddstyle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    Babylon Selling Coach
                                                </button>
                                                <div class="dropdown-menu ddstyle" aria-labelledby="dropdownMenuButton">
                                                    <a class="dropdown-item" href="#">Action</a>
                                                    <a class="dropdown-item" href="#">Another action</a>
                                                    <a class="dropdown-item" href="#">Something else here</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="sideNavselling">
                                            <div class="nav flex-column nav-tabs text-dark col-2" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                <a class="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">Home</a>
                                                <a class="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">Profile</a>
                                                <a class="nav-link" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">Messages</a>
                                                <a class="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">Settings</a>
                                            </div>
                                            <div class="tab-content" id="v-pills-tabContent">
                                                <div class="tab-pane fade show active text-dark" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                                    <div>
                                                        <div class="row" style={{ paddingLeft: 30, fontWeight: "bold", fontSize: 18 }}>What type of product would you like to sell?</div>
                                                        <p style={{ paddingLeft: 15, lineHeight: 1.5 }}>Enter you preferences below so we can help you discover popular products with few offers</p>
                                                    </div>
                                                    <div className="row" style={{ paddingLeft: 15 }}>
                                                        <div className="col-6">
                                                            <p style={{ fontWeight: "bold", lineHeight: 1.5 }}>Show me product recommandations related to these brand:</p>
                                                        </div>
                                                        <div className="col-6" style={{ paddingRight: 35 }}>
                                                            <input type="text" style={{ height: 35 }} class="form-control inputbord" aria-label="Text input with checkbox" />
                                                            <p style={{lineHeight: 1, fontSize: 13, marginTop: 3}}>Use semicolons to separate brands:</p>
                                                        </div>
                                                        <div className="col-6">
                                                            <p style={{ fontWeight: "bold", lineHeight: 1.5 }}>Show me product recommandations related to these category:</p>
                                                        </div>
                                                        <div className="col-6" style={{ paddingRight: 35 }}>
                                                            <div class="dropdown">
                                                                <button class="btn btn-outline-dark dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    Product Categories
                                                                </button>
                                                                <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                                    <button class="dropdown-item" type="button">Action</button>
                                                                    <button class="dropdown-item" type="button">Another action</button>
                                                                    <button class="dropdown-item" type="button">Something else here</button>
                                                                </div>
                                                            </div>
                                                            <p style={{lineHeight: 1, fontSize: 13, marginTop: 3}}>Hold key to select multiple categories</p>
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ paddingLeft: 30 }}>
                                                        <button type="button" class="btn btn-dark">Save Preferences</button>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consequuntur odio magni aspernatur facere et a vitae laudantium sapiente quis eius? Natus unde, fugiat quis accusamus ducimus saepe aliquid mollitia facilis?</div>
                                                <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Praesentium nostrum quos nobis voluptatum adipisci odio neque exercitationem numquam. Ducimus quis maiores corporis facilis labore at pariatur saepe fuga doloribus excepturi.</div>
                                                <div class="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id in et ab quae doloribus sapiente, ratione tenetur, vero amet dolorum assumenda pariatur fugit explicabo, quis reiciendis incidunt cupiditate voluptatem accusamus?</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row rowBg" style={{ paddingRight: 25 }}>
                                        <div class="dropdown">
                                            <button class="btn dropdown-toggle ddstyle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Headlines
                                            </button>
                                            <div class="dropdown-menu ddstyle" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" href="#">Action</a>
                                                <a class="dropdown-item" href="#">Another action</a>
                                                <a class="dropdown-item" href="#">Something else here</a>
                                            </div>
                                        </div>
                                        <div className="row performancerow3 headlines">
                                            <span style={{ fontSize: 16, color: "black" }}>Free Inventory Removal for a Limited Time</span>
                                            <span style={{ fontSize: 14, color: "black", fontWeight: "bold" }}>Sep 27, 2021</span>
                                        </div>

                                    </div>
                                </div>
                                <div class="col colBg" id="colC">
                                    <div class="row rowBg" style={{ paddingRight: 25 }}>
                                        <div class="dropdown">
                                            <button class="btn dropdown-toggle ddstyle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Payments Summary
                                            </button>
                                            <div class="dropdown-menu ddstyle" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" href="#">Action</a>
                                                <a class="dropdown-item" href="#">Another action</a>
                                                <a class="dropdown-item" href="#">Something else here</a>
                                            </div>
                                        </div>
                                        <div style={{ paddingTop: 10, paddingLeft: 30 }}>
                                            <div className="row">
                                                <div className="h6 text-dark">Most Recent Payment</div>
                                            </div>
                                        </div>
                                        <div className="row notificationrow1">
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero, asperiores fuga.
                                        </div>
                                        <div className="row performancerow2" id="doubleline">
                                            <span style={{ color: "black" }}>Balance</span>
                                            <span style={{ color: "blue" }}>$0.00</span>
                                        </div>
                                        <div className="row performancerow4">
                                            <a href="#">View Your Orders</a>
                                        </div>
                                    </div>
                                    <div class="row rowBg" style={{ paddingRight: 25 }}>
                                        <div class="dropdown">
                                            <button class="btn dropdown-toggle ddstyle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Manage Your Case Log
                                            </button>
                                            <div class="dropdown-menu ddstyle" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" href="#">Action</a>
                                                <a class="dropdown-item" href="#">Another action</a>
                                                <a class="dropdown-item" href="#">Something else here</a>
                                            </div>
                                        </div>
                                        <div className="row performancerow4">
                                            <a href="#">View Your Orders</a>
                                        </div>
                                    </div>
                                    <div class="row rowBg" style={{ paddingRight: 25 }}>
                                        <div class="dropdown">
                                            <button class="btn dropdown-toggle ddstyle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Sales Summary
                                            </button>
                                            <div class="dropdown-menu ddstyle" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" href="#">Action</a>
                                                <a class="dropdown-item" href="#">Another action</a>
                                                <a class="dropdown-item" href="#">Something else here</a>
                                            </div>
                                        </div>
                                        <div style={{ marginLeft: 15, fontSize: 12, marginTop: -5 }}>Last updated 12/12/21 1:23:59 PDT</div>
                                        <div className="row performancerow4 h6 text-dark" style={{ marginTop: 0, marginBottom: 0 }}>
                                            Order product
                                        </div>
                                        <div className="row performancerow2" id="singleline" style={{ padding: 2 }}>
                                            <span class="tableitem">Today</span>
                                            <span class="tableitembold">$0.00</span>
                                            <span class="tableitembold">0</span>
                                        </div>
                                        <div className="row performancerow2" id="singleline">
                                            <span class="tableitem">7 Days</span>
                                            <span class="tableitembold">$0.00</span>
                                            <span class="tableitembold">0</span>
                                        </div>
                                        <div className="row performancerow2" id="singleline">
                                            <span class="tableitem">15 Days</span>
                                            <span class="tableitembold">$0.00</span>
                                            <span class="tableitembold">0</span>
                                        </div>
                                        <div className="row performancerow2">
                                            <span class="tableitem">30 Days</span>
                                            <span class="tableitembold">$0.00</span>
                                            <span class="tableitembold">0</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}