import React, { useState, useEffect } from "react";
import Header from '../../../components/header/header'
import Footer from '../../../components/footer/footer'
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2'
import BaseUrl from "../../../../config/BaseUrl"
import MaterialTable from 'material-table'
import image_url from "../../../../config/BaseUrl"
import { Link, NavLink, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { getApiWithToken, PutApi, PostApi, DeleteApi } from "../../../../config/Api/FakeuserApi";
import { RemoveRedEye } from "@material-ui/icons"
import { style } from "@mui/system";
import './style.css';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    input: {
        display: "none",
    },
}));




export default function ManageProductimg() {

    // const [GetAccounts, SetGetAccounts] = useState([])
    const [Loader, setLoader] = useState(false)

    const [selectedRow, setSelectedRow] = useState(null);
    const [manageimg, Setmanageimg] = useState([]);
    const [Active, SetActive] = useState(true);
    const [buttonshow, Setbuttonshow] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [verifyImgObj, setVerifyImgObj] = useState([]);
    const [isshow, setisShow] = useState(false);
    const [id, setid] = useState("");
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const [productid, Setproductid] = useState("");




    // console.log("hello image",selectimg)








    const Token = localStorage.getItem('SellerToken');


    useEffect(() => {
        ManageImages()

    }, [])






    // console.log("selectimg",selectimg)


    const ManageImages = () => {

        setLoader(true);

        getApiWithToken(`${BaseUrl.base_url}/ManageImage`, "", Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    console.log("manageimag", data)
                    Setmanageimg(data.products)


                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })



    }


    // DELETE SPECIFIC IMG

    // DELETE API

    //    const Delete = (id) => {
    //     Swal.fire({
    //         title: 'Are you sure?',
    //         text: "You want to delete it",
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Yes, delete it!'
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             DeleteImg(id)
    //         }
    //     })

    // }


    const DeleteImg = (id) => {
        console.log("id=================>", id)
        // console.log("token==>",Token)

        setLoader(true);
        // var formdata = new FormData();
        // formdata.append("id", id);

        DeleteApi(`${BaseUrl.base_url}/ManageImage?id=${id}`, "", Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    console.log("dELETEimg==>", data)
                    ManageImages()

                    Swal.fire({
                        title: "success",
                        text: data.message,
                        icon: "success"
                    })
                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })
    }


    function hoverfunc(id) {



        document.getElementById("edit" + id).classList.remove('d-none')
        document.getElementById("del" + id).classList.remove('d-none')

    }

    function mouseDown(id) {


        document.getElementById("edit" + id).classList.add('d-none')
        document.getElementById("del" + id).classList.add('d-none')

    }
    // console.log("productid",productid)


    const Imageopen = (e, pid) => {

        console.log("pid", pid)

        // ADCategory API

        setLoader(true);
        var formdata = new FormData();

        formdata.append("Productid", pid);
        for (let i = 0; i < e.length; i++) {
            console.log("img is ", e[i])
            formdata.append('image', e[i])
        }


        PostApi(`${BaseUrl.base_url}/ManageImage`, formdata, Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    console.log("imgopen==>", data)
                    ManageImages()


                    Swal.fire({
                        title: "success",
                        text: data.message,
                        icon: "success"
                    })
                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })






    }





    return (
        <>
            {Loader ? <div className="loader" /> : null}
            <div id="app">
                <div class="main-wrapper main-wrapper-1">
                    <Header />
                    <div className="main-content">
                        <section className="section">
                            <div class="row">
                                <div className="col-md-12">
                                    <div className="card card-primary">
                                        <div className="card-header">
                                            <h4>Manage Product Image</h4>
                                        </div>
                                        <div className="card-body">
                                            <section className="section">
                                                <div className="section-body">
                                                    <div className="row clearfix">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <div className="card">
                                                                {/* <div className="card-header">
                                                                    <h4>Vertical Layout</h4>
                                                                </div> */}
                                                                <div className="card-body explainBlock">
                                                                    <i style={{ fontSize: 16 }} class="fas fa-exclamation-circle fa-lg"></i>
                                                                    <span style={{ fontSize: 16, fontWeight: "bold" }}> Tips: one product must have sku image or item image.</span>
                                                                </div>
                                                                {/* <div className="card-body">


                                                                    <button type="button" class="expTopbar btn btn-outline-primary" >SKU images  </button>
                                                                    <button type="button" class="expTopbar btn btn-outline-primary">Item Images</button>
                                                                    <button type="button" class="expTopbar btn btn-outline-primary">Image Missing</button>



                                                                </div> */}

                                                                <div className="card-body" style={{ paddingTop: 0 }}>

                                                                    <div className="card-body" style={{ padding: 5 }}>
                                                                        {/* <div class="row" style={{ marginLeft: 10 }}>
                                                                            <div class="col-1 onlineNav">
                                                                                <div class="dropdown" style={{ marginTop: "5px" }}>
                                                                                    <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                        Export
                                                                                    </button>
                                                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                                        <a class="dropdown-item" href="#">SKU image</a>
                                                                                        <a class="dropdown-item" href="#">Image Missing</a>

                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            <div class="col-2 onlineNav">
                                                                                <input type="text" class="form-control" id="autoSizingInput" placeholder="  Brand" />
                                                                            </div>
                                                                            <div class="col-2 onlineNav">
                                                                                <input type="text" class="form-control" id="autoSizingInput" placeholder="Product or SKU" />
                                                                            </div>

                                                                            <div class="col-2 onlineNav">
                                                                                <button style={{ height: 42 }} type="search" class="expTopbar btn btn-outline-secondary">Search</button>
                                                                            </div>
                                                                        </div> */}

                                                                        <div className="mt-4" style={{ maxWidth: '100%' }}>
                                                                            <MaterialTable
                                                                                data={manageimg}
                                                                                title=""
                                                                                columns={[



                                                                                    { title: 'Product_Name', field: 'Product_Name' },


                                                                                    {
                                                                                        title: ' Images', field: "images", render: ({ images }) => images.map((e) =>
                                                                                            <div onMouseOver={() => hoverfunc(e.imageid)} onMouseOut={() => mouseDown(e.imageid)} style={{ display: "inline-block" }}>
                                                                                                <span style={{ position: "relative", margin: "10px" }}>
                                                                                                    <img src={(e.images)} alt="" border="3" height="70" width="70" />
                                                                                                    <div>
                                                                                                        <i onClick={() => DeleteImg(e.imageid)} id={"del" + e.imageid} className="fas fa-trash-alt d-none mx-3" style={{ fontSize: "12px" }}></i >
                                                                                                        <i id={"edit" + e.imageid} onClick={() => handleShow2()} className="d-none mt-1" style={{ fontSize: "12px", float: "right" }}></i>
                                                                                                    </div>

                                                                                                </span></div>), width: "100%",
                                                                                    },
                                                                                    {
                                                                                        title: "Upload Images", field: "products", render: rowData => <div> <label style={{ marginBottom: 0 }} htmlFor={"file" + rowData.Productid} className="label">
                                                                                            <i style={{ fontSize: 35 }} className="material-icons">add_a_photo</i>
                                                                                        </label> <input accept="image/*" multiple type="file" id={"file" + rowData.Productid} hidden  onChange={(e) => Imageopen(e.target.files, rowData.Productid)}  /> </div>
                                                                                  }





                                                                                ]}

                                                                                onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                                                                options={{
                                                                                    rowStyle: rowData => ({
                                                                                        selectableRow: false,
                                                                                        backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                                                                    }),
                                                                                    actionsColumnIndex: -1,
                                                                                }}

                                                                                options={{
                                                                                    maxBodyHeight: 400,
                                                                                    overflowX: 'x-scroll',
                                                                                    actionsColumnIndex: 7,
                                                                                    headerStyle: {
                                                                                        backgroundColor: '#222',
                                                                                        color: "white"
                                                                                    }
                                                                                }}


                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>


            {/* Modal 2 edit category  */}


            <Modal
                size="sm"
                aria-labelledby="example-modal-sizes-title-lg"
                show={show2}
                onHide={handleClose2}>
                <Modal.Header   >
                    <Modal.Title>Add Image</Modal.Title>
                    <i onClick={handleClose2} style={{ fontSize: "24px" }} className=" fa-10X fas fa-window-close"></i>
                </Modal.Header>
                <Modal.Body>

                    <form>

                        <div className="form-row mt-4">
                            <div className="form-group col-md-12">
                                {/* <label htmlFor="inputAR">Edit Category</label> */}
                                <input multiple type="file" className="form-control" id="inputAR" placeholder=" Add image" />


                            </div>

                        </div>


                    </form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose2}>
                        Cancel
                    </Button>
                    <Button variant="primary"    >
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}