import React,{useState,useEffect} from "react"
import Header from '../../../components/header/header';
import Footer from '../../../components/footer/footer';
import Swal from 'sweetalert2'
import BaseUrl from "../../../../config/BaseUrl"
import { getApiWithToken, PutApi, PostApi, DeleteApi } from "../../../../config/Api/FakeuserApi";




export default function NewDashboard() {

    const [Loader, setLoader] = useState(false)
    const [Sdash, setSdash] = useState([])
    // console.log("Sdash",Sdash)
    const Token = localStorage.getItem('SellerToken');

    useEffect(() => {

        GetSellerDashboard()
    }, [

    ])


    const GetSellerDashboard = () => {
        setLoader(true);


        getApiWithToken(`${BaseUrl.base_url}/SellerDashboardDetail`, "", Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    setSdash(data)
                    console.log("Sdash==>", data)
                    // setdisable(true);
                    // SetUstatus
                    // $(".loader").hide();

                } else {
                    // $(".loader").hide();
                    // Swal.fire({
                    //     title: "Opps",
                    //     text: data.message,
                    //     icon: "error"
                    // })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })

    }






    return (
        <>
            {Loader ? <div className="loader" /> : null}

            <div id="app">
                <Header/>
                <div class="main-wrapper main-wrapper-1">
                    <div className="main-content">
                        <section className="section">
                            <ul className="breadcrumb breadcrumb-style ">
                                <li className="breadcrumb-item">
                                    <h4 className="page-title m-b-0">Dashboard</h4>
                                </li>

                            </ul>
                            <div className="row">
                            <div className="col-lg-4 col-sm-6">
                                    <div className="card">
                                        <div className="card-statistic-5">
                                            <div className="info-box7-block">
                                                <div className="row ">
                                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                        <div className="banner-img">
                                                            <img src="assets/img/banner/1.png" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                        <h6 className="m-b-20 text-right">Active Products </h6>
                                                        <h4 className="text-right">{Sdash.activeProducts}<span>
                                                        </span>
                                                        </h4>
                                                    </div>
                                                </div>
                                                {/* <div id="cardChart2" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="card">
                                        <div className="card-statistic-5">
                                            <div className="info-box7-block">
                                                <div className="row ">
                                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                        <div className="banner-img">
                                                            <img src="assets/img/banner/vendors.png" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                        <h6 className="m-b-20 text-right">Pending Products</h6>
                                                        <h4 className="text-right"><span>{Sdash.pendingProducts}</span>
                                                        </h4>
                                                    </div>
                                                </div>
                                                {/* <div id="cardChart1"/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="card">
                                        <div className="card-statistic-5">
                                            <div className="info-box7-block">
                                                <div className="row ">
                                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                        <div className="banner-img">
                                                            <img src="assets/img/banner/active.png" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                        <h6 className="m-b-20 text-right">Total Products</h6>
                                                        <h4 className="text-right"><span>{Sdash.TotalProducts}</span>
                                                        </h4>
                                                    </div>
                                                </div>
                                                {/* <div id="cardChart1"/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">

                                    <div className="card">
                                        <div className="card-statistic-5">
                                            <div className="info-box7-block">
                                                <div className="row ">
                                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                        <div className="banner-img">
                                                            <img src="assets/img/banner/2.png" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                        <h6 className="m-b-20 text-right">Pending Orders </h6>
                                                        <h4 className="text-right"><span>{Sdash.pendingOrders}</span>
                                                        </h4>
                                                    </div>
                                                </div>
                                                {/* <div id="cardChart4" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="card">
                                        <div className="card-statistic-5">
                                            <div className="info-box7-block">
                                                <div className="row ">
                                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                        <div className="banner-img">
                                                            <img src="assets/img/banner/4.png" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                        <h6 className="m-b-20 text-right">Active Orders</h6>
                                                        <h4 className="text-right"><span>{Sdash.activeOrders}</span>
                                                        </h4>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="card">
                                        <div className="card-statistic-5">
                                            <div className="info-box7-block">
                                                <div className="row ">
                                                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                        <div className="banner-img">
                                                            <img src="assets/img/banner/4.png" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                                        <h6 className="m-b-20 text-right">Total Orders</h6>
                                                        <h4 className="text-right"><span>{Sdash.totalorders}</span>
                                                        </h4>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </section>

                    </div>
<Footer/>
                </div>
            </div>


        </>
    )
}