export default function reducer(
    state = {

        EmailVarificationCode:"",
        MobileverificationCode:""

    },
    action
) {
    console.log("action===",action);
    switch (action.type) {
        case 'SET_VALUE': {
            return {
                ...state,
                [action.key]: action.value,
            }
        }

        case 'EMAIL_OR_PHONE_CHECKED':{
            return{
                ...state,
                EmailVarificationCode: action.payload['EmaiVerification Code'],
                MobileverificationCode: action.payload["Mobile verification Code"]
            }
        }

        case 'CLEAR_PROCESSING': {
            return {
                ...state,
                loading: false
            }
        }
    }
    return state
}