
import BaseUrl from "../../../config/BaseUrl"
import { postApi } from "../../../config/Api/FakeuserApi";
import Swal from 'sweetalert2'


export const setValue = (key, value) => {
    return (dispatch) => {
        dispatch({ type: "SET_VALUE", key, value })
    }
}




export const checkEmailorPhone = (user) => {
    return async (dispatch) => {
        dispatch({ type: "EMAIL_OR_PHONE_CHECKING" })

        try {
            let { status, data } = await postApi(`${BaseUrl.base_url}/Check_Email_Verification_Code`, user)

            // console.log({ status, data })
            if (data.status) {
                dispatch({ type: "EMAIL_OR_PHONE_CHECKED", payload: data })
                return Promise.resolve({ status: true , EmaiVerificationCode: data["EmaiVerification Code"],MobileverificationCode:data["Mobile verification Code"]})
            } else {
                dispatch({ type: "EMAIL_OR_PHONE_CHECKED", payload: null })
                Swal.fire({
                    title: "Opps",
                    text: data.message,
                    icon: "error"
                })
                return Promise.resolve({ status: false })
            }
        } catch (error) {

            dispatch({ type: "CLEAR_PROCESSING" })
            return Promise.reject({ status: false, error })

        }
    }
}

