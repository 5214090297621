import React, { useState, useEffect } from "react"
import AdminHeader from "../components/adminheader/AdminHeader"
import AdminFooter from "../components/adminfooter/AdminFooter"
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from 'material-table'
import { RemoveRedEye } from "@material-ui/icons"
import Swal from 'sweetalert2'
import BaseUrl from "../../../../config/BaseUrl"
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import IconButton from "@material-ui/core/IconButton"
import { getApiWithToken, PutApi, PostApi, DeleteApi } from "../../../../config/Api/FakeuserApi";

import BootstrapSwitchButton from 'bootstrap-switch-button-react'





const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    input: {
        display: "none",
    },
}));

export default function BrandNames() {
    const [selectedRow, setSelectedRow] = useState(null);

    // // GetAllCategories STATES
    const [GetAllBrandNames, SetGetAllBrandNames] = useState([])
    const [Loader, setLoader] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const classes = useStyles();
    const [SellerId, SetSellerId] = useState("")
    // console.log("SellerId" , SellerId)
    console.log("GetAllBrandNames",GetAllBrandNames)



    // VIEW AllBrandsName API
    const Token = localStorage.getItem('Token');
    useEffect(() => {
        ViewBrandNames()
    }, [])

    const ViewBrandNames = () => {
        setLoader(true);

        getApiWithToken(`${BaseUrl.Admin_Url}/AddBrand`, "", Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    SetGetAllBrandNames(data.data)
                    // console.log("BrandNames==>", data.data[0]['Brand_Name'])
                    // setdisable(true);
                    // $(".loader").hide();

                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })

        }

    // UPDATE STATUS PUT API

    const UpdateStatus = (brandName,status) => {
        setLoader(true);
        var formdata = new FormData();

        formdata.append("Brand_Name", brandName);
        formdata.append("Status","Online");
        // formdata.append("Percentage",EditPercentage);

        PutApi(`${BaseUrl.Admin_Url}/AddBrand`, formdata, Token)
            .then(({ data }) => {
                setLoader(false);
                if (data.status) {
                    console.log("dataupdatestatus==>", data)
                    ViewBrandNames()
                    // handleClose2()
                    Swal.fire({
                        title: "success",
                        text: data.message,
                        icon: "success"
                    })
                } else {
                    // $(".loader").hide();
                    Swal.fire({
                        title: "Opps",
                        text: data.message,
                        icon: "error"
                    })
                }
            }).catch(function (e) {
                // $(".loader").hide();
                Swal.fire({
                    title: "Opps",
                    text: e.message,
                    icon: "error"
                })
            })
        }


    return (
        <>
    {Loader?<div class="loader"></div>:null}
            <div id="app">
                <AdminHeader />
                <div class="main-wrapper main-wrapper-1">
                    <div className="main-content">
                        <section className="section">
                            <ul className="breadcrumb breadcrumb-style ">
                                <li className="breadcrumb-item">
                                    <h4 className="page-title m-b-0"> Subadmin</h4>
                                </li>

                                <li className="breadcrumb-item active">Brand Names </li>


                                {/* <div class="row">
                                    <div class="col-md-12  text-right">
                                        <button   type="button" class="btn btn-primary">Add Category</button>

                                    </div>
                                </div> */}
                            </ul>

                            <div style={{ maxWidth: '100%' }}>
                                <MaterialTable
                                    data={GetAllBrandNames}
                                    title=""
                                    columns={[

                                        {
                                            title: 'Brand Name',
                                            render: rowData => rowData.Brand_Name},
                                            // { title: "Brand_Name", field: "Brand_Name"?"Brand_Name":"" },
                                            { title: "Status", field: "Status" },

                                            {

                                                // console.log("rowData",rowData)
                                                title: 'Switch',
                                                render: rowData => <BootstrapSwitchButton  onChange={()=>UpdateStatus(rowData.Brand_Name,rowData.status)} checked={false} size="md" />
                                            }



                                            // { title: "Percentage ", field: "Percentage", },

                                            //  <Button >Button</Button>




                                        ]}


                                    onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                                    options={{
                                        rowStyle: rowData => ({
                                            backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                                        }),
                                        actionsColumnIndex: -1,
                                    }}
                                    // other props
                                    actions={[
                                        rowData => ({
                                            icon: RemoveRedEye,
                                            tooltip: 'View Detail',
                                            isFreeAction: true,
                                            onClick: (event, rowData) => {
                                                console.log( "Sellerid",rowData)
                                                SetSellerId(rowData.Sellerid)
                                                handleShow()

                                            }
                                            // onClick: () => history.push("/EditSubadmin")
                                            // onClick: () => {

                                            //     handleShow()
                                            // }

                                        }),

                                        // rowData => ({
                                        //     icon: "edit",
                                        //     tooltip: 'Edit User',
                                        //     //   onClick: (event, rowData) => history.push("/EditSubadmin")
                                        //     // onClick: () => history.push("/EditSubadmin")
                                        //     onClick: () => {
                                        //     }
                                        // }),

                                        // rowData => ({
                                        //     icon: 'delete',
                                        //     tooltip: 'Delete User',
                                        //     onClick: () => {
                                        //     }
                                        // })

                                    ]}
                                    options={{
                                        maxBodyHeight: 400,
                                        overflowX: 'x-scroll',
                                        actionsColumnIndex: 7,
                                        headerStyle: {
                                            backgroundColor: '#222',
                                            color: "white"
                                          }
                                      }}
                                />


                            </div>


                        </section>

                    </div>
                    <AdminFooter />
                </div>
            </div>
            <Modal
                scrollable={true}
                size="lg"
                // aria-labelledby="example-modal-sizes-title-lg"
                show={show}
                onHide={handleClose}>
                <Modal.Header >
                    <Modal.Title>Seller Detail</Modal.Title>
                    <hr />
                    <i onClick={handleClose} style={{ fontSize: "24px" }} className=" fa-10X fas fa-window-close"></i>
                </Modal.Header>




                <Modal.Body  >

                    <form>


                        <div className="row">
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>First Name</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerId.First_name}</p>

                            </div>
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Middle Name</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerId.Middle_name ? SellerId.Middle_name : "---"}</p>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Last Name</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerId.last_name}</p>

                            </div>
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Email</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerId.Email}</p>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Store Name</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerId.Store_Name}</p>

                            </div>
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Are you Brand Owner</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerId.Are_you_Brand_Owner}</p>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Do You Goverment registered trademark</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerId.Do_You_Goverment_registered_trademark}</p>

                            </div>
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Registration Date</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerId.Registration_Date}</p>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Account Status</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerId.Account_Status}</p>

                            </div>
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Shop Status</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerId.Shop_Status}</p>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Business Location</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerId.Business_Location}</p>

                            </div>
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Business Type</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerId.Business_Type}</p>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Country of Citizenship</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerId.Country_of_Citizenship}</p>

                            </div>
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Country of Birth </small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerId.Country_of_Birth}</p>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Date Of Birth</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerId.DOB}</p>

                            </div>
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>ExpirationDate</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerId.ExpirationDate}</p>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Proof of Identity</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerId.Proof_of_Identity}</p>

                            </div>
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Country of issue</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerId.Country_of_issue}</p>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Business_address1</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerId.Business_address1}</p>

                            </div>
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Business_address2</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerId.Business_address2}</p>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Business Address Country</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerId.Business_Address_Country}</p>

                            </div>
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Business Address Zipcode</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerId.Business_Address_Zipcode}</p>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Businessadress Country Code</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerId.Businessadress_Country_Code}</p>

                            </div>
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Businessadress_Number</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerId.Businessadress_Number}</p>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Universal_Products</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerId.Universal_Products}</p>

                            </div>
                            <div className="col-md-6">
                                <small style={{ fontSize: "13px" }}>Billing_Address</small>
                                <p style={{ fontSize: "15px", fontWeight: "bold" }}>{SellerId.Billing_Address}</p>

                            </div>
                        </div>



                    </form>
                </Modal.Body>
                <Modal.Footer>

                    {/* <Button variant="primary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" >
                        Update
                    </Button> */}
                </Modal.Footer>
            </Modal>

        </>
    )
}
