import React, { useState, useEffect } from "react"
import Country from "../components/countries/countries";
// import intlTelInput from 'intl-tel-input';

export default function SignUpWizard() {

    return (
        <>
            {/* <div className="loader" /> */}
            <div id="app">
                <section className="section">
                    <div className="container-fluid mt-5">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <img style={{ width: "30%" }} src="assets/img/babylon2.png"></img>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h4>Sign UP Details</h4>
                                    </div>
                                    <div className="card-body">
                                        <section className="section">
                                            <div className="section-body">
                                                <div className="row clearfix">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <div className="card">
                                                            {/* <div className="card-header">
                                                                <h4>Vertical Layout</h4>
                                                            </div> */}
                                                            <div className="card-body">
                                                                <form id="wizard_with_validation" method="POST">
                                                                    <h3>Personal Information</h3>
                                                                    <fieldset>
                                                                        <div className="form-group form-float">
                                                                            <div className="form-line">
                                                                                <label className="form-label">Country Of citizenship</label>
                                                                                <Country />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group form-float">
                                                                            <div className="form-line">
                                                                                <label className="form-label">Country Of birth</label>
                                                                                <Country />
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <div className="form-group form-float">
                                                                                    <div className="form-line">
                                                                                        <label className="form-label">Proof Of Identity</label>
                                                                                        <select className="form-control">
                                                                                            <option>Passport</option>
                                                                                            <option>ID Card</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <label className="form-label"> &nbsp;</label>
                                                                                <input placeholder="Enter your Passport/Id NO" type="number" className="form-control" required />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group form-float">
                                                                            <div className="form-line">
                                                                                <label className="form-label">Country Of issue</label>
                                                                                <Country />
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <div className="form-group form-float">
                                                                            <div className="form-line">
                                                                                <label className="form-label">Business Address</label>
                                                                                <div className="alert alert-secondary" role="alert">
                                                                                    {/* <h4 className="alert-heading">Well done!</h4> */}
                                                                                    {/* <p>Aww yeah, you successfully read this important alert message. This example text is going to run a bit longer so that you can see how spacing within an alert works with this kind of content.</p> */}
                                                                                    {/* <hr /> */}
                                                                                    <div className="row">
                                                                                        <div className="col-lg-1">
                                                                                            <i style={{ fontSize: "55px" }} className="fas fa-exclamation-circle"></i>

                                                                                        </div>
                                                                                        <div className="col-md-11">
                                                                                            <p className="mb-0" style={{ color: 'rgb(82, 80, 80)' }}> We will validate this address by sending you a postcard containing a varification code. To avoid any delays plese enter your address in English characters only.</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <input placeholder="Address Line 1" type="text" className="form-control" required />
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <input placeholder="Address Line 2" type="text" className="form-control" required />
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mt-2">
                                                                            <div className="col-md-6">
                                                                                <Country />
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <input placeholder="Street No" type="text" className="form-control" required />
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <div className="row mt-2">
                                                                            <div className="col-md-6">
                                                                                <input placeholder="Phone Number" type="number" className="form-control" required />
                                                                            </div>
                                                                        </div>
                                                                        {/* <intlTelInput/> */}
                                                                    </fieldset>
                                                                    <h3>Marketplaces</h3>
                                                                    <fieldset>
                                                                        <div className="form-group form-float">
                                                                            <div className="form-line">
                                                                                {/* <label className="form-label">Username*</label>
                                                                                <input type="text" className="form-control" name="username" required /> */}
                                                                                <h3 className="mb-0">Marketplace specific details</h3>
                                                                                <p className="text-muted" style={{ fontSize: "20px" }}>Information required to register in United States and Canada marketplace</p>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <div className="form-group form-float">
                                                                            <div className="form-line">
                                                                                {/* <label className="form-label">Username*</label>
                                                                                <input type="text" className="form-control" name="username" required /> */}
                                                                                <h3 className="mb-0">North America</h3>
                                                                                <p className="text-success mb-0" style={{ fontSize: "20px" }}>No extra information needed</p>
                                                                                <div className="custom-control custom-checkbox">
                                                                                    <input type="checkbox" name="remember" className="custom-control-input" tabIndex={3} id="remember-me" />
                                                                                    <label className="custom-control-label" htmlFor="remember-me">Remember Me</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                    <h3>Billing</h3>
                                                                    <fieldset>
                                                                        <div className="form-group form-float">
                                                                            <div className="form-line">
                                                                                {/* <label className="form-label">Username*</label>
                                                                                <input type="text" className="form-control" name="username" required /> */}
                                                                                <h5 className="mb-0">Billing Information</h5>
                                                                                <p className="text-muted" >Credit card details</p>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <div className="form-group form-float">
                                                                            <div className="form-line">
                                                                                <p className="text-muted" style={{ fontSize: "15px" }}>We use credit card for account varification. A one time only one US dollar ($ 1) account varification charge will be applied to your credit card upon complition of account setup.</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <div className="form-group form-float">
                                                                                    <div className="form-line">
                                                                                        <label className="form-label"><b>Credit card number</b></label>
                                                                                        <input placeholder="Enter Credit Card Number" type="text" className="form-control" required />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <div className="form-group form-float">
                                                                                    <div className="form-line">
                                                                                        <label className="form-label"><b>Expires on</b></label>
                                                                                        <input placeholder="Enter Month" type="number" className="form-control" required />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3 pl-0">
                                                                                <div className="form-group form-float">
                                                                                    <div className="form-line">
                                                                                        <label className="form-label">&nbsp;</label>
                                                                                        <input placeholder="Enter Year" type="number" className="form-control" required />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <div className="form-group form-float">
                                                                                    <div className="form-line">
                                                                                        <label className="form-label"><b>Card holder's name</b></label>
                                                                                        <input placeholder="Enter Card Holder Name" type="text" className="form-control" required />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-12 ml-4">
                                                                                <div className="form-group form-float">
                                                                                    <div className="form-line">
                                                                                        {/* <label className="form-label">Username*</label> */}
                                                                                        <h5 className=" mb-0" >Billing address</h5>
                                                                                        <div className="custom-control custom-checkbox mt-2 ml-2">
                                                                                            <input type="checkbox" name="address" className="custom-control-input" tabIndex={3} id="address" />
                                                                                            <label className="custom-control-label" htmlFor="address">14 Evergreen Terrace, New Brunswick, NJ, 08904, US</label>
                                                                                        </div>
                                                                                        {/* <a href="#" className="mt-2 ml-2">+ Add a new address</a> */}
                                                                                        <p>
                                                                                            <a className="mt-2 ml-2" data-toggle="collapse" href="#collapseExample" role="button"
                                                                                                aria-expanded="false" aria-controls="collapseExample">
                                                                                                + Add a new address
                                                                                            </a>
                                                                                        </p>
                                                                                        <div className="collapse" style={{width:"98%"}} id="collapseExample">
                                                                                            <div className="row">
                                                                                                <div className="col-md-6">
                                                                                                    <input placeholder="Address Line 1" type="text" className="form-control" required />
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <input placeholder="Address Line 2" type="text" className="form-control" required />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row mt-2">
                                                                                                <div className="col-md-6">
                                                                                                    <Country />
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <input placeholder="Street No" type="text" className="form-control" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                    <h3>Store</h3>
                                                                    <fieldset>
                                                                        <div className="form-group form-float">
                                                                            <div className="form-line">
                                                                                {/* <label className="form-label">Username*</label>
                                                                                <input type="text" className="form-control" name="username" required /> */}
                                                                                <h5 className="mb-0">Store Information</h5>
                                                                                <p className="text-muted" >Store name and product information</p>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <div className="form-group form-float">
                                                                            <div className="form-line">
                                                                                <div className="row">
                                                                                    <div className="col-md-6">
                                                                                        <label className="form-label"><b>Store name</b></label>
                                                                                    </div>
                                                                                    <div className="col-md-6 text-right">
                                                                                        <i className="fas fa-check-circle text-success"></i>
                                                                                        <label className="form-label"> Available</label>
                                                                                        {/* <i className="fas fa-times-circle text-danger"></i>
                                                                                        <label className="form-label"> Not Available</label> */}
                                                                                    </div>
                                                                                </div>
                                                                                <input type="text" name="name" className="form-control" required />
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <div className="form-group form-float">
                                                                            <div className="form-line">
                                                                                <label className="form-label d-block"><b>Do you have Universal Product Codes (UPCs) for all yor products</b></label>
                                                                                <div className="form-check ml-2">
                                                                                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" checked="" />
                                                                                    <label className="form-check-label" for="exampleRadios1">
                                                                                        Yes
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check ml-2">
                                                                                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" checked="" />
                                                                                    <label className="form-check-label" for="exampleRadios2">
                                                                                        No
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group form-float">
                                                                            <div className="form-line">
                                                                                <label className="form-label d-block"><b>Are you manufacturer or brand owner (or agent or representative of the brand) for any of the products you want to sell on Babylon?</b></label>
                                                                                <div className="form-check ml-2">
                                                                                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" checked="" />
                                                                                    <label className="form-check-label" for="exampleRadios3">
                                                                                        Yes
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check ml-2">
                                                                                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios4" checked="" />
                                                                                    <label className="form-check-label" for="exampleRadios4">
                                                                                        No
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group form-float">
                                                                            <div className="form-line">
                                                                                <label className="form-label d-block"><b>Do you own government-registered trademark for the branded products you want to sell on Babylon?</b></label>
                                                                                <div className="form-check ml-2">
                                                                                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios5" checked="" />
                                                                                    <label className="form-check-label" for="exampleRadios5">
                                                                                        Yes
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check ml-2">
                                                                                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios6" checked="" />
                                                                                    <label className="form-check-label" for="exampleRadios6">
                                                                                        No
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check ml-2">
                                                                                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios7" checked="" />
                                                                                    <label className="form-check-label" for="exampleRadios7">
                                                                                        Some of them
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                    <h3>varification</h3>
                                                                    <fieldset>
                                                                        <div className="form-group form-float">
                                                                            <div className="form-line">
                                                                                <h5 className="text-center mb-0">Address Validation</h5>
                                                                                <hr />
                                                                                <p className="text-muted">A postcard containing a code will bemailed to the adress mentioned below. please confirm the address to receive the postcard. Once you confirm the address it will take 7 days for the mail to arrive. In case you want to change the address than click on previous.</p>
                                                                                <h5 className="mb-0">TJ Faoa</h5>
                                                                                <p className="text-muted mb-0">14 Evergreen Terrace,</p>
                                                                                <p className="text-muted">New Brunswick, NJ, 08904, US</p>
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                    <h3>Confirmation</h3>
                                                                    <fieldset>
                                                                        <div className="form-group form-float">
                                                                            <div className="form-line">
                                                                                <h5 className="text-center mb-0">Address Validation</h5>
                                                                                <hr />
                                                                                <p className="text-muted">A postcard with your code was mailed to the address mentioned below. It takes 7 days for the mail to arrive and you can track the status below.</p>
                                                                                <h5 className="mb-0">TJ Faoa</h5>
                                                                                <p className="text-muted mb-0">14 Evergreen Terrace,</p>
                                                                                <p className="text-muted">New Brunswick, NJ, 08904, US</p>
                                                                                <h5 className="text-danger mb-0">Preparing</h5>
                                                                                <h5 className="d-inline mb-0">Address Validation</h5>
                                                                                <p className="text-success d-inline mb-0"> 15-May-2020</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group form-float">
                                                                            <div className="form-line">
                                                                                <div className="row">
                                                                                    <div className="col-md-3">
                                                                                        <label className="form-label text-info"><b>Enter code below</b></label>
                                                                                        <input type="text" className="form-control" required />
                                                                                    </div>
                                                                                    <div className="col-md-9">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                    {/* <h3>Profile Information</h3>
                                                                    <fieldset>
                                                                        <div className="form-group form-float">
                                                                            <div className="form-line">
                                                                                <label className="form-label">First Name*</label>
                                                                                <input type="text" name="name" className="form-control" required />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group form-float">
                                                                            <div className="form-line">
                                                                                <label className="form-label">Last Name*</label>
                                                                                <input type="text" name="surname" className="form-control" required />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group form-float">
                                                                            <div className="form-line">
                                                                                <label className="form-label">Email*</label>
                                                                                <input type="email" name="email" className="form-control" required />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group form-float">
                                                                            <div className="form-line">
                                                                                <label className="form-label">Address*</label>
                                                                                <textarea name="address" cols={30} rows={3} className="form-control no-resize" required defaultValue={""} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group form-float">
                                                                            <div className="form-line">
                                                                                <label className="form-label">Age*</label>
                                                                                <input min={18} type="number" name="age" className="form-control" required />
                                                                            </div>
                                                                            <div className="help-info">The warning step will show up if age is less than 18</div>
                                                                        </div>
                                                                    </fieldset> */}
                                                                    {/* <h3>Terms &amp; Conditions - Finish</h3>
                                                                    <fieldset>
                                                                        <input id="acceptTerms-2" name="acceptTerms" type="checkbox" required />
                                                                        <label htmlFor="acceptTerms-2">I agree with the Terms and Conditions.</label>
                                                                    </fieldset> */}
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                                <div className="mt-5 text-muted text-center">
                                    Don't have an account? <a href="auth-register.html">Create One</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* </div> */}
        </>
    )
}