import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';

export default function LandingPage() {
    const history = useHistory();
    return (
        <>
            <img style={{ width: "100%", height: "100%", zIndex: "-1", position: "fixed" }} src="assets/img/landingpage2.jpg" />
            <div className="container-fluid">
                <div className="row pt-3">
                    <div className="col-md-10"></div>
                    <div className="col-md-2">
                        <button onClick={() => history.push('/SellerLogin')} className="btn btn-light rounded-pill m-2">Login</button>
                        <button onClick={() => history.push('/Memberships')} className="btn btn-light rounded-pill m-2">Signup</button>
                    </div>
                </div>
                <div style={{ marginTop: "18%" }} className="row">
                    <div className="col-md-12">
                    <p style={{ width:'30%', marginLeft: "6%", color:'white' }}>More than half the units sold in our stores are from independent sellers.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <button style={{ width: "10%", marginLeft: "6%" }} onClick={() => history.push('/Memberships')} className="btn btn-light rounded-pill p-2">Signup</button>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-md-12">
                        <p style={{ width:'30%', marginLeft: "6%", color:'white' }}></p>
                    </div>
                    </div>
            </div>
        </>
    )
}